import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { RegionModel } from '../../../../shared/model/region-model';
import { DataGroupListItem } from '../../../../shared/model/interfaces/data-group-list-item';
import { RoleModel } from '../../../../shared/model/role-model';

export interface IRegionData {
    name: string;
    roles: string;
    dataGroups: string;
  }

@Component({
  selector: 'src-data-access',
  templateUrl: './data-access.component.html',
  styleUrls: ['./data-access.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataAccessComponent implements OnInit, OnChanges {
  @Input() dataGroups: Map<RegionModel, Array<DataGroupListItem>>;
  @Input() regions: Map<RegionModel, Array<RoleModel>>;
  regionData: IRegionData[];
  ngOnInit() {
    this.regionData = this.dataAccess();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.regionData = this.dataAccess();
  }

  dataAccess(): IRegionData[]  {
    const dataAccess: IRegionData[] = [];
    this.regions.forEach((value: Array<RoleModel>, key: RegionModel) => {
      dataAccess.push({
        name: key.name,
        roles: value.map((item) => item.name).reduce((item1, item2, idx) => `${idx === 0 ? ` ${item1}` : ` ${item1},`} ${item2}`, ''),
        // tslint:disable-next-line: max-line-length
        dataGroups: this.dataGroups.get(key).map(item => item.name).reduce((item1, item2, idz) => `${idz === 0 ? ` ${item1}` : ` ${item1},`} ${item2}`, ''),
      });
    });
    return dataAccess;
  }

}
