import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatChipInputEvent, throwMatDialogContentAlreadyAttachedError} from '@angular/material';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AdminConstants } from '../../constants/admin.constants';

@Component({
  selector: 'src-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss']
})
export class TagInputComponent implements OnInit, OnChanges {
  @Input() visible = true;
  @Input() selectable = true;
  @Input() removable = true;
  @Input() addOnBlur = false;
  public options: Array<string> = [];
  public selectedOptions: Array<string> = [];
  public prevSelection = [];
  @Input() placeTitle: string;
  @Input() isEdit: boolean;
  @Input() previousSelection: Array<string> = [];
  @Output() optionUpdate: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
  separatorKeysCodes = [ENTER, COMMA];
   error :boolean =true;
   check :boolean = true;
  optionCtrl = new FormControl('', Validators.required);
  filteredOptions: Observable<any[]>;
  public requiredFieldMessage = AdminConstants.REQUIRED_FIELD_MESSAGE;
  @ViewChild('optionInput', {static: false}) optionInput: ElementRef;
  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    /*Old code for Setting filtered options incase of preloaded active users on user type (commented code)*/
    // this.filteredOptions = this.optionCtrl.valueChanges.pipe(
    //     startWith(null),
    //     map((option: string | null) => {
    //       return option ? this.filter(option) : this.options.slice();
    //     })
    // );
    // this.addFilter();
  }

  ngOnChanges(changes: SimpleChanges) {
    /* Old code to filter the options for active users (commented code)*/
    // this.addFilter();
      this.selectedOptions = this.previousSelection && JSON.parse(JSON.stringify(this.previousSelection));
      this.prevSelection = this.prevSelection && JSON.parse(JSON.stringify(this.previousSelection));
  }

  /**
   * This method is used to palce filter on option provided
   */
  addFilter() {
    this.options = this.options.filter(
      (item) => !this.selectedOptions.includes(item)
    );
  }

  /**
   * This method is used to filter out the options
   * @param option: option which need to be filtered
   */
  filter(option: string) {
    return this.options.filter(fruit =>
        fruit.toLowerCase().indexOf(option.toLowerCase()) === 0);
  }

  /**
   * Method is used to update selectedOption array, and also dispatch event so that parent
   * can update the particular.
   * @param value: which need to be updated.
   */
  updateSelectedOptions(value): void {
    this.selectedOptions.push(value.trim());
    this.optionUpdate.emit(this.selectedOptions);
    /* Old code to filter the options for active users (commented code)*/
    // this.options = this.options.filter( item => !this.selectedOptions.includes(item));
  }

  /**
   * *****************************************************************************************************************
   * Event handlers
   * *****************************************************************************************************************
   */

  /**
   * Method used to add option in selected input
   * @param event: instance of mat chip input event
   */
  add(event: MatChipInputEvent): void {
    const me = this;
    const input = event.input;
    const value = event.value;
    /* Old code which works only for active users (commented code) */
    // if ((value || '').trim()) {
    //   const index = this.options.findIndex(item => {
    //     return item.toLowerCase() === value.trim().toLowerCase();
    //   });
    //   if (index !== -1) {
    //     this.error = false;

    //     this.updateSelectedOptions(this.options[index]);
    //   } else {
    //     this.optionCtrl.setErrors({incorrect: true});
    //   }
    // }
    if((value || '').trim()) {
      me.selectedOptions.push(value);
      this.optionUpdate.emit(me.selectedOptions);
    }
    if (input) {
      input.value = '';
    }
  }

  /**
   * This method is used to remove tags from selectedOptions.
   * @param option; which need to removed.
   */
  remove(option: any): void {
    const index = this.selectedOptions.indexOf(option);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.optionUpdate.emit(this.selectedOptions);
      /* Old code to filter the options for active users (commented code)*/
      // this.options = this.options.filter( item => !this.selectedOptions.includes(item));
    }
    if(this.selectedOptions.length==0){
      this.error=true;
    }
    else{
      this.error=false;
    }
  }

  /**
   * This method is used to add option when any option is selected from the list.
   * @param event: instance of autocomplete event.
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    if(event.option.viewValue){
      this.error=false;
    }
    else{
      this.error=true;
    }
    this.updateSelectedOptions(event.option.viewValue);
    this.optionInput.nativeElement.value = '';
    this.optionCtrl.setValue(null);
  }

  validateControl() {
    this.optionCtrl.markAsTouched({ onlySelf: true });
    this.changeDetector.detectChanges();
  }

  resetControls() {
    this.selectedOptions = this.isEdit ? JSON.parse(JSON.stringify(this.previousSelection)) : [];
    this.optionCtrl.setValue(null);
    this.optionInput.nativeElement.value = '';
    this.optionUpdate.emit(this.selectedOptions);
  }
}
