import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource,MatSort} from '@angular/material';
import {Subject} from 'rxjs';
import {ProjectContactListItemInterface} from '../../shared/model/interfaces/project-contact-list-item.interface';
import {MatMenuModule} from '@angular/material/menu';
import { AdminConstants } from '../../shared/constants/admin.constants';
@Component({
  selector: 'src-project-contact-list',
  templateUrl: './project-contact-list.component.html',
  styleUrls: ['./project-contact-list.component.scss']
})
export class ProjectContactListComponent implements OnInit, OnChanges {
  @Input() data: Array<ProjectContactListItemInterface> = [];
  @Input() actionControl = true;
  @Output() actionSelect: EventEmitter<{item: ProjectContactListItemInterface, type: string}>
    = new EventEmitter<{item: ProjectContactListItemInterface, type: string}>();

  dataSource: MatTableDataSource<ProjectContactListItemInterface>;
  displayedColumn: Array<string> = ['regionName', 'title', 'responsibleTeamName','action'];
  @Input()
  searchSubject: Subject<any> = new Subject<any>();
  searchValue = '';
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor() { }
  public selectedRow: any;
  projectContactAccess: boolean;

  ngOnInit() {
    this.init();
    this.searchSubject.subscribe(value => this.applyFilter(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  /**
   * Initialize the view data
   */
  init() {
    const userFunctionalities = localStorage.getItem('userFunctionalities');
    this.projectContactAccess = userFunctionalities ? userFunctionalities.includes(AdminConstants.CREATE_PROJECT_CNTCT) : false;
    if (this.data) {
      this.data.sort((item1, item2) => item1.regionName < item2.regionName ? -1 : 1);

      this.dataSource = new MatTableDataSource<ProjectContactListItemInterface>(this.data);
      if (this.searchValue !== '') {
        this.dataSource.filter = this.searchValue;
      }
      this.dataSource.sort = this.sort;

    }
  }

  /**
   * This method is used to provide display name for the column header
   * @param column: String value for column name
   */
  getColumnDisplayName(column: string): string {
    let displayName = column;
    if (column === 'regionName') {
      displayName = 'Region Name';
    }else if (column === 'responsibleTeamName') {
      displayName = 'Responsible Team';
    }
    return displayName;
  }

  getFieldDisplayValue(element: any, column: string) {
    if (column === 'includeFromDL') {
      return '' + element[column] === '1';
    }
    if (column === 'responsibleTeamName' && !element['responsibleTeamName']) {
        return '';
    }
    return element[column].toString();
  }

  /**
   * **************************************************
   * Event Handlers
   * **************************************************
   */

  /**
   * This method is used to filter out the table.
   * @param filterValue: string value on which filtering need to be done
   */
  applyFilter(filterValue: string) {
    this.searchValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  highlight(element: any) {
    this.selectedRow = element;

  }
  /**
   * This method handles click on action items.
   * @param event: Instance of mouse event
   * @param row: Instance of the row clicked
   * @param type: type of the action button.
   */
  actionItemClicked(event: MouseEvent, row: ProjectContactListItemInterface, type: string) {
   // event.stopImmediatePropagation();
    this.actionSelect.emit({item: row, type});
  }
}
