import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {ProjectContactService} from '../../services/project-contact.service';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatTableDataSource
} from '@angular/material';
import { ApplicationStateService } from '../../services/application-state.service';
import { CriteriaItemInterface } from '../../shared/model/interfaces/criteria-item.iterface';
import { FieldNameInterface } from '../../shared/model/interfaces/field-name.interface';
import { AdminConstants } from '../../shared/constants/admin.constants';

// @dynamic
@Component({
    selector: 'src-add-criteria-dialog',
    templateUrl: './add-criteria-dialog.component.html',
    styleUrls: ['./add-criteria-dialog.component.scss']
})
export class AddCriteriaDialogComponent implements OnInit {
    isEdit = false;
    criteriaGroup: FormGroup;
    criteriaTableData: Array<CriteriaItemInterface> = [];
    criteriaTableDataSource: MatTableDataSource<CriteriaItemInterface>;
    displayedColumn = [
        'opening',
        'fieldName',
        'compare',
        'fieldValue',
        'closing',
        'andOr',
        'action'
    ];
    openingBracketOptions = ['{', '{{', '{{{', ''];
    operators = ['And', 'Or'];
    compares: Array<CompareInterface> = [];
    fieldNames: Array<FieldNameInterface> = [];
    title = 'Add Query';
    queryPreview: string;
    validQuery = true;
    errorLabel = '';
    private isPreviousEntry: boolean;
    private editRowIndex: number;
    constructor(
        public dialogRef: MatDialogRef<AddCriteriaDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: { tableData: Array<CriteriaItemInterface>; query: string  , region: string},
        private applicationState: ApplicationStateService,
        private projectContactService: ProjectContactService,
    ) {
        this.applicationState.fields.subscribe(
            (value) => (this.fieldNames = value)
        );
        this.applicationState.operators.subscribe(
            (value: Array<CompareInterface>) => (this.compares = value)
        );
        this.isEdit = !!this.data.tableData;
        this.title = this.isEdit ? 'Edit Query' : this.title;
    }

    ngOnInit() {
        this.projectContactService.fetchFields(this.data.region);
        this.criteriaGroup = new FormGroup({
            notOption: new FormControl(null),
            openBracket: new FormControl(null),
            fieldName: new FormControl(null, Validators.required),
            fieldValue: new FormControl(null, Validators.required),
            compare: new FormControl(null, Validators.required),
            closingBracket: new FormControl(null),
            condition: new FormControl(null)
        });
        this.criteriaTableData = this.isEdit ? this.data.tableData : [];
        if (this.isEdit) {
            this.generateQueryPreview();
        }
        this.criteriaTableDataSource = new MatTableDataSource<
            CriteriaItemInterface
        >(this.criteriaTableData);
    }

    /**
     * Method is responsible for checking that query is valid or not.
     */
    cancelClicked() {
        this.dialogRef.close(null);
    }
    checkForValidQuery() {
        // check for opening and closing brackets counts
        let openCount = 0;
        let closeCount = 0;
        this.criteriaTableData.forEach((row, index) => {
            openCount =
                row.opening === '{'
                    ? openCount + 1
                    : row.opening === '{{'
                    ? openCount + 2
                    : row.opening === '{{{'
                    ? openCount + 3
                    : openCount;
            closeCount =
                row.closing === '}'
                    ? closeCount + 1
                    : row.closing === '}}'
                    ? closeCount + 2
                    : row.closing === '}}}'
                    ? closeCount + 3
                    : closeCount;
        });
        let validAnd = true;
        if (this.criteriaTableData.length > 1) {
            this.criteriaTableData.forEach((row, index) => {
                if (index < this.criteriaTableData.length - 1 && row.andOr == '') {
                    validAnd = false;
                }
            });
        }
        // Check first row should has open bracket and last row has closing bracket
        const haveProperBrackets = openCount === closeCount;
        if (haveProperBrackets && validAnd) {
            this.errorLabel = '';
        } else if (!haveProperBrackets && validAnd) {
            this.errorLabel = AdminConstants.BRACKET_MISMATCH_ERROR;
        } else if (haveProperBrackets && !validAnd) {
            this.errorLabel = AdminConstants.AND_OR_ERROR;
        } else {
            this.errorLabel = AdminConstants.BRACKET_AND_OR_ERROR;
        }
        this.validQuery = haveProperBrackets && validAnd;
    }

    /**
     * This method is responsible for populating value for specific row in to query fields.
     * @param row: Table row for the query.
     */
    updateFormValue(row) {
        this.isPreviousEntry = true;
        this.criteriaGroup.setValue({
            notOption: row.not,
            openBracket: row.opening,
            fieldName: row.fieldName.fieldName,
            fieldValue: row.fieldValue,
            compare: row.compare,
            closingBracket: row.closing,
            condition: row.andOr
        });
    }

    /**
     * This method is responsible for filtering the value for the compare operators.
     */
    getCompare() {
        let compareArray = [];
        if (this.criteriaGroup.get('fieldName').value) {
            let data= this.fieldNames.filter(x=>{x.fieldName==this.criteriaGroup.get('fieldName').value})
            const fieldType: string = data.length>0?data[0].type:'';
            compareArray = this.compares.filter((item: CompareInterface) =>
                item.type.includes(fieldType.toLowerCase())
            );
        }
        return compareArray;
    }

    getFieldType() {
        if (this.criteriaGroup.get('fieldName').value) {
            let data= this.fieldNames.filter(x=>{x.fieldName==this.criteriaGroup.get('fieldName').value})
            return data.length>0?data[0].type:'';
        } else {
            return 'text';
        }
    }

    /**
     * Return array for the closing bracket option depending on the option selected for opening
     * bracket.
     */
    getClosingBracketArray(): Array<string> {
        let arr = [''];
        if (
            this.criteriaGroup.get('openBracket').value === '' ||
            !this.criteriaGroup.get('openBracket').value
        ) {
            arr = [...arr, '}', '}}', '}}}'];
        }
        return arr;
    }

    /**
     * This method is used to reset the form value.
     */
    resetForm() {
        this.criteriaGroup.setValue({
            notOption: null,
            openBracket: null,
            fieldName: null,
            fieldValue: null,
            compare: null,
            closingBracket: null,
            condition: null
        });
    }

    /**
     * Method is used to generate the preview for the query.
     */
    generateQueryPreview() {
        this.queryPreview = '';
        this.checkForValidQuery();
        this.criteriaTableData.forEach((row, index) => {
            if (index === 0) {
                this.queryPreview = `${row.opening} ${
                    row.fieldName.fieldName
                } ${this.getCompareValue(row.compare)} '${row.fieldValue}'
         ${this.criteriaTableData[index + 1] ? row.andOr.toUpperCase() : ''} `;
            } else {
                if (row.opening.includes('{')) {
                    this.queryPreview += `${row.opening} ${
                        row.fieldName.fieldName
                    } ${this.getCompareValue(row.compare)} '${row.fieldValue}'
         ${this.criteriaTableData[index + 1] ? row.andOr.toUpperCase() : ''} `;
                } else if (row.closing.includes('}')) {
                    this.queryPreview += `${row.fieldName.fieldName} ${this.getCompareValue(row.compare)} '${
                        row.fieldValue
                    }' ${row.closing}
           ${
               this.criteriaTableData[index + 1] ? row.andOr.toUpperCase() : ''
           } `;
                } else {
                    this.queryPreview += `${row.fieldName.fieldName} ${this.getCompareValue(row.compare)} '${
                        row.fieldValue
                    }' ${row.andOr.toUpperCase() } `;
                }
            }
        });
    }

    /**
     * This method is used to provide display value for the compare operator.
     * @param compare: string value for the compare operator.
     */
    getCompareValue(compare: string): string {
        let displayValue = '';
        switch (compare) {
            case 'Equal to':
                displayValue = '=';
                break;
            case 'Not Equal to':
                displayValue = '!=';
                break;
            case 'Like':
                displayValue = 'LIKE';
                break;
            case 'Not Like':
                displayValue = 'NOT LIKE';
                break;
            case 'Greater than':
                displayValue = '>';
                break;
            case 'Less than':
                displayValue = '<';
                break;
            case 'Greater than/Equal to':
                displayValue = '>=';
                break;
            case 'Less than/Equal to':
                displayValue = '<=';
                break;
        }
        return displayValue;
    }

    getColumnDisplayName(column) {
        if (column === 'not') {
            return '!';
        }
        return column;
    }

    /**
     * This method generates table data based on the current row values.
     */
    private generateTableData(rowId?: number) {
        let data : any = this.fieldNames.filter(x=>x.fieldName == this.criteriaGroup.controls.fieldName.value)
        return {
            opening: this.criteriaGroup.controls.openBracket.value
                ? this.criteriaGroup.controls.openBracket.value
                : '',
            closing: this.criteriaGroup.controls.closingBracket.value
                ? this.criteriaGroup.controls.closingBracket.value
                : '',
            fieldName: data[0],
            not: this.criteriaGroup.controls.notOption.value
                ? this.criteriaGroup.controls.notOption.value
                : '',
            andOr: this.criteriaGroup.controls.condition.value
                ? this.criteriaGroup.controls.condition.value
                : '',
            fieldValue: this.criteriaGroup.controls.fieldValue.value,
            compare: this.criteriaGroup.controls.compare.value
                ? this.criteriaGroup.controls.compare.value
                : '',
            id: (rowId === undefined) ?
                (this.criteriaTableData.length ? (this.criteriaTableData[this.criteriaTableData.length - 1]['id'] + 1)
                : 0) : rowId
        };
    }

    /**
     * **************************************************
     * Event Handlers
     * **************************************************
     */

    /**
     * This method handles click on action items.
     * @param event: Instance of mouse event
     * @param row: Instance of the row clicked
     * @param type: type of the action button.
     */
    actionItemClicked(
        event: MouseEvent,
        row: CriteriaItemInterface,
        type: string
    ) {
        // event.stopImmediatePropagation();
        this.editRowIndex = row.id;
        if (type === 'Delete') {
            this.criteriaTableData.splice(this.editRowIndex, 1);
            this.criteriaTableData.map((item, index) => {
                item.id = index;
                return item;
            });
            this.criteriaTableDataSource = new MatTableDataSource<
                CriteriaItemInterface
            >(this.criteriaTableData);
            // this.editRowIndex = -1;
        } else {
            this.criteriaTableData.map((item, index) => {
                item.selected = item.id === row.id;
                return item;
            });
            this.updateFormValue(row);
        }
        this.generateQueryPreview();
    }

    /**
     * This method is used to handle click on save button, this will check for valid query and length of
     * the query table.
     */
    save() {
        if (this.validQuery && this.criteriaTableData.length > 0) {
            this.dialogRef.close({
                tableData: this.criteriaTableData,
                query: this.queryPreview
            });
        } else {
            this.errorLabel = 'Query is not valid';
        }
    }

    /**
     * This method is used to handle click on add button. This will check that all the field which are necessary are
     * provided.
     */
    add() {
        if (this.criteriaGroup.valid) {
            if (!this.isPreviousEntry) {
                this.criteriaTableData.push(this.generateTableData());
            } else {
                for (let i = 0; i < this.criteriaTableData.length; i++) {
                    if (this.criteriaTableData[i]['id'] === this.editRowIndex) {
                        this.criteriaTableData[i] = this.generateTableData(this.editRowIndex);
                        break;
                    }
                }
                this.isPreviousEntry = false;
            }
            this.criteriaTableDataSource = new MatTableDataSource<
                CriteriaItemInterface
            >(this.criteriaTableData);
            this.generateQueryPreview();
            this.resetForm();
        }
    }
}

interface CompareInterface {
    name: string;
    type: string;
}
