import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Subject, Subscription } from 'rxjs';
import { DataGroupService } from '../../services/data-group.service';
import { StateService } from '../../services/state.service';
import { UserOperationService } from '../../services/user-operation.service';
import { ConfirmationDialogComponent } from '../../shared/component/confirmation-dialog/confirmation-dialog.component';
import { DataGroupListItem } from '../../shared/model/interfaces/data-group-list-item';
import { AddEditDataGroupsComponent } from './components/add-edit-data-groups/add-edit-data-groups.component';

@Component({
  selector: 'src-data-groups',
  templateUrl: './data-groups.component.html',
  styleUrls: ['./data-groups.component.scss']
})
export class DataGroupsComponent implements OnInit {
  dataGroups: Array<DataGroupListItem>;
  private subscription: Subscription;
  private dataSet: any;
  searchSubject: Subject<any> = new Subject<any>();
  constructor(
    public matDialog: MatDialog,
    private dataGroupService: DataGroupService,
    private applicationState: StateService,
    private userService: UserOperationService
  ) {
    
  }

  async ngOnInit() {
    // let result;
    if (this.applicationState.guid === 'system') {
      //result =
      await this.userService.fetchGuid();
    }
    // if(result.success) {
    this.subscription = new Subscription();
    this.addObservers();
    this.fetchDataGroups();
    // }
  }

  /**
   * This method is used to fetch data groups.
   */
  fetchDataGroups() {
    this.dataGroupService.fetchDataGroups();
    this.dataGroupService.fetchData().then(result => {
      this.dataSet = result;
    });
  }

  /**
   * This method is used to add observers related to data.
   */
  addObservers() {
    this.subscription.add(
      this.applicationState.dataGroupObserver.subscribe(result => {
        this.dataGroups = [...result];
      })
    );
  }

  /**
   * This method is used to show add/Edit data groups
   */
  showAddEditDialog(group: DataGroupListItem) {
    if (group) {
      group = this.dataGroups.find(index => {
        return index.id === group.id;
      });
    }
    const dialogRef: MatDialogRef<
      AddEditDataGroupsComponent
    > = this.matDialog.open(AddEditDataGroupsComponent, {
      data: {
        group,
        sites: this.dataSet.sites,
        plants: this.dataSet.plants,
        units: this.dataSet.units
      }
    });
    return dialogRef;
  }

  /**
   * This method is used handle click on edit group button.
   * @param groupId: instance of selected data group
   */
  editGroup(groupId: any) {
    const extractedGroup = this.dataGroups.find(item => item.id === groupId);
    this.subscription.add(
      this.showAddEditDialog(extractedGroup)
        .afterClosed()
        .subscribe((data: DataGroupListItem) => {
          if (data) {
            this.dataGroupService.editDataGroup(data);
          }
        })
    );
  }

  /**
   * This method is used to handle click on delete group button.
   * @param groupId: instance of selected data group
   */
  deleteGroup(groupId: any) {
    const extractedGroup = this.dataGroups.find(item => item.id === groupId);
    const desc = `Are you sure to remove the "${extractedGroup.name}" Data Group permanently?`;
    this.subscription.add(
      this.showConfirmation('Remove Data Group', desc, 'No', 'Yes')
        .afterClosed()
        .subscribe((data: DataGroupListItem) => {
          if (data) {
            this.dataGroupService.deleteDataGroup(extractedGroup);
          }
        })
    );
  }

  /**
   * This method is used to show confirmation.
   * @param title: Title for the confirmation dialog
   * @param description: Description for the confirmation dialog
   * @param cancelLabel: label of the cancel button for the confirmation dialog
   * @param okLabel: label of the ok button for the confirmation dialog
   */
  showConfirmation(
    title: string,
    description: string,
    cancelLabel?: string,
    okLabel?: string
  ) {
    const dialogRef: MatDialogRef<
      ConfirmationDialogComponent
    > = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title,
        description,
        cancelLabel: cancelLabel ? cancelLabel : 'Cancel',
        okLabel: okLabel ? okLabel : 'Ok'
      }
    });
    return dialogRef;
  }

  /**
   * ************************************
   * Event Handlers
   * ************************************
   */

  /**
   * This method is used to filter out the table.
   * @param filterValue: string value on which filtering need to be done
   */
  applyFilter(filterValue: string) {
    this.searchSubject.next(filterValue);
  }

  /**
   * This method is used to open add data dialog, where user can provide details for the new data group.
   */
  addGroups() {
    this.subscription.add(
      this.showAddEditDialog(null)
        .afterClosed()
        .subscribe((data: any) => {
          if (data) {
            this.dataGroupService.createDataGroup(data);
          }
        })
    );
  }

  /**
   * This method handles the action taken on selected row.
   * @param action: instance of the data group list
   */
  groupActionClicked(action: any) {
    if (action.type === 'Edit') {
      this.editGroup(action.dgId);
    } else {
      this.deleteGroup(action.dgId);
    }
  }
}
