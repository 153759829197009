import { StateService } from './../../../../services/state.service';
import { UserOperationService } from './../../../../services/user-operation.service';
import { AzureADIntegrationService } from './../../../../services/azure-ad-integration.service';
import { ToasterService } from './../../../../services/toaster.service';
import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatAutocompleteSelectedEvent,
    MatDialogRef
} from '@angular/material';
import { UserListDataItem } from '../../../../shared/model/interfaces/user-list-data-item';
import UserModel from '../../../../shared/model/user-model';
import { debounce, debounceTime, switchMap, takeWhile } from 'rxjs/operators';
import { AdminConstants } from '../../../../shared/constants/admin.constants';

@Component({
    selector: 'src-add-edit-user',
    templateUrl: './add-edit-user.component.html',
    styleUrls: ['./add-edit-user.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddEditUserComponent implements OnInit {
    user: UserListDataItem;
    roles: Array<string> = [];
    userFormGroup: FormGroup;
    titleLabel: string;
    availableUsers: Array<any> = [];
    regions: Array<string>;
    isEditable = true;
    @ViewChild('email', { static: false }) emailInput: ElementRef;
    public requiredFieldMessage = AdminConstants.REQUIRED_FIELD_MESSAGE;
    public invalidEmailMessage = AdminConstants.INVALID_EMAIL_MESSAGE;
    constructor(
        public dialogRef: MatDialogRef<AddEditUserComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private userService: UserOperationService,
        private applicationState: StateService,
        private azureADService: AzureADIntegrationService,
        private readonly toasterService: ToasterService
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.user = this.createNewUserData();
        this.availableUsers = this.data.allUser;
        const emailRegex = new RegExp('[a-zA-Z0-9._-]+@siemens-energy.com', 'i');
        this.titleLabel = 'Search User';
        this.userFormGroup = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.pattern(emailRegex)])
        });
        this.userFormGroup
            .get('email')
            .valueChanges.pipe(
                debounceTime(1000),
                switchMap((result) => {
                    if (result && !this.userFormGroup.get('email').hasError('pattern')) {
                        return this.azureADService.fetchUserDetails(result);
                    } else {
                        return [];
                    }
                })
            )
            .subscribe((val: any) => {
                if (this.isEditable) {
                    const locDetails = val.officeLocation.split("/");
                    const usersData: UserModel[] = [{
                        fName: val.givenName,
                        lName: val.surname,
                        email: val.mail,
                        alias: `${val.givenName} ${val.surname} (${val.department})`,
                        dataGroups: null,
                        regions: null,
                        userId: null,
                        userGid: val.extension_37708433798243bca89959b28c968b71_siemens_gid,
                        activeUser: val.accountEnabled,
                        windowsUserid: val.onPremisesSamAccountName,
                        pccAdminRegion: null,
                        orgCode: val.department,
                        userName: `${val.givenName} ${val.surname}`,
                        jobTitle: val.jobTitle,
                        cellPhone: val.mobilePhone,
                        countryCode:locDetails[0] == '-' ? null : locDetails[0],
                        division: null,
                        site: locDetails[2] == '-' ? null : locDetails[2],
                        locationCode: locDetails[1] == '-' ? null : locDetails[1],
                        mailCode: locDetails[3] == '-' ? null : locDetails[3],
                        displayName: `${val.givenName} ${val.surname} (${val.department})`,
                        searchKey: `${val.givenName} ${val.surname} (${val.department})`,
                        managerGID: val.extension_37708433798243bca89959b28c968b71_siemensResponsible
                    }];
                    this.applicationState.serverUsers = usersData;
                }
            }, (err) => {
                this.applicationState.serverUsers = [];
                if (err.error && err.error.error.code.indexOf('ResourceNotFound') > 0) {
                    this.toasterService.danger(AdminConstants.USER_NOT_EXIST);
                }
                else if (err.error && err.error.error.code) {
                    this.toasterService.danger(err.error.error.message);
                }
                else {
                    this.toasterService.danger(AdminConstants.GENERIC_ERROR);
                }
                this.refreshClicked();
                this.ngOnInit();
            });

        this.applicationState.serverUserObserver.subscribe((data) => {
            this.availableUsers = data;
        });
    }

    cancelClicked() {
        this.dialogRef.close(null);
    }
    /**
     * Create and return a fresh user data model.
     */
    createNewUserData(): UserListDataItem {
        return {
            region: [],
            userName: '',
            email: '',
            alias: '',
            userRole: new Map<string, Array<string>>(),
            userId: null,
            userGid: '',
        };
    }

    /**
     * Method is used to set the form value
     */
    setFormValues(value) {
        this.userFormGroup.get('email').setValue(value.email);
    }
    /**
     * ************************************
     * Event Handlers
     * ************************************
     */

    /**
     * Method Handles selection of the user from the search.
     */
    optionSelected(event: MatAutocompleteSelectedEvent) {
        this.isEditable = false;
        this.userFormGroup.get('email').setValue(event.option.value.email);
        this.save() ;
    }

    /**
     * Method is used to handle input in the user field
     */
    onSearchChange(value: string) {
        if (!value || this.userFormGroup.get('email').hasError('pattern')) {
            this.availableUsers=[];
            return;
        }
        const userExists = this.applicationState.isUserAvailable(value);
        if (userExists) {
            this.applicationState.listedUser = [userExists];
            //show error user already exist
            this.toasterService.danger(AdminConstants.USER_EXIST);
            this.refreshClicked();
            this.cancelClicked();
            return;
        }
        else {
            if (!this.isEditable) {
                this.setFormValues({ fName: '', lName: '', email: '' });
                this.isEditable = true;
            }
        }
    }
    /**
     * Function is used to handle click on refresh button
     */
    refreshClicked() {
        this.userFormGroup.reset();
        this.setFormValues({ fName: '', lName: '', email: '' });
        this.availableUsers = [];
    }

    /**
     * This method is used to handle save button clicked.
     */
    save() {
        const email = this.userFormGroup.get('email').value.toString();
        let isUser = '';
        if (this.availableUsers && this.availableUsers.length) {
            isUser = this.availableUsers.find((item) => {
                return item.email === email;
            });
        }
        if (
            isUser &&
            this.userFormGroup.valid &&
            this.userFormGroup.get('email').value !== ''
        ) {
            const user = new UserModel();
            user.fName = isUser['fName'];
            user.lName = isUser['lName'];
            user.email = this.userFormGroup.get('email').value;
            user.alias = isUser['alias'];
            user.userGid = isUser['userGid'];
            user.windowsUserid = isUser['windowsUserid'];
            user.orgCode = isUser['orgCode'];
            user.userName = isUser['userName'];
            user.jobTitle = isUser['jobTitle'];
            user.cellPhone = isUser['cellPhone'];
            user.countryCode = isUser['countryCode'];
            user.division = isUser['division'];
            user.site = isUser['site'];
            user.mailCode = isUser['mailCode'];
            user.locationCode = isUser['locationCode'];
            user.displayName = isUser['displayName'];
            user.searchKey = isUser['searchKey'];
            user.managerGID = isUser['managerGID'];

            this.dialogRef.close(user);
        } else {
            if (!isUser) {
                this.userFormGroup.markAllAsTouched();
            } else {
                Object.keys(this.userFormGroup.controls).forEach((field) => {
                    const control = this.userFormGroup.get(field);
                    control.markAsTouched({ onlySelf: true });
                });
            }
        }
    }
}
