import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, SimpleChanges, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { AdminConstants } from '../../constants/admin.constants';

@Component({
  selector: 'src-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CheckboxListComponent implements OnInit, OnChanges {
  @Input() options: Array<{id: string, functionality: string, defaultselect: string}> = [];
  functionalitiesOptions: Array<{id: string, functionality: string}> = [];
  selectedFunctionalitiesOptions: Array<string> = [];
  @Input() selectedOptions: Array<{id: string, functionality: string}> = [];
  @Input() searchSubject: Subject<any> = new Subject<any>();
  @Output() optionUpdate: EventEmitter<any> = new EventEmitter<any>();
  public noRecordMessage = AdminConstants.NO_RECORDS_FOUND;
  private initialSelectionOption;
  constructor() {
  }

  ngOnInit() {
    this.selectedFunctionalitiesOptions = this.selectedOptions.map(option => {
      return option.id;
    });
    this.initializeList();
    this.searchSubject.subscribe(value => {
      if (value.filterFor === 'functionalities') {
        this.filterOption(value.filterValue);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedOptions.currentValue && !this.initialSelectionOption) {
      this.initialSelectionOption = this.selectedOptions;
    }
  }

  /**
   * Function to initilaize the user functionalities list
   * @param functionalitiesOptions: Array of user functionalities
   */
  initializeList(functionalitiesOptions = null) {
    this.functionalitiesOptions = functionalitiesOptions || this.options;
  }

  /**
   * This is used to filter the value of checklist source
   * @param filterValue: String value for search
   */
  filterOption(filterValue: string) {
    const filterOptions = this.options.filter(item => {
      return item.functionality.toLowerCase().includes(filterValue.toLowerCase());
    });
    this.initializeList(filterOptions);
  }

  onClickHandler(event: MouseEvent, option) {
    if (option.defaultselect === '1') {
      event.preventDefault();
    } else {
      option.selected = !option.selected;
    }
  }

  resetSelection() {
    if (this.initialSelectionOption && this.initialSelectionOption.length) {
      this.options.forEach(option => {
        let flag = false;
        for (let index = 0; index <= this.initialSelectionOption.length - 1 ; index++) {
          const intialSelectedOption = this.initialSelectionOption[index];
          if (intialSelectedOption.id === option.id) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          Object.assign(option, {selected: option.defaultselect === '1' ? true : false});
        }
      });
    } else {
      this.options = this.options.map(functionality => {
        return Object.assign(functionality, {selected: functionality.defaultselect === '1' ? true : false});
      });
      this.initializeList();
    }
  }

}
