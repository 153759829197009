import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import UserModel from '../../../../shared/model/user-model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
//import { AdminConstants } from 'projects/user-auth/src/lib/shared/constants/admin.constants';
import { Router } from '@angular/router';
//import { RegionModel } from 'projects/user-auth/src/lib/shared/model/region-model';
import { RegionModel } from '../../../../shared/model/region-model'
import { AdminConstants } from '../../../../shared/constants/admin.constants';

@Component({
  selector: 'src-pcc-admin',
  templateUrl: './pcc-admin.component.html',
  styleUrls: ['./pcc-admin.component.scss']
})
export class PccAdminComponent implements OnInit, OnChanges {
  @Input() invalidPccRegion: any;
  selectedRegions: any;
  userFormGroup: FormGroup;
  @Input() user: UserModel;
  @Input() pccRegions: any;
  @Output() regionSelected: EventEmitter<any> = new EventEmitter<any>();
  constructor(private router: Router) { }

  ngOnInit() {
    // this.user.activeUser = this.router.url.includes('add') ? '0' : this.user.activeUser;
    console.log('ngOnInit this', this);
    this.init();
    this.userFormGroup = new FormGroup({
      alias: new FormControl(this.user.alias, Validators.required)
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  /**
   * This method is used to initialize view bindings.
   */
  init() {
    if(this.pccRegions && this.pccRegions.length) {
      this.selectedRegions = this.pccRegions.map(item => {
        const regionObj = {
          displayName: item.displayName,
          value: item.value,
          default: this.user.pccAdminRegion === item.value ? true : false
        }
        return regionObj;
      });
    }
  }

  onRegionSelected(event) {
    this.selectedRegions.map((item) => {
      item.default = item.displayName === event.target.value;
      if (item.default) {
        this.regionSelected.emit(item);
      }
      return item;
    });
  }
}
