import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, ChangeDetectorRef } from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatChipInputEvent, throwMatDialogContentAlreadyAttachedError} from '@angular/material';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { AdminConstants } from '../../constants/admin.constants';

@Component({
  selector: 'src-input-tag',
  templateUrl: './input-tag.component.html',
  styleUrls: ['./input-tag.component.scss']
})
export class InputTagComponent implements OnInit, OnChanges {
  @Input() visible = true;
  @Input() selectable = true;
  @Input() removable = true;
  @Input() addOnBlur = false;
  @Input() options: Array<string> = [];
  @Input() selectedOptions: Array<string> = [];
  @Input() placeTitle: string;
  @Input() isEdit: boolean;
  @Input() previousSelection: Array<string> = [];
  @Output() optionUpdate: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
  separatorKeysCodes = [ENTER, COMMA];
   error :boolean =true;
  optionCtrl = new FormControl('', Validators.required);
  filteredOptions: Observable<any[]>;
  public requiredFieldMessage = AdminConstants.REQUIRED_FIELD_MESSAGE;
  @ViewChild('optionInput', {static: false}) optionInput: ElementRef;
  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.filteredOptions = this.optionCtrl.valueChanges.pipe(
        startWith(null),
        map((option: string | null) => {
          return option ? this.filter(option) : this.options.slice();
        })
    );
    this.addFilter();
  }

  ngOnChanges() {
    this.addFilter();
  }

  /**
   * This method is used to palce filter on option provided
   */
  addFilter() {
    this.options = this.options.filter(
      (item) => !this.selectedOptions.includes(item)
    );
  }

  /**
   * This method is used to filter out the options
   * @param option: option which need to be filtered
   */
  filter(option: string) {
    return this.options.filter(fruit =>
        fruit.toLowerCase().indexOf(option.toLowerCase()) === 0);
  }

  /**
   * Method is used to update selectedOption array, and also dispatch event so that parent
   * can update the particular.
   * @param value: which need to be updated.
   */
  updateSelectedOptions(value): void {
    this.selectedOptions.push(value.trim());
    this.optionUpdate.emit(this.selectedOptions);
    this.options = this.options.filter( item => !this.selectedOptions.includes(item));
  }

  /**
   * *****************************************************************************************************************
   * Event handlers
   * *****************************************************************************************************************
   */

  /**
   * Method used to add option in selected input
   * @param event: instance of mat chip input event
   */
  add(event: MatChipInputEvent): void {
    // Handled user type input not to be there in org code
    // const input = event.input;
    // const value = event.value;
    // if ((value || '').trim()) {
    //   const index = this.options.findIndex(item => {
    //     return item.toLowerCase() === value.trim().toLowerCase();
    //   });
    //   if (index !== -1) {
    //     this.error = false;

    //     this.updateSelectedOptions(this.options[index]);
    //   } else {
    //     this.optionCtrl.setErrors({incorrect: true});
    //   }
    // }
    // if (input) {
    //   input.value = '';
    // }
  }

  /**
   * This method is used to remove tags from selectedOptions.
   * @param option; which need to removed.
   */
  remove(option: any): void {
    const index = this.selectedOptions.indexOf(option);

    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.optionUpdate.emit(this.selectedOptions);
      this.options = this.options.filter( item => !this.selectedOptions.includes(item));
    }
    if(this.selectedOptions.length==0){
      this.error=true;
    }
    else{
      this.error=false;
    }
  }

  /**
   * This method is used to add option when any option is selected from the list.
   * @param event: instance of autocomplete event.
   */
  selected(event: MatAutocompleteSelectedEvent): void {
    if(event.option.viewValue){
      this.error=false;
    }
    else{
      this.error=true;
    }
    this.updateSelectedOptions(event.option.viewValue);
    this.optionInput.nativeElement.value = '';
    this.optionCtrl.setValue(null);
  }

  validateControl() {
    this.optionCtrl.markAsTouched({ onlySelf: true });
    this.changeDetector.detectChanges();
  }

  resetControls() {
    this.selectedOptions = this.isEdit ? JSON.parse(JSON.stringify(this.previousSelection)) : [];
    this.optionCtrl.setValue(null);
    this.optionInput.nativeElement.value = '';
    this.optionUpdate.emit(this.selectedOptions);
  }
}
