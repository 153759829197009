export class OktaConstants {
  
  // dev and QA
  public static BASE_URL = 'https://ps-siemens.oktapreview.com/';
  public static CLIENT_ID = '0oancufteg6Rj4jWP0h7';
  //public static IDP = '0oaqb04zujdYt3yF10h7';
  // use the new Micorsoft Azure
  public static IDP = '0oa17wfobm2MmmFnr0h8';
  public static ISSUER = 'https://ps-siemens.oktapreview.com/oauth2/ausnd2nhcc8XWrVt80h7';

  /** PROD details */
 // public static BASE_URL = 'https://ps-siemens.okta-emea.com/';
 // public static CLIENT_ID = '0oa4el0kyep2kVsyw0i7';
 // public static IDP = '0oa3ydxyopa7G15570i7';
 // use the new Micorsoft Azure
 // public static IDP = '0oa7xqatztKok1nWY0i7';
 // public static ISSUER = 'https://ps-siemens.okta-emea.com/oauth2/aus4elfq5ur0p0VSa0i7';


  public static REDIRECT_URL = window.origin + '/implicit/callback';
  public static SCOPES = [
    'openid',
    'profile',
    'email'
  ];
};