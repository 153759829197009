const ENV_NAMES = {
    LOCAL: 'local',
    SANDBOX: 'sandbox',
    DEV: 'dev'
};
export const AZURE_GRAPH_API = 'https://graph.microsoft.com/v1.0/users';
export const LOCAL_URL = 'http://localhost:4000';
export const SANDBOX_URL =
    'https://im9e0lgpa4.execute-api.eu-central-1.amazonaws.com/dev/v1';

const getEnvAPIDomain = () => {
    if(
    window.location.host.includes("dev") ||
    window.location.host.includes("localhost")) {
        return 'c7hfj5rui9'; /* DEV New Infra*/
    } else if ( window.location.host.includes("qa")) {
        return 'c6pfhpysl9'; /** QA New Infra 3.0.1*/
    } else {
        return '4332d1wsui'; /** PRD New Infra 3.0.1*/
    }
}

export const DEV_URL = `https://${getEnvAPIDomain()}.execute-api.eu-central-1.amazonaws.com/test_auth/v1/auth`;


const getEnvTOKEN_API = () => {
    if(
    window.location.host.includes("dev") ||
    window.location.host.includes("localhost")) {
        return 'https://src-metadataapi.mosaic-dev.siemens-energy.cloud/v1/callApi'; /* DEV New Infra*/
    } else if ( window.location.host.includes("qa")) {
        return 'https://src-metadataapi.mosaic-qa.siemens-energy.cloud/v1/callApi'; /** QA New Infra 3.0.1*/
    } else {
        return 'https://src-metadataapi.mosaic.siemens-energy.cloud/v1/callApi'; /** PRD New Infra 3.0.1*/
    }
}

export const TOKEN_API = getEnvTOKEN_API();

//  export const DEV_URL = 'https://c6pfhpysl9.execute-api.eu-central-1.amazonaws.com/test_auth/v1/auth'; /** QA New Infra 3.0.1*/
// export const DEV_URL = 'https://c7hfj5rui9.execute-api.eu-central-1.amazonaws.com/test_auth/v1/auth';  /* DEV New Infra*/
 // export const DEV_URL = 'https://4332d1wsui.execute-api.eu-central-1.amazonaws.com/test_auth/v1/auth'; /** PRD New Infra 3.0.1*/
//'https://dit69n86f9.execute-api.eu-central-1.amazonaws.com/test/v1/auth';
// 'https://dq98mktpbd.execute-api.eu-central-1.amazonaws.com/test/v1/auth'
export let ENV = ENV_NAMES.DEV;
