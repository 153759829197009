import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { RoleOperationService } from '../../services/role-operation.service';
import { StateService } from '../../services/state.service';
import { UserOperationService } from '../../services/user-operation.service';
import { ConfirmationDialogComponent } from '../../shared/component/confirmation-dialog/confirmation-dialog.component';
import { UserListDataItem } from '../../shared/model/interfaces/user-list-data-item';
import { RegionModel } from '../../shared/model/region-model';
import { RoleModel } from '../../shared/model/role-model';
import UserModel from '../../shared/model/user-model';
import { AddEditUserComponent } from './components/add-edit-user/add-edit-user.component';
import { AdminConstants } from '../../shared/constants/admin.constants';

@Component({
    selector: 'src-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    userData: Array<UserListDataItem> = [];
    availableUsers: Array<any> = [];
    roles: Array<RoleModel> = [];
    regions: Array<RegionModel> = [];
    userAccess: boolean;

    private subscription: Subscription;
    private userInformation: UserModel;
    searchSubject: Subject<any> = new Subject<any>();
    constructor(
        public matDialog: MatDialog,
        private userService: UserOperationService,
        private roleOperationService: RoleOperationService,
        public router: Router,
        private route: ActivatedRoute,
        private applicationState: StateService
    ) {
        
    }

    async ngOnInit() {
        // let result;
        if (this.applicationState.guid) {
            // result = 
            await this.userService.fetchGuid();
        }
        // if(result.success) {
        const userFunctionalities = localStorage.getItem('userFunctionalities');
        this.userAccess = userFunctionalities ? userFunctionalities.includes(AdminConstants.CREATE_USER) : false;
        this.subscription = new Subscription();
        this.addObservers();
        this.fetchUsersRelatedInformation();
        // }
        
    }

    /**
     * This method is used to adding observers for different data.
     */
    addObservers() {
        this.subscription.add(
            this.applicationState.userListObserver.subscribe((data) => {
                this.userData = data;
            })
        );
        this.subscription.add(
            this.applicationState.roleObserver.subscribe((data) => {
                this.roles = data;
            })
        );
        this.subscription.add(
            this.applicationState.regionObserver.subscribe((data) => {
                this.regions = data;
            })
        );
    }

    /**
     * This method is used to fetch user details.
     */
    fetchUsersRelatedInformation() {
        this.userService.fetchListedUsers('users');
        // this.userService.fetchUser(); // New changes
        this.roleOperationService.fetchRole();
        this.roleOperationService.fetchRegions();
    }

    /**
     * Method handle click on edit user.
     * @param user: user instance whom instance need to be shown
     */
    editUser(user) {
        this.userService.fetchUsersDetails(user);
        this.router.navigate(['edit'], { relativeTo: this.route });
    }

    /**
     * Method handle click on delete user.
     * @param user: user instance whom instance need to be shown
     */
    deleteUser(user) {
        const title = 'Delete User';
        const description = `Are you sure to delete ${user.userName}?`;
        const cancelLabel = 'No';
        const okLabel = 'Yes';
        this.subscription.add(
            this.showConfirmation(title, description, cancelLabel, okLabel)
                .afterClosed()
                .subscribe((data: UserListDataItem) => {
                    if (data) {
                        this.userService.deleteUser([user]);
                    }
                })
        );
    }

    /**
     * This method is used to show confirmation.
     * @param title: Title for the confirmation dialog
     * @param description: Description for the confirmation dialog
     * @param cancelLabel: label of the cancel button for the confirmation dialog
     * @param okLabel: label of the ok button for the confirmation dialog
     */
    showConfirmation(
        title: string,
        description: string,
        cancelLabel?: string,
        okLabel?: string
    ) {
        const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.matDialog.open(
            ConfirmationDialogComponent,
            {
                data: {
                    title,
                    description,
                    cancelLabel: cancelLabel ? cancelLabel : 'Cancel',
                    okLabel: okLabel ? okLabel : 'Ok'
                }
            }
        );
        return dialogRef;
    }

    /**
     * ******************************************************************************
     * Event Handlers
     * ******************************************************************************
     */
    /**
     * This method is used to filter out the table.
     * @param filterValue: string value on which filtering need to be done
     */
    applyFilter(filterValue: string) {
        this.searchSubject.next(filterValue);
    }

    /**
     * This method is used to show user detail
     * @param event instance of user data.
     */
    showUserDetails(event: UserListDataItem) {
        this.userService.fetchUsersDetails(event);
        this.router.navigate(['edit'], { relativeTo: this.route });
    }

    /**
     * This method handles the action taken on selected row.
     * @param action: instance of the user list
     */
    actionClicked(action: any) {
        this.userInformation = action.user;
        if (action.type === 'Edit') {
            this.editUser(action.user);
        } else {
            this.deleteUser(action.user);
        }
    }

    /**
     * This method is used to add user, this will redirect to add/Edit user .
     */
    addUser() {
        this.fetchUsersRelatedInformation();        
        const dialogRef: MatDialogRef<AddEditUserComponent> = this.matDialog.open(
            AddEditUserComponent,
            {
                width: '50vw',
                data: {
                    user: null,
                    allUser: null
                }
            }
        );
        this.subscription.add(
            dialogRef.afterClosed().subscribe((data: UserModel) => {
                if (data) {
                    this.router.navigate(['add'], {
                        relativeTo: this.route,
                        state: {
                            userData: data
                        }
                    });
                }
            })
        );
    }
}
