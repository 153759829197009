import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ListItemOption } from '../../../../shared/model/interfaces/list-item-option';
import { RegionModel } from '../../../../shared/model/region-model';
import { RoleModel } from '../../../../shared/model/role-model';
import { AdminConstants } from '../../../../shared/constants/admin.constants';

@Component({
    selector: 'src-list-selection',
    templateUrl: './list-selection.component.html',
    styleUrls: ['./list-selection.component.scss']
})
export class ListSelectionComponent implements OnInit, OnChanges {
    @Input() roles: Array<RoleModel> = [];
    @Input() selectedRegion: RegionModel;
    @Input() userSelectedRoles: Array<RoleModel> = [];
    filterRoles: Array<RoleModel>;
    rolesToAdd: Array<RoleModel> = [];
    rolesToRemove: Array<RoleModel> = [];
    selectionDone: Boolean = false;
    rolesCount: Array<RoleModel> =[];
    public requiredFieldMessage = AdminConstants.REQUIRED_FIELD_MESSAGE;
    @Output() updateList: EventEmitter<any> = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {
        this.init();
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.init();
    }

    init() {
        this.userSelectedRoles = this.userSelectedRoles
            ? this.userSelectedRoles
            : [];
        this.rolesToAdd = [];
        this.rolesCount = [...this.userSelectedRoles];
        
        if (this.roles) {
            this.filterRolesArray();
        }
    }
    /**
     * This method is used to filter roles.
     */
    filterRolesArray() {
        this.filterRoles = this.roles.filter((role) => {
            return (
                this.userSelectedRoles.findIndex(
                    (item) => item.id === role.id
                ) < 0
            );
        });
        this.filterRoles.sort((item1, item2) =>
            item1.name < item2.name ? -1 : 1
        );
    }

    /**
     * This method is used to find roles
     * @param option: For which region need to be fund.
     */
    findRoles(option: { name: string; id: string }): RoleModel {
        return this.roles.find((item) => {
            return item.id === option.id;
        });
    }

    /**
     * This method is used to add roles into user selected roles, Also update the regions array list.
     */
    addRolesIntoUserArray() {
        this.userSelectedRoles = [...this.rolesToAdd];
        this.rolesCount = [...this.rolesToAdd];
        this.filterRolesArray();
    }

    /**
     * This method is used to remove roles into user selected roles, Also update the regions array list.
     */
    removeRolesIntoUserArray() {
        this.userSelectedRoles = this.userSelectedRoles.filter((role) => {
            return (
                this.rolesToRemove.findIndex((item) => item.id === role.id) < 0
            );
        });
        this.rolesCount = this.userSelectedRoles;
        this.rolesToAdd = this.userSelectedRoles;
        this.rolesToRemove = [];
        this.filterRolesArray();
    }
    /**
     * *********************************************************************
     * Event Handlers
     * *********************************************************************
     */

    /**
     * This method is used to add region in selected region array
     * @param option: contains object holding selected region.
     */
    addRoleIntoArray(option: ListItemOption) {
        this.rolesToAdd = this.userSelectedRoles;
        if (option.selected) {
            this.rolesToAdd.push(this.findRoles(option));
        } else {
            this.rolesToAdd.splice(
                this.rolesToAdd.findIndex((item) => {
                    return item.id === option.id;
                }),
                1
            );
        }
    }

    /**
     * This method is used to update userRegion array.
     * @param operation: String value which identify that array will be remove or added
     */
    updateUserRole(operation: string) {
        this.userSelectedRoles.map(x => {
            if (x.selected) {
                this.selectionDone = true;
            }
        });
        if (operation === 'add' && this.selectionDone && this.rolesToRemove.length == 0) {
            this.addRolesIntoUserArray();
        } else if (
            operation === 'remove' && this.rolesToRemove.length > 0) {
            this.removeRolesIntoUserArray();
        }
        this.selectionDone=false;

        this.saveClicked();
    }

    /**
     * This method is used to update user selection array.
     * @param option: contains object holding selected region in user selected regions
     */
    addRoleInToUserSelection(option: ListItemOption) {
        if (option.selected) {
            this.rolesToRemove.push(this.findRoles(option));
        } else {
            const index = this.rolesToRemove.findIndex(x=>x.id == option.id);
            if (index > -1) {
                this.rolesToRemove.splice(index, 1);
            }
        }
    }

    /**
     * This method is used to save/ update clicked.
     */
    saveClicked() {
        this.updateList.emit({
            region: this.selectedRegion,
            role: this.userSelectedRoles
        });
    }
}
