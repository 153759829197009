import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    constructor(public snackBar: MatSnackBar,
                private zone: NgZone) {
    }

    success(message: string, options?: { delay?: number, dismissible?: boolean }) {
        this.zone.run(() => {
            this.snackBar.open(message, '', {
                duration: 1000
            });
        });
    }

    danger(message: string, options?: { delay?: number, dismissible?: boolean }) {
        this.zone.run(() => {
            // The second parameter is the text in the button.
            // In the third, we send in the css class for the snack bar.
            this.snackBar.open(message, 'X', {panelClass: ['error'],  duration: 3000});
        });
    }
}
