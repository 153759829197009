import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RestMethodService {
    constructor(private httpClient: HttpClient) {}

    /**
     * This method is used to make a post request to provided server.
     * @param url: Api address for the server
     * @param data: Data which needs to be posted on API
     */
    post(url: string, data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return new Promise<any>((resolve: any) => {
            this.httpClient
                .post<any>(url, data, httpOptions)
                .subscribe((result: any) => {
                    resolve(result);
                });
        });
    }

    /**
     * This method is used to send a get request to provided server.
     * @param url: API address for the server.
     */
    get(url: string,options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };}) {
        return new Promise<any>((resolve: any) => {
            this.httpClient.get<any>(url, options).subscribe((data: any) => {
                resolve(data);
            });
        });
    }    

    getObserver(url: string,options?: {
        headers?: HttpHeaders | {
            [header: string]: string | string[];
        };}): Observable<any> {
        return this.httpClient.get<any>(url, options);
    }
    /**
     * This method is used to send a put request to provided server.
     * @param url: API address for the server.
     * @param data: Data which needs to be updated on API
     */
    put(url: string, data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return new Promise<any>((resolve: any) => {
            this.httpClient
                .put<any>(url, data, httpOptions)
                .subscribe((result: any) => {
                    resolve(result);
                });
        });
    }

    /**
     * This method is used to send a delete request to provided server.
     * @param url: API address for the server.
     */
    delete(url: string) {
        return new Promise<any>((resolve: any) => {
            this.httpClient.delete<any>(url).subscribe((result: any) => {
                resolve(result);
            });
        });
    }

    /**
     * This method is used to send a delete request to provided server, along with body
     * @param type
     * @param url
     * @param body
     */
    deleteWithBody(type: string, url: string, body: any) {
        return new Promise<any>((resolve: any) => {
            this.httpClient
                .request(type, url, {
                    body,
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json'
                    })
                })
                .subscribe((result: any) => {
                    resolve(result);
                });
        });
    }
}
