import { DataGroupListItem } from '../../shared/model/interfaces/data-group-list-item';
import { PlantModel } from '../../shared/model/plant-model';
import { RegionModel } from '../../shared/model/region-model';
import { RoleModel } from '../../shared/model/role-model';
import { SiteModel } from '../../shared/model/site-model';
import { UnitModel } from '../../shared/model/unit-model';
import { UserListDataItem } from '../../shared/model/interfaces/user-list-data-item';
import UserModel from '../../shared/model/user-model';

// @dynamic
export class UserDataSerializer {
  static serializeListedUser(data: Array<any>): Array<UserListDataItem> {
    return data.map(item => {
      item.userId = item.id;
      item.userName = item.name;
      delete item.name;
      delete item.id;
      return item;
    });
  }

  /**
   * This method is used to handle user model data.
   * @param data: Instance of user model
   */
  static serializeUserModel(data: any): UserModel {
    const user = new UserModel();
    user.fName = data.name.split(' ')[0];
    user.lName = data.name.split(' ')[1];
    user.alias = data.alias;
    user.email = data.email;
    user.orgCode = data.orgCode;
    user.userId = data.id;
    user.activeUser = data.activeUser;
    user.pccAdminRegion = data.pccAdminRegion;
    user.dataGroups = new Map<RegionModel, Array<DataGroupListItem>>();
    user.regions = new Map<RegionModel, Array<RoleModel>>();
    data.regions.forEach(item => {
      const region: RegionModel = {
        name: item.name,
        id: item.id,
        default: item.isDefault || false
      };
      user.dataGroups.set(region, item.dataGroups);
      user.regions.set(region, item.roles);
    });
    return user;
  }

  /**
   * This method is used to convert User data into json string with modification required for
   * server request.
   * @param data: Model for the user which need to be created.
   * @param defaultRegion: default region
   */
  static serializeUserData(data: UserModel, defaultRegion: RegionModel): any {
    const regions = [];
    let defaultRegionId;
    if (defaultRegion) {
      defaultRegionId = defaultRegion.id;
    }
    data.regions.forEach((value, key) => {
      // set default region if not provided
      if (!defaultRegionId) {
        defaultRegionId = key.id;
      }
      if (key && value) {
        regions.push({
          id: key.id,
          default: key.default,
          roles: value,
          dataGroups: data.dataGroups.get(key)
        });
      }
    });
    const cloneData = {
      userName: `${data.fName} ${data.lName}`,
      email: data.email,
      fName: data.fName,
      lName: data.lName,
      orgCode: data.orgCode,
      jobTitle: data.jobTitle,
      cellPhone: data.cellPhone,
      countryCode: data.countryCode,
      division: data.division,
      locationCode: data.locationCode,
      mailCode: data.mailCode,
      site: data.site,
      displayName: data.displayName,
      searchKey: data.searchKey,
      managerGID: data.managerGID,
      managerName: data.managerName,
      managerEmail: data.managerEmail,
      description: '',
      gid: data.userGid,
      alias: data.alias,
      activeUser: data.activeUser,
      windowsUserid: data.windowsUserid,
      pccAdminRegion: data.pccAdminRegion,
      regions
    };
    return cloneData;
  }

  /**
   * This method is used to serialize data received from server for data.
   * @param data: Json string received from server.
   */
  static serializeDataGroup(data: any): any {
    const dataCollection = data;
    const plantsMap: Map<SiteModel, Array<PlantModel>> = new Map<
      SiteModel,
      Array<PlantModel>
    >();
    const unitsMap: Map<PlantModel, Array<UnitModel>> = new Map<
      PlantModel,
      Array<UnitModel>
    >();
    const sites: Array<SiteModel> = dataCollection.map(site => {
      const siteModel: SiteModel = {
        siteId: site.id,
        siteName: site.name,
        default: false
      };
      let plants: Array<PlantModel> = [];
      if (site.plants) {
        plants = site.plants.map(plant => {
          const plantModel: PlantModel = {
            plantId: plant.id,
            plantName: plant.name
          };
          if (plant.units) {
            unitsMap.set(
              plantModel,
              plant.units.map(unit => {
                return {
                  unitId: unit.id,
                  unitName: unit.name
                };
              })
            );
          } else {
            unitsMap.set(plantModel, []);
          }
          return plantModel;
        });
      }
      plantsMap.set(siteModel, plants);
      return siteModel;
    });
    return { sites, units: unitsMap, plants: plantsMap };
  }
}
