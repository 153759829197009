import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource,MatSort, MatMenu} from '@angular/material';
import {Subject} from 'rxjs';
import {RoleListDataItem} from '../../../../shared/model/interfaces/role-list-data-item';
import { AdminConstants } from '../../../../shared/constants/admin.constants';

@Component({
  selector: 'src-list-role',
  templateUrl: './list-role.component.html',
  styleUrls: ['./list-role.component.scss']
})
export class ListRoleComponent implements OnInit, OnChanges {
  @Input() data: Array<RoleListDataItem> = [];
  @Input() actionControl = true;
  @Output() actionSelect: EventEmitter<any> = new EventEmitter<any>();
  dataSource: MatTableDataSource<RoleListDataItem>;
  displayedColumns: Array<string> = ['name', 'description', 'action'];
  @Input()
  searchSubject: Subject<any> = new Subject<any>();
  searchValue = '';
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor( ) { }
  public selectedRow: any;
  roleAccess: boolean;

  ngOnInit() {
    this.init();
    this.searchSubject.subscribe(value => {
      this.applyFilter(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  init() {
    const userFunctionalities = localStorage.getItem('userFunctionalities');
    this.roleAccess = userFunctionalities ? userFunctionalities.includes(AdminConstants.CREATE_ROLE) : false;
    if (this.data) {
      this.data.sort((item1, item2) => item1.name < item2.name ? -1 : 1);
      this.dataSource = new MatTableDataSource<RoleListDataItem>(this.data);
      if (this.searchValue !== '') {
        this.dataSource.filter = this.searchValue;
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

    }
  }

  /**
   * **************************************************
   * Event Handlers
   * **************************************************
   */

  /**
   * This method is used to filter out the table.
   * @param filterValue: string value on which filtering need to be done
   */
  applyFilter(filterValue: string) {
    this.searchValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  highlight(element: any) {
    this.selectedRow = element;

  }
  /**
   * This method handles click on action items.
   * @param event: Instance of Mouse event
   * @param row: Instance of the row clicked
   * @param type: type of the action button.
   */
  actionItemClicked(event: MouseEvent, row: any, type: string) {
    this.actionSelect.emit({type, role: row});
  }
}
