import { SearchInputComponent } from './shared/component/search-input/search-input.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTreeModule,
    MatMenu,
    MatMenuModule,
    MatListModule,
} from '@angular/material';
import { MdePopoverModule } from '@material-extended/mde';
import { AddEditDialogComponent } from './components/add-edit-dialog/add-edit-dialog.component';
import { DataGroupsComponent } from './components/data-groups/data-groups.component';
import { StatusListSelectionComponent } from './components/list-selection/status-list-selection.component';
import { ProjectContactListComponent } from './components/project-contact-list/project-contact-list.component';
import { ListRoleComponent } from './components/roles/components/list-role/list-role.component';
import { RolesComponent } from './components/roles/roles.component';
// tslint:disable: import-spacing
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { DataAccessComponent } from './components/user-profile/component/data-access/data-access.component';
import { UserListComponent } from './components/users/components/user-list/user-list.component';
import { UsersComponent } from './components/users/users.component';
import { ProjectContactsComponent } from './container/project-contacts/project-contacts.component';
import { ApplicationStateService } from './services/application-state.service';
import { DataGroupService } from './services/data-group.service';
import { ProjectContactService } from './services/project-contact.service';
import { RoleOperationService } from './services/role-operation.service';
import { StateService } from './services/state.service';
import { UserOperationService } from './services/user-operation.service';
import { ConfirmationDialogComponent } from './shared/component/confirmation-dialog/confirmation-dialog.component';
import { AddEditUserComponent } from './components/users/components/add-edit-user/add-edit-user.component';
import { AddEditRoleComponent } from './components/roles/components/add-edit-role/add-edit-role.component';
import { AddEditDataGroupsComponent } from './components/data-groups/components/add-edit-data-groups/add-edit-data-groups.component';
import { DataSetComponent } from './shared/component/data-set/data-set.component';
import { TagInputComponent } from './shared/component/tag-input/tag-input.component';
import { TitleHeaderComponent } from './shared/component/title-header/title-header.component';
import { ListDataGroupsComponent } from './components/data-groups/components/list-data-groups/list-data-groups.component';
import { LabelValueComponent } from './components/user-details/component/label-value/label-value.component';
import { UserInformationComponent } from './components/user-details/component/user-information/user-information.component';
import { SelectRegionComponent } from './components/user-details/component/select-region/select-region.component';
import { ListSelectionComponent } from './components/user-details/component/list-selection/list-selection.component';
import { MultiSelectPaginationListComponent } from './shared/component/multi-select-pagination-list/multi-select-pagination-list.component';
import { GroupListSelectionComponent } from './components/user-details/component/group-list-selection/group-list-selection.component';
import { TreeComponent } from './shared/component/tree/tree.component';
import { UserAuthRoutingModule } from './user-auth-routing.module';
import { AddCriteriaDialogComponent } from './components/add-criteria-dialog/add-criteria-dialog.component';
import { CheckboxListComponent } from './shared/component/checkbox-list/checkbox-list.component';
import { PccAdminComponent } from './components/user-details/component/pcc-admin/pcc-admin.component';
import { InputTagComponent } from './shared/component/input-tag/input-tag.component';
import { AzureADIntegrationService } from './services/azure-ad-integration.service';

@NgModule({
    declarations: [
        UsersComponent,
        UserListComponent,
        RolesComponent,
        ListRoleComponent,
        AddEditUserComponent,
        AddEditRoleComponent,
        ConfirmationDialogComponent,
        TitleHeaderComponent,
        TagInputComponent,
        DataGroupsComponent,
        AddEditDataGroupsComponent,
        DataSetComponent,
        ListDataGroupsComponent,
        LabelValueComponent,
        UserInformationComponent,
        SelectRegionComponent,
        UserDetailsComponent,
        UserProfileComponent,
        ListSelectionComponent,
        DataAccessComponent,
        MultiSelectPaginationListComponent,
        GroupListSelectionComponent,
        TreeComponent,
        ProjectContactsComponent,
        AddEditDialogComponent,
        ProjectContactListComponent,
        StatusListSelectionComponent,
        SearchInputComponent,
        AddCriteriaDialogComponent,
        CheckboxListComponent,
        PccAdminComponent,
        InputTagComponent
    ],
    providers: [
        UserOperationService,
        RoleOperationService,
        DataGroupService,
        StateService,
        ApplicationStateService,
        ProjectContactService,
        AzureADIntegrationService
    ],
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule,
        MatTableModule,
        MatCheckboxModule,
        MatSortModule,
        MatDialogModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatTabsModule,
        MatChipsModule,
        MatAutocompleteModule,
        HttpClientModule,
        MatPaginatorModule,
        MdePopoverModule,
        UserAuthRoutingModule,
        MatDividerModule,
        MatTreeModule,
        MatListModule,
        FormsModule
    ],
    entryComponents: [
        AddEditUserComponent,
        AddEditRoleComponent,
        ConfirmationDialogComponent,
        AddEditDataGroupsComponent,
        AddEditDialogComponent,
        AddCriteriaDialogComponent
    ],
    exports: [
        UsersComponent,
        RolesComponent,
        DataGroupsComponent,
        UserDetailsComponent,
        UserProfileComponent
    ]
})
export class UserAuthModule {}
