import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'src-title-header',
  templateUrl: './title-header.component.html',
  styleUrls: ['./title-header.component.scss']
})
export class TitleHeaderComponent implements OnInit {
  @Input() actionButton = true;
  @Input() actionButtonLocation = 'Right';
  constructor() { }

  ngOnInit() {
  }

}
