import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { DataGroupService } from '../../../../services/data-group.service';
import { StateService } from '../../../../services/state.service';
import { DataGroupListItem } from '../../../../shared/model/interfaces/data-group-list-item';
import { ListItemOption } from '../../../../shared/model/interfaces/list-item-option';
import { PlantModel } from '../../../../shared/model/plant-model';
import { RegionModel } from '../../../../shared/model/region-model';
import { SiteModel } from '../../../../shared/model/site-model';
import { UnitModel } from '../../../../shared/model/unit-model';

@Component({
    selector: 'src-group-list-selection',
    templateUrl: './group-list-selection.component.html',
    styleUrls: ['./group-list-selection.component.scss']
})
export class GroupListSelectionComponent implements OnChanges {
    @Input() groups: Array<DataGroupListItem> = [];
    @Input() userSelectedGroups: Array<any> = [];
    @Input() selectedRegion: RegionModel;
    filterGroups: Array<any> = [];
    groupsToAdd: Array<any> = [];
    groupsToRemove: Array<any> = [];
    infoData: {
        sites: Array<SiteModel>;
        plants: Map<SiteModel, Array<PlantModel>>;
        units: Map<PlantModel, Array<UnitModel>>;
    };
    infoTitle = '';
    @Output() updateList: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private dataService: DataGroupService,
        private applicationState: StateService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.init();
    }

    /**
     * This method is used to initialize view component.
     */
    init() {
        this.userSelectedGroups = this.userSelectedGroups
            ? this.userSelectedGroups
            : [];
        this.groupsToAdd = JSON.parse(JSON.stringify(this.userSelectedGroups));
        if (this.groups) {
            this.filterGroupsArray();
        }
    }

    /**
     * This method is used to filter Groups.
     */
    filterGroupsArray() {
        let arrayToCheck: Array<any> = JSON.parse(JSON.stringify(this.groups));
        arrayToCheck = arrayToCheck.map((item) => {
            delete item.sites;
            delete item.plants;
            delete item.units;
            return item;
        });
        this.filterGroups = arrayToCheck.filter((group) => {
            // return !this.userSelectedGroups.includes(group);  // Todo: not working??
            return (
                this.userSelectedGroups.findIndex(
                    (item) => item.id === group.id
                ) < 0
            );
        });
        this.filterGroups.sort((item1, item2) =>
            item1.name < item2.name ? -1 : 1
        );
    }

    /**
     * This method is used to find Groups
     * @param option: For which region need to be fund.
     */
    findGroups(option: { name: string; id: string }): DataGroupListItem {
        return this.groups.find((item) => {
            return item.id === option.id;
        });
    }

    /**
     * This method is used to add Groups into user selected Groups, Also update the regions array list.
     */
    addGroupsIntoUserArray() {
        this.userSelectedGroups = [...this.groupsToAdd];
        this.filterGroupsArray();
    }

    /**
     * This method is used to remove Groups into user selected Groups, Also update the regions array list.
     */
    removeGroupsIntoUserArray() {
        this.userSelectedGroups = this.userSelectedGroups.filter((group) => {
            return (
                this.groupsToRemove.findIndex((item) => item.id === group.id) <
                0
            );
        });
        this.groupsToAdd = this.userSelectedGroups;
        this.filterGroupsArray();
    }

    /**
     * *********************************************************************
     * Event Handlers
     * *********************************************************************
     */

    /**
     * This method is used to handle click on info button, this is used to open event.
     * @param event
     */
    infoClicked(event: any) {
        this.infoTitle = event.option.name;
        this.dataService.fetchDataGroupInfo(event.option.id).then((result) => {
            this.infoData = {
                sites: result.sites,
                plants: result.plants,
                units: result.units
            };
            this.applicationState.notifierObserver.next(true);
            // setTimeout(() => this.applicationState.notifierObserver.next(true), 400);
        });
    }

    /**
     * This method is used to add region in selected region array
     * @param event: contains object holding selected region.
     */
    addGroupIntoArray(option: ListItemOption) {
        if (option.selected) {
            this.groupsToAdd.push(this.findGroups(option));
        } else {
            this.groupsToAdd.splice(
                this.groupsToAdd.findIndex((item) => {
                    return item.id === option.id;
                }),
                1
            );
        }
    }

    /**
     * This method is used to update userRegion array.
     * @param operation: String value which identify that array will be remove or added
     */
    updateUserGroup(operation: string) {
        if (operation === 'add') {
            this.addGroupsIntoUserArray();
        } else {
            this.removeGroupsIntoUserArray();
        }
        this.saveClicked();
    }

    /**
     * This method is used to update user selection array.
     * @param event: contains object holding selected region in user selected regions
     */
    addGroupInToUserSelection(option: ListItemOption) {
        if (option.selected) {
            this.groupsToRemove.push(this.findGroups(option));
        } else {
            this.groupsToRemove.splice(
                this.groups.findIndex((item) => {
                    return item.id === option.id;
                }),
                1
            );
        }
    }

    /**
     * This method is used to reset all the changes done.
     */
    cancelClicked() {}

    /**
     * This method is used to save/ update clicked.
     */
    saveClicked() {
        this.updateList.emit({
            region: this.selectedRegion,
            group: this.userSelectedGroups
        });
    }
}
