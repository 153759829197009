import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ChangeDetectorRef
} from '@angular/core';
import {MatCheckboxChange} from '@angular/material';
import {StatusItemInterface} from '../../shared/model/interfaces/status-item.interface';
import { AdminConstants } from '../../shared/constants/admin.constants';

@Component({
    selector: 'src-status-list-selection',
    templateUrl: './status-list-selection.component.html',
    styleUrls: ['./status-list-selection.component.scss']
})
export class StatusListSelectionComponent implements OnInit, OnChanges {
    @Input() status: Array<StatusItemInterface> = [];
    @Input() selectedStatus: Array<StatusItemInterface> = [];
    filterStatus: Array<StatusItemInterface>;
    statusToAdd: Array<StatusItemInterface> = [];
    statusToRemove: Array<StatusItemInterface> = [];
    selectedStatusError = false;
    @Output() updateList: EventEmitter<any> = new EventEmitter<any>();
    @Input() isEdit: boolean;
    @Input() previousSelection: Array<StatusItemInterface> = [];
    public requiredFieldMessage = AdminConstants.REQUIRED_FIELD_MESSAGE;
    constructor(private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('this.userSelectedRoles = ', this.selectedStatus);
        this.init();
    }

    init() {
        this.selectedStatus = this.selectedStatus ? this.selectedStatus : [];
        this.statusToAdd = JSON.parse(JSON.stringify(this.selectedStatus));
        if (this.status) {
            this.filterStatusArray();
        }
    }

    /**
     * This method is used to filter roles.
     */
    filterStatusArray() {
        this.filterStatus = this.status.filter(role => {
            return this.selectedStatus.findIndex(item => item.id === role.id) < 0;
        });
        this.filterStatus.sort((item1, item2) => item1.type < item2.type ? -1 : 1);
    }

    /**
     * This method is used to find roles
     * @param option: For which region need to be fund.
     */
    findStatus(option: any): StatusItemInterface {
        return this.status.find(item => {
            return item.id === option.id;
        });
    }

    /**
     * This method is used to add roles into user selected roles, Also update the regions array list.
     */
    addStatusIntoUserArray() {
        this.selectedStatus = [...this.statusToAdd];
        this.filterStatusArray();
    }

    /**
     * This method is used to remove roles into user selected roles, Also update the regions array list.
     */
    removeStatusIntoUserArray() {
        this.selectedStatus = this.selectedStatus.filter(role => {
            return this.statusToRemove.findIndex(item => item.id === role.id) < 0;
        });
        this.statusToAdd = this.selectedStatus;
        this.filterStatusArray();
    }

    /**
     * *********************************************************************
     * Event Handlers
     * *********************************************************************
     */

    /**
     * This method is used to add region in selected region array
     * @param event: contains object holding selected region.
     * @param item: instance of selected item
     */
    addStatusIntoArray(event: MatCheckboxChange, item: StatusItemInterface) {
        if (this.statusToAdd.indexOf(item) === -1) {
        this.statusToAdd.push(item);
        } else {
            this.statusToAdd.splice(
                this.statusToAdd.findIndex(value => {
                    return value.id === item.id;
                }),
                1
            );
        }
    }

    /**
     * This method is used to update userRegion array.
     * @param operation: String value which identify that array will be remove or added
     */
    updateUserStatus(operation: string) {
        if (operation === 'add') {
            this.statusToRemove = [];
            this.addStatusIntoUserArray();
        } else {
            this.statusToAdd = [];
            this.removeStatusIntoUserArray();
        }
        this.saveClicked();
    }

    /**
     * This method is used to update user selection array.
     * @param event: contains object holding selected region in user selected regions
     * @param item: instance of selected item
     */
    addStatusInToUserSelection(event: MatCheckboxChange, item: StatusItemInterface) {
        if (this.statusToRemove.indexOf(item) === -1) {
            this.statusToRemove.push(item);
        } else {
            this.statusToRemove.splice(
                this.status.findIndex(value => {
                    return item.id === value.id;
                }),
                1
            );
        }
    }

    /**
     * This method is used to reset all the changes done.
     */
    cancelClicked() {
        // Todo:
    }

    /**
     * This method is used to save/ update clicked.
     */
    saveClicked() {
        this.selectedStatusError = !(this.selectedStatus && this.selectedStatus.length);
        this.updateList.emit({
            status: this.selectedStatus
        });
    }

    validateControl() {
        this.selectedStatusError = !(this.selectedStatus && this.selectedStatus.length);
        this.changeDetector.detectChanges();
    }

    resetControls() {
        this.selectedStatus = this.isEdit ? this.previousSelection : [];
        this.updateList.emit({
            status: this.selectedStatus
        });
    }
}
