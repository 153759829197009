import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RegionModel } from '../shared/model/region-model';
import UserModel from '../shared/model/user-model';
import { RestMethodService } from './rest-method.service';
import { UserDataSerializer } from './serializers/user-data-serializer';
import { UserListDataItem } from '../shared/model/interfaces/user-list-data-item';
import { StateService } from './state.service';
import { LOCAL_URL, SANDBOX_URL, ENV, DEV_URL } from '../shared/model/env';
import { Observable } from 'rxjs';
import { ToasterService } from './toaster.service';
import { AdminConstants } from '../shared/constants/admin.constants';
import { AzureADIntegrationService } from './azure-ad-integration.service';

@Injectable({
    providedIn: 'root'
})
export class UserOperationService extends RestMethodService {
    private API_DOMAIN =
        ENV === 'local' ? LOCAL_URL : ENV === 'dev' ? DEV_URL : SANDBOX_URL;

    constructor(
        httpClient: HttpClient,
        private applicationState: StateService,
        private azureADService: AzureADIntegrationService,
        private readonly toasterService: ToasterService
    ) {
        super(httpClient);
    }

    /**
     * This method is used to fetch the GUID of the login user.
     */
    fetchGuid() {
        const email = window.localStorage.getItem('userId');
        const gid = window.localStorage.getItem('gid');
        let query = `{"email": "${email}", "gid": "${gid}" }`;
        query = encodeURIComponent(query);
        const serverAddress = `${this.API_DOMAIN}/user-gid?q=${query}`;
        return new Promise<any>((resolve: any) => {
            this.get(serverAddress).then((result) => {
                if (result.success) {
                    this.applicationState.guid = result.data.gid;
                    this.applicationState.id = result.data.id;
                    this.applicationState.userFunctionalities = result.data.loggedInUserFunctionalities;
                    this.applicationState.adminRoleRegion = result.data.adminRoleRegion;
                } else {
                    this.toasterService.danger(AdminConstants.GUID_FETCH_ERROR);
                }
                resolve(result);
            });
        });
    }
    /**
     * This method is used to fetch the users data from the server.
     * @param searchString: string value for the search string
     */
    fetchUser(searchString: string): Observable<any> {
        const serverAddress = `${this.API_DOMAIN}/ad-users?q="${searchString}"`;
        return this.getObserver(serverAddress);
    }

    /**
     * This method is used to fetch subscribed or added users
     */
    fetchListedUsers(source?: any): void {
        let query = `{ "id":1,"name": 0,"gid": 0,"alias": 0,"email":0}`;
        query = encodeURIComponent(query);
        const serverAddress = `${this.API_DOMAIN}/users?sortingParams=${query}&source=${source}`;
        this.get(serverAddress).then((result) => {
            if (result.success) {
                this.applicationState.listedUser = UserDataSerializer.serializeListedUser(
                    result.data.users
                );
            } else {
                this.toasterService.danger(AdminConstants.USER_LIST_FETCH_ERROR);
            }
        });
    }

    /**
     * This method is used to fetch user data.
     * @return list of users
     */
    fetchUsersDetails(event): void {
        const serverAddress = `${this.API_DOMAIN}/users/${event.userId}`;
        this.get(serverAddress).then((result) => {
            this.applicationState.userModel = result.data;
        });
    }

    /**
     * This method is used to create a user.
     * @param data: user information which is being created.
     * @param defaultRegion: Default region
     */
    createUser(data: UserModel, defaultRegion: RegionModel) {
        this.azureADService.fetchResponsibleDetails(data.managerGID).then((response) => {
            if (response && response.value && response.value.length > 0) {
                data.managerName = response.value[0].givenName + ' ' + response.value[0].surname;
                data.managerEmail = response.value[0].mail;
            }
            const create = UserDataSerializer.serializeUserData(
                data,
                defaultRegion
            );
            create['createdBy'] = this.applicationState.guid;
            const serverAddress = `${this.API_DOMAIN}/users`;
            this.post(serverAddress, JSON.stringify(create)).then((result) => {
                if (result.success) {
                    this.fetchListedUsers();
                } else {
                    this.toasterService.danger(result.err || AdminConstants.USER_CREATE_ERROR);
                }
            });

        })
    }

    /**
     * This method is used to edit user information.
     * @param data: user information which need to be edited.
     * @param defaultRegion: Default region
     */
    editUser(data: UserModel, defaultRegion: RegionModel) {
        const edit = UserDataSerializer.serializeUserData(data, defaultRegion);
        delete edit['gid'];
        edit['updatedBy'] = this.applicationState.guid;
        const serverAddress = `${this.API_DOMAIN}/users/${data.userId}`;
        this.put(serverAddress, JSON.stringify(edit)).then((result) => {
            if (result.success) {
                this.toasterService.success(AdminConstants.UPDATE_SUCCESS_MSG);
                this.fetchListedUsers();
            } else {
                this.toasterService.danger(result.err || AdminConstants.USER_UPDATE_ERROR);
            }
        });
    }

    /**
     * This method is used to delete the provided user.
     * @param data instance of user information which need to be deleted.
     */
    deleteUser(data: Array<UserListDataItem>) {
        const serverAddress = `${this.API_DOMAIN}/users/${data[0].userId}`;
        const body = {
            deleteBy: this.applicationState.guid,
            email: data[0].email
        };

        this.deleteWithBody('delete', serverAddress, body).then((result) => {
            if (result.success) {
                this.fetchListedUsers();
            } else {
                this.toasterService.danger(result.err || AdminConstants.USER_DELETE_ERROR);
            }
        });
    }
}
