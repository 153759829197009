import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {FieldNameInterface} from '../shared/model/interfaces/field-name.interface';
import {ProjectContactListItemInterface} from '../shared/model/interfaces/project-contact-list-item.interface';
import {StatusItemInterface} from '../shared/model/interfaces/status-item.interface';
import {ApplicationStateService} from './application-state.service';
import {ToasterService} from './toaster.service';
import { LOCAL_URL, SANDBOX_URL, ENV, DEV_URL } from '../shared/model/env';
import {map, tap} from 'rxjs/operators';
import { ServiceConstant } from '../shared/constants/admin.constants';

@Injectable({
  providedIn: 'root'
})
export class ProjectContactService {
  private API_DOMAIN =
        ENV === 'local' ? LOCAL_URL : ENV === 'dev' ? DEV_URL : SANDBOX_URL;
  private contactApiUrl: string;
  private operatorMethodsUrl: string;
  private statusMethodsUrl: string;
  private projectContactMethodsUrl: string;
  private fieldMethodsUrl: string;
  private gid: string;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private httpClient: HttpClient,
              private applicationState: ApplicationStateService
    ,         public toasterService: ToasterService) {
  }

  /**
   * This method is used to initialize
   */
  init() {
    this.gid = localStorage.getItem('gid');
    this.contactApiUrl = this.applicationState.contactApiServicePath;
    this.operatorMethodsUrl = this.applicationState.operatorApiServicePath;
    this.statusMethodsUrl = this.applicationState.statusApiServicePath;
    this.projectContactMethodsUrl = this.applicationState.projectContactApiPath;
    this.fieldMethodsUrl = this.applicationState.fieldNameApiPath;
  }

  /**
   * This method is used to fetch project contact details.
   */
  fetchProjectContacts() {

    this.httpClient.get(`${this.contactApiUrl}?gid=${this.gid}`)
      .pipe(tap(data => console.log(data)),
        map((value: any) => {
          value.data.projectContacts = value.data.projectContacts.map(contact => {
            return {
              title: contact.title,
              emailAddresses: contact.emailAddresses && contact.emailAddresses.split(','),
              id: contact.id,
              includeFromDL: contact.includeDistributionList,
              isNameShownInPdf: contact.isNameShownInPdf,
              regionId: contact.regionId,
              regionName: contact.regionName,
              status: contact.status,
              query: contact.query,
              displayQuery: contact.displayQuery,
              objectQuery: contact.objectQuery,
              actualQuery: contact.actualQuery,
              orgCodes: contact.orgCodes && contact.orgCodes.split(','),
              responsibleTeamId : contact.responsibleTeamId,
              responsibleTeamName : contact.responsibleTeamName
            };
          });
          return value;
        }))
      .subscribe((value: any) => {
        this.applicationState.projectContactList = value.data.projectContacts;
      });
  }

  /**
   * This method is used to add project contacts.
   * @param projectContact: instance of the project contact which need to be added
   */
  addProjectContact(projectContact: any) {
  //  serialize project contact
    this.httpClient.post(this.contactApiUrl, projectContact, this.httpOptions)
      .subscribe(result => {
        this.fetchProjectContacts();
      });
  }

  /**
   * This method is used to modify existing project contacts.
   * @param projectContact: instance of the project contact which need to be modified
   */
  modifyProjectContact(projectContact: any) {
    // Serialize project Contact
    const projectContactData = JSON.parse(projectContact);
    const putContactUrl = this.contactApiUrl + `/${projectContactData.id}`;
    delete projectContactData.id;
    this.httpClient.put(putContactUrl, JSON.stringify(projectContactData), this.httpOptions)
      .subscribe(result => {
        this.fetchProjectContacts();
      });
  }

  /**
   * This method is used to add project contacts.
   * @param projectContact: instance of the project contact which need to be deleted
   */
  deleteProjectContact(projectContact: ProjectContactListItemInterface) {
    const deleteContactUrl = this.contactApiUrl + `/${projectContact.id}`;
    this.httpClient.delete(deleteContactUrl, this.httpOptions)
      .pipe(tap(value => console.log(value)),
        map(value => value))
      .subscribe(result => {
        this.fetchProjectContacts();
      });
  }

  /**
   * This method is used to fetch operators for the query.
   */
  fetchOperators() {
    this.httpClient.get(this.operatorMethodsUrl)
      .pipe(tap(data => console.log(data)),
        map((value: any) => {
          return value.data.queryBuilderOperators.map(operator => operator);
        }))
      .subscribe((value: Array<string>) => {
        this.applicationState.operatorList = value;
      });
  }

  /**
   * This method is used to fetch status for the query.
   */
  fetchStatus() {
    this.httpClient.get(this.statusMethodsUrl)
      .pipe(tap(data => console.log(data)),
        map((value: any) => value.data.status))
      .subscribe((value: Array<StatusItemInterface>) => {
        this.applicationState.statusList = value;
      });
  }

  /**
   * This method is used to fetch field values for the query.
   */
  fetchFields(region) {
    const regionId = region || 'null';
    this.httpClient.get(this.fieldMethodsUrl + `?regionId=${regionId}`)
      .pipe(tap(data => console.log(data)),
        map((value: any) => value.data.queryBuilderFields.map(field => field)))
      .subscribe((value: Array<FieldNameInterface>) => {
        this.applicationState.fieldList = value;
      });
  }

  /**
   * This method is used to create query
   */
  createQueryMethod(queryMethod: string) {
    return this.httpClient.post(this.applicationState.queryBuilderApiPath, queryMethod, this.httpOptions)
      .pipe(tap(item => {
        if (item['err']) {
          this.toasterService.danger(item['err']['message']);
        }
      }));
  }

  fetchOrgCodes(): void {
    const body = {
        name: 'get_siemens_org_code_list',
        type: 'function',
        params: [
            {"language_pk": "2"}
        ]
    }
    const serverAddress = `${this.API_DOMAIN}/${ServiceConstant.MASTER_DATA_URL}`;
    this.httpClient.post(serverAddress, body, this.httpOptions)
      .subscribe((result: any) => {
        this.applicationState.orgCodesList = result;
      });
  }
}

