import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DataGroupsComponent} from './components/data-groups/data-groups.component';
import {RolesComponent} from './components/roles/roles.component';
import {UserDetailsComponent} from './components/user-details/user-details.component';
import {UserProfileComponent} from './components/user-profile/user-profile.component';
import {UsersComponent} from './components/users/users.component';
import {ProjectContactsComponent} from './container/project-contacts/project-contacts.component';

const routes: Routes = [
    {path: 'users', component: UsersComponent},
    {path: 'users/edit', component: UserDetailsComponent},
    {path: 'users/add', component: UserDetailsComponent},
    {path: 'users/profile', component: UserProfileComponent},
    {path: 'roles', component: RolesComponent},
    {path: 'datagroups', component: DataGroupsComponent},
    {path: 'projectcontacts', component: ProjectContactsComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class UserAuthRoutingModule {}
