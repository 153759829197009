import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { PlantModel } from '../../model/plant-model';
import { SiteModel } from '../../model/site-model';
import { UnitModel } from '../../model/unit-model';

@Component({
    selector: 'src-data-set',
    templateUrl: './data-set.component.html',
    styleUrls: ['./data-set.component.scss']
})
export class DataSetComponent implements OnInit, OnChanges {
    @Input() data: {
        sites: Array<SiteModel>;
        plants: Map<SiteModel, Array<PlantModel>>;
        units: Map<PlantModel, Array<UnitModel>>;
    };
    tableItems: Array<{ name: string; unitName: string }> = [];
    dataSource: MatTableDataSource<{ name: string; unitName: string }>;
    displayedColumns: Array<string> = ['name', 'unitName'];
    spans = [];
    constructor() {}

    ngOnInit() {
        this.init();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.init();
    }

    init() {
        this.tableItems = [];
        this.spans = [];
        this.spreadData();
        this.dataSource = new MatTableDataSource<{
            name: string;
            unitName: string;
        }>(this.tableItems);
    }

    spreadData() {
        if (this.data && this.data.sites.length > 0) {
            this.data.sites.forEach((site) => {
                if (this.data.plants.size > 0 && this.data.plants.get(site)) {
                    this.data.plants.get(site).forEach((plant) => {
                        if (this.data.units.get(plant)) {
                            if (this.data.units.size > 0) {
                                const name = `${site.siteName} - ${plant.plantName}`;
                                const unitString = this.data.units
                                    .get(plant)
                                    .map((unit) => unit.unitName)
                                    .reduce(
                                        (item1, item2) => `${item1}, ${item2}`
                                    );
                                this.tableItems.push({
                                    name,
                                    unitName: unitString
                                });
                            }
                        }
                    });
                }
            });
        }
    }
}
