import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { from, Observable, throwError } from "rxjs";
import { mergeMap, catchError } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.auth.getAccessToken()).pipe(
      mergeMap((token) => {
        let tokenReq = req;
        if (
          !req.headers.get("isTokenReq") ||
          req.headers.get("isTokenReq") !== "false"
        ) {
          tokenReq = req.clone({
            setHeaders: { access_token: `Bearer ${token}` },
          });
        }
        return next.handle(tokenReq);
      }),
      catchError((err) => throwError(err))
    );
  }
}
