import {Component, Inject, OnInit, ViewEncapsulation, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatSelectionList} from '@angular/material';
import {Subject} from 'rxjs';
import {RoleListDataItem} from '../../../../shared/model/interfaces/role-list-data-item';
import { AdminConstants } from '../../../../shared/constants/admin.constants';
import { CheckboxListComponent } from '../../../../shared/component/checkbox-list/checkbox-list.component';
import { TreeComponent } from '../../../../shared/component/tree/tree.component';
import { ToasterService } from '../../../../services/toaster.service';

@Component({
  selector: 'src-add-edit-role',
  templateUrl: './add-edit-role.component.html',
  styleUrls: ['./add-edit-role.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddEditRoleComponent implements OnInit {
  role: RoleListDataItem;
  initialState: RoleListDataItem;
  roleFormGroup: FormGroup;
  titleLabel: string;
  isPrivilegedRole: string;
  hasRegion: string;
  searchSubject: Subject<any> = new Subject<any>();
  operations: Array<{id: string, operation: string}> = [];
  invalidOperations = false;
  functionalities: Array<{id: string, functionality: string, defaultselect: string , selected: boolean}> = [];
  private isEdit = false;
  roleAccess: boolean;
  adminRoleAccess = localStorage.getItem('adminRoleRegion') ? !!localStorage.getItem('adminRoleRegion').length : false;
  public requiredFieldMessage = AdminConstants.REQUIRED_FIELD_MESSAGE;
  @ViewChild(CheckboxListComponent, {static: true}) checkBoxComponent: CheckboxListComponent;
  @ViewChild(TreeComponent, {static: true}) treeComponent: TreeComponent;
  constructor(public dialogRef: MatDialogRef<AddEditRoleComponent>,
              private readonly toasterService: ToasterService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
               }

  ngOnInit() {
    const userFunctionalities = localStorage.getItem('userFunctionalities');
    this.roleAccess = userFunctionalities ? userFunctionalities.includes(AdminConstants.CREATE_ROLE) : false;
    this.role = JSON.parse(JSON.stringify(this.data.role)) || this.createNewRoleData();
    if (this.role.functionalities && this.role.functionalities.length) {
      this.role.functionalities = this.role.functionalities.map(functionality => {
        return Object.assign(functionality, {selected: true});
      });
    } else {
      this.role.functionalities =  this.data.functionalities.filter(functionality => functionality.defaultselect === '1');
    }

    this.operations = this.data.operations;
    this.functionalities = [];
    this.data.functionalities.forEach(functionality => {
      let functionalityObject;
      if (this.role.functionalities && this.role.functionalities.length) {
        for (let index = 0; index <= this.role.functionalities.length - 1 ; index++) {
          const existingFunctionality = this.role.functionalities[index];
          if (existingFunctionality.id === functionality.id || functionality.defaultselect === '1') {
            functionalityObject = Object.assign(functionality, {selected: true});
            break;
          } else {
            functionalityObject = Object.assign(functionality, {selected: false});
          }
        }
      } else {
        functionalityObject = Object.assign(functionality, {selected: functionality.defaultselect === '1' ? true : false});
      }
      this.functionalities.push(functionalityObject);
      this.initialState = JSON.parse(JSON.stringify(this.functionalities));

    });
    // this.functionalities = this.data.functionalities;
    this.titleLabel = this.data.role ? 'Edit Roles' : 'Add Role';
    this.isPrivilegedRole = this.data.role ? this.role.isPrivilegedRole : AdminConstants.NON_PRIVILEGE_ROLE;
    this.isEdit = !!this.data.role;
    this.roleFormGroup = new FormGroup({
      roleName: new FormControl({value: this.role.name, disabled: this.isEdit}, Validators.required),
      roleDesc: new FormControl(this.role.description, Validators.required)
    });
  }

  /**
   * This method is used to return array of statuses with id and item as key
   * @param statuses: Array of statuses
   */
  getStatuses(statuses: Array<{id: string, status: string, operationId: string}>) {
    return statuses.map(status => {
      return {
        id: status.id,
        item: status.status,
        operationId: status.operationId
      };
    });
  }

  /**
   * Function to set privilege role
   */
  setPrivilegeRole(event) {
    this.isPrivilegedRole = event.checked ? AdminConstants.PRIVILEGE_ROLE : AdminConstants.NON_PRIVILEGE_ROLE;
  }

  /**
   * This method is used to return array of operations.
   * @param operations: Array of operations.
   */
  getOperations(operations: Array<{id: string, operation: string, statuses?: []}>) {
    return operations.map(operation => {
      return {
        id: operation.id,
        item: operation.operation,
        children: operation.statuses ? this.getStatuses(operation.statuses) : [] // Todo: need to change as per the data in future
      };
    });
  }

  cancelClicked() {
    this.dialogRef.close(null);
}
  /**
   * Create and return a fresh user data model.
   */
  createNewRoleData(): RoleListDataItem {
    return {
      name: '',
      id: null,
      description: '',
      operations: [],
      functionalities: [],
      isPrivilegedRole: ''
    };
  }

  getMappedOperations(event: any) {
    const operationArr = [];

    event.forEach(element => {
      if (element.operationId) {
        operationArr.forEach(operation => {
          operation.statuses = operation.statuses || [];
          if (operation.id === element.operationId) {
            operation.statuses.push({id: element.id, status: element.item});
          }
        });
      } else {
        operationArr.push({id: element.id, item: element.item});
      }
    });

    return operationArr;
  }

  getMappedFunctionalities() {
    const functionalitiesArr = this.functionalities.filter(functionality => {
      return functionality.selected === true;
    });

    return functionalitiesArr;
  }

  /**
   * ************************************
   * Event Handlers
   * ************************************
   */

  /**
   * This method is used to filter out the table.
   * @param filterValue: string value on which filtering need to be done
   */
  applyFilter(filterValue: string, filterFor: string) {
    this.searchSubject.next({filterValue, filterFor});
  }

  /**
   * This method handle adding of operation in current role.
   * @param event: array of operation which is being added
   */
  operationAdded(event) {
    this.role.operations = this.getMappedOperations(event);
  }

  /**
   * This method handle adding of functionalities in current role.
   * @param event: array of operation which is being added
   */
  functionalitiesAdded(event) {
    if (!(event.defaultselect === AdminConstants.DEFAULT_FUNCTIONALITY)) {
      event.selected = !event.selected;
      this.role.functionalities = this.getMappedFunctionalities();
    }
  }

  /**
   * This method is used to handle save button clicked.
   */
  save(event: MouseEvent) {
    if (this.roleAccess) {
      if (this.roleFormGroup.valid) {
        if (!this.role.operations.length) {
          this.invalidOperations = true;
        } else {
          this.invalidOperations = false;
          this.role.name = this.roleFormGroup.get('roleName').value;
          this.role.description = this.roleFormGroup.get('roleDesc').value;
          this.role.isPrivilegedRole = this.isPrivilegedRole;
          this.dialogRef.close(this.role);
        }
      } else {
        if (!this.role.operations.length) {
          this.invalidOperations = true;
        } else {
          this.invalidOperations = false;
        }
        this.roleFormGroup.markAllAsTouched();
        event.stopImmediatePropagation();
      }
    } else {
      this.toasterService.danger(AdminConstants.ROLE_AUTHORIZATION_ERROR);
    }
  }

  /**
   * This method handles click on refresh button, this will reset the user progress.
   */
  refreshClicked() {
    this.functionalities = JSON.parse(JSON.stringify(this.initialState));
    this.role.functionalities = this.getMappedFunctionalities();
    if (!this.isEdit) {
      this.roleFormGroup.get('roleName').setValue(this.role.name);
    }
    this.roleFormGroup.get('roleDesc').setValue(this.role.description);
    this.treeComponent.resetSelection();
    this.invalidOperations = true;
  }
}
