import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Subject, Subscription } from 'rxjs';
import { RoleOperationService } from '../../services/role-operation.service';
import { StateService } from '../../services/state.service';
import { UserOperationService } from '../../services/user-operation.service';
import { ConfirmationDialogComponent } from '../../shared/component/confirmation-dialog/confirmation-dialog.component';
import { RoleListDataItem } from '../../shared/model/interfaces/role-list-data-item';
import { AddEditRoleComponent } from './components/add-edit-role/add-edit-role.component';
import { RoleModel } from '../../shared/model/role-model';
import { AdminConstants } from '../../shared/constants/admin.constants';

@Component({
  selector: 'src-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  roles: Array<RoleModel> = [];
  private operations: Array<{ id: string; operation: string }> = [];
  private functionalities: Array<{ id: string; functionality: string }> = [];
  private pccRegions: any;
  private subscription: Subscription;
  searchSubject: Subject<any> = new Subject<any>();
  roleAccess: boolean;
  constructor(
    public matDialog: MatDialog,
    private roleService: RoleOperationService,
    private applicationState: StateService,
    private userService: UserOperationService
  ) {
    
  }

  async ngOnInit() {
    // let result;
    if (this.applicationState.guid) {
      //result = 
      await this.userService.fetchGuid();
    }
    // if(result.success) {
    this.subscription = new Subscription();
    this.addObserver();
    this.fetchRole();
    const userFunctionalities = localStorage.getItem('userFunctionalities');
    this.roleAccess = userFunctionalities ? userFunctionalities.includes(AdminConstants.CREATE_ROLE) : false;
    // }
  }

  /**
   * This method is used to fetch user details.
   */
  fetchRole() {
    this.roleService.fetchRole();
    this.roleService.fetchOperations();
    this.roleService.fetchUserFunctionalities();
  }

  /**
   * This method is used to add observer for the data.
   */
  addObserver() {
    this.subscription.add(
      this.applicationState.roleObserver.subscribe(result => {
        this.roles = result;
      })
    );
    this.subscription.add(
      this.applicationState.operationObserver.subscribe(result => {
        this.operations = result;
      })
    );
    this.subscription.add(
      this.applicationState.functionalitiesObserver.subscribe(result => {
        this.functionalities = result;
      })
    );

    this.subscription.add(
      this.applicationState.pccRegionObserver.subscribe(result => {
        this.pccRegions = result;
      })
    )
  }

  /**
   * This method is used to show add/ edit user dialog
   */
  showAddEditDialog(data: RoleListDataItem) {
    const dialogRef: MatDialogRef<AddEditRoleComponent> = this.matDialog.open(
      AddEditRoleComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: {
          role: data,
          operations: this.operations,
          functionalities: this.functionalities
        }
      }
    );
    return dialogRef;
  }

  /**
   * This method is used handle click on edit role button.
   */
  editRole(role: RoleModel) {
    this.roleService.fetchRoleWithOperationAndFunctionalities(role);
    const roleSubscriber = this.applicationState.roleWithOperationAndFunctionalitiesObserver.subscribe(result => {
      this.showAddEditDialog(result)
        .afterClosed()
        .subscribe((data: RoleListDataItem) => {
          roleSubscriber.unsubscribe();
          if (data) {
            this.roleService.editRole(data);
          }
        });
    });
  }

  /**
   * This method is used to handle click on delete role button.
   */
  deleteRole(role: RoleModel) {
    const title = 'Remove Role';
    const desc = `Are you sure to remove the "${role.name}" role permanently?`;
    this.subscription.add(
      this.showConfirmation(title, desc, 'No', 'Yes')
        .afterClosed()
        .subscribe((data: RoleModel) => {
          if (data) {
            this.roleService.deleteRole(role);
          }
        })
    );
  }

  /**
   * This method is used to show confirmation.
   * @param title: Title for the confirmation dialog
   * @param description: Description for the confirmation dialog
   * @param cancelLabel: label of the cancel button for the confirmation dialog
   * @param okLabel: label of the ok button for the confirmation dialog
   */
  showConfirmation(
    title: string,
    description: string,
    cancelLabel?: string,
    okLabel?: string
  ) {
    const dialogRef: MatDialogRef<
      ConfirmationDialogComponent
    > = this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        title,
        description,
        cancelLabel: cancelLabel ? cancelLabel : 'Cancel',
        okLabel: okLabel ? okLabel : 'Ok'
      }
    });
    return dialogRef;
  }

  /**
   * ************************************
   * Event Handlers
   * ************************************
   */

  /**
   * This method is used to filter out the table.
   * @param filterValue: string value on which filtering need to be done
   */
  applyFilter(filterValue: string) {
    this.searchSubject.next(filterValue);
  }

  
  /**
   * THis method is used to open add role dialog, where user can provide details for the new role.
   */
  addRole() {
    this.subscription.add(
      this.showAddEditDialog(null)
        .afterClosed()
        .subscribe((data: RoleListDataItem) => {
          if (data) {
            this.roleService.createRole(data);
          }
        })
    );
  }

  /**
   * This method handles the action taken on selected row.
   * @param action: instance of the user list
   */
  roleActionClicked(action: any) {
    if (action.type === 'Edit') {
      this.editRole(action.role);
    } else {
      this.deleteRole(action.role);
    }
  }
}
