import {Component, Input, OnChanges, OnInit, SimpleChanges, Output, EventEmitter} from '@angular/core';
import UserModel from '../../../../shared/model/user-model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
//import { AdminConstants } from 'projects/user-auth/src/lib/shared/constants/admin.constants';
import { Router } from '@angular/router';
//import { RegionModel } from 'projects/user-auth/src/lib/shared/model/region-model';
import { RegionModel } from '../../../../shared/model/region-model'
import { AdminConstants } from '../../../../shared/constants/admin.constants'; 

@Component({
  selector: 'src-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent implements OnInit, OnChanges {

  userFormGroup: FormGroup;
  @Input() user: UserModel;
  @Input() selectedRegions: Array<RegionModel>;
  @Input() invalidRegion : boolean ;
  @Output() regionSelected: EventEmitter<any> = new EventEmitter<any>();
  fName = '';
  lName = '';
  email = '';
  alias = '';
  activeUser = '';
  constructor(private router: Router) { }

  ngOnInit() {
    this.user.activeUser = this.router.url.includes('add') ? '0' : this.user.activeUser;
    this.init();
    this.userFormGroup = new FormGroup({
      alias: new FormControl(this.user.alias, Validators.required)
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  /**
   * Function to set alias value
   */
  setAlias() {
    this.user.alias = this.userFormGroup.get('alias').value;
    this.userFormGroup.get('alias').setValue(this.user.alias);
  }

  /**
   * Function to set user activate/inactivate value
   */
  setActiveInactivateUser(event) {
    this.user.activeUser = event.checked ? AdminConstants.ACTIVE_USER : AdminConstants.INACTIVE_USER;
  }
  /**
   * This method is used to initialize view bindings.
   */
  init() {
    this.fName = this.user.fName;
    this.lName = this.user.lName;
    this.email = this.user.email;
    this.alias = this.user.alias;
    this.activeUser = this.user.activeUser;
  }
}
