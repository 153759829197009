import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatOption,
    MatSelect
} from '@angular/material';
import { StateService } from '../../../../services/state.service';
import { DataGroupListItem } from '../../../../shared/model/interfaces/data-group-list-item';
import { PlantModel } from '../../../../shared/model/plant-model';
import { SiteModel } from '../../../../shared/model/site-model';
import { UnitModel } from '../../../../shared/model/unit-model';

@Component({
    selector: 'src-add-edit-data-groups',
    templateUrl: './add-edit-data-groups.component.html',
    styleUrls: ['./add-edit-data-groups.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddEditDataGroupsComponent implements OnInit, OnChanges {
    group: DataGroupListItem;
    dataFormGroup: FormGroup;
    titleLabel = '';
    sites: Array<SiteModel>;
    plants: Map<SiteModel, Array<PlantModel>>;
    units: Map<PlantModel, Array<UnitModel>>;
    plantsForSelection: Array<PlantModel> = [];
    disableUnitSelection = false;
    disablePlantSelection = false;
    addedSites: {
        sites: Array<SiteModel>;
        plants: Map<SiteModel, Array<PlantModel>>;
        units: Map<PlantModel, Array<UnitModel>>;
    } = {
        sites: [],
        plants: new Map<SiteModel, Array<PlantModel>>(),
        units: new Map<PlantModel, Array<UnitModel>>()
    };
    private isEdit = false;
    @ViewChild('plantCombo', { static: false }) plantOption: MatSelect;
    @ViewChild('unitCombo', { static: false }) unitOption: MatSelect;
    @ViewChild('allPlants', { static: false }) private allPlants: MatOption;
    @ViewChild('allUnits', { static: false }) private allUnits: MatOption;
    constructor(
        public dialogRef: MatDialogRef<AddEditDataGroupsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private applicationState: StateService
    ) {}

    ngOnInit() {
        this.init();
        this.dataFormGroup = new FormGroup({
            groupName: new FormControl(
                {
                    value: this.data.group ? this.data.group.name : '',
                    disabled: this.isEdit
                },
                Validators.required
            ),
            groupDesc: new FormControl(
                this.data.group ? this.data.group.description : '',
                Validators.required
            ),
            plants: new FormControl(null),
            units: new FormControl(null),
            sites: new FormControl(null)
        });
        this.addFormListeners();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.init();
    }

    /**
     * This method initialize component with initial value according to data.
     */
    init() {
        let groupData = this.data.group;
        let isEdit = true;
        this.titleLabel = 'Edit Data Group';
        if (!this.data.group) {
            groupData = this.createNewGroupData();
            this.titleLabel = 'Add Data Group';
            isEdit = false;
        }
        this.initializeDefaultValues(groupData, this.titleLabel, isEdit);
    }

    cancelClicked() {
        this.dialogRef.close(null);
    }

    /**
     * This method is used to add listener on site, plant and unit form controls, so any change on those we can take
     * our action.
     */
    addFormListeners() {
        this.dataFormGroup.controls.sites.valueChanges.subscribe((value) => {
            this.handleSitesSelection(value);
        });
        this.dataFormGroup.controls.plants.valueChanges.subscribe((value) => {
            this.handlePlantsSelection(value);
        });
        this.dataFormGroup.controls.units.valueChanges.subscribe((value) => {
            this.handleUnitsSelection(value);
        });
    }

    /**
     * This method handle changes on site selection. From here we will handle all site selection and
     * custom site selection.
     * @param value: Selected value for the sites
     */
    handleSitesSelection(value: SiteModel) {
        this.togglePlantUnitSelection(false, true);
        if (!this.addedSites.sites.includes(value as SiteModel)) {
            this.addedSites.sites.push(value as SiteModel);
        }
        this.plantsForSelection = this.getPlantsForSiteSelection();
    }

    /**
     * This method is used to handle changes in plant selection, this will handle the all plant selection condition.
     * @param value: value for the plants
     */
    handlePlantsSelection(value: PlantModel) {
        const siteValue = this.dataFormGroup.controls.sites.value;
        let plantArray = this.addedSites.plants.get(siteValue);
        if (plantArray) {
            const index = plantArray.findIndex(
                (item) => item.plantId === value.plantId
            );
            plantArray = index !== -1 ? plantArray : [value, ...plantArray];
        } else {
            plantArray = [value];
        }
        this.addedSites.plants.set(siteValue, plantArray);
        this.togglePlantUnitSelection(false, false);
    }

    /**
     * This method is used to handle the selection of units in the view.
     * @param value: Array of plant and unit Model
     */
    handleUnitsSelection(value: Array<UnitModel>) {
        const unitPlantMap: Map<PlantModel, Array<UnitModel>> = new Map<
            PlantModel,
            Array<UnitModel>
        >();
        const index = value.findIndex(
            (item: UnitModel | number) => (item as number) === 0
        );
        if (index > 0) {
            value.splice(index, 1);
        }
        const plant = this.dataFormGroup.controls.plants.value;
        this.units.get(plant).forEach((unit) => {
            const i = value.findIndex((item) => item.unitId === unit.unitId);
            if (i !== -1) {
                let unitArray = unitPlantMap.get(plant);
                if (!unitArray) {
                    unitArray = [];
                }
                unitArray.push(unit);
                unitPlantMap.set(plant, unitArray);
            }
        });
        unitPlantMap.forEach((units, plantItem) => {
            this.addedSites.units.set(plantItem, units);
        });
        this.updateListing();
    }

    /**
     * This method is used to update grid view by providing new instance when required to render.
     */
    updateListing() {
        this.addedSites = {
            sites: this.addedSites.sites,
            plants: this.addedSites.plants,
            units: this.addedSites.units
        };
    }

    /**
     * Enable/ Disable the Plant and unit selection.
     * @param plant: flag value for the plant
     * @param unit: flag value for the plant
     */
    togglePlantUnitSelection(plant, unit) {
        this.disableUnitSelection = unit;
        this.disablePlantSelection = plant;
    }
    /**
     * This method is used to initialize default values for the view.
     * @param groupData: value of group
     * @param titleLabel: String value shown as title for the view
     * @param isEdit: flag which will determine view is in edit mode
     */
    initializeDefaultValues(groupData, titleLabel, isEdit) {
        this.initializeDataSet(
            this.data.sites,
            this.data.plants,
            this.data.units
        );
        this.group = groupData;
        const addedSites = this.sites.filter((site) => {
            return (
                groupData.sites.findIndex(
                    (item) => item.siteId === site.siteId
                ) !== -1
            );
        });
        const addedUnits = new Map<PlantModel, Array<UnitModel>>();
        groupData.units.forEach((value, key) => {
            this.units.forEach((unitsArray, plant) => {
                if (plant.plantId === key.plantId) {
                    const units: Array<UnitModel> = unitsArray.filter(
                        (unit) => {
                            return (
                                value.findIndex(
                                    (item) => item.unitId === unit.unitId
                                ) !== -1
                            );
                        }
                    );
                    addedUnits.set(plant, units);
                }
            });
        });
        const addedPlants: Map<SiteModel, Array<PlantModel>> = new Map<
            SiteModel,
            Array<PlantModel>
        >();
        groupData.plants.forEach((value, key) => {
            this.plants.forEach((plantArray, site) => {
                if (site.siteId === key.siteId) {
                    const plants: Array<PlantModel> = plantArray.filter(
                        (plant) => {
                            return (
                                value.findIndex(
                                    (item) => item.plantId === plant.plantId
                                ) !== -1
                            );
                        }
                    );
                    addedPlants.set(site, plants);
                }
            });
        });
        // Force binding
        this.addedSites = {
            sites: addedSites,
            units: addedUnits,
            plants: addedPlants
        };
        this.titleLabel = titleLabel;
        this.isEdit = isEdit;
    }

    /**
     * This method is used to initialize default value for the data related information.
     * @param sites: Array of SiteModel which include all the available sites.
     * @param plants: Map collection for the Plants.
     * @param units: Map collection for the Units.
     */
    initializeDataSet(sites, plants, units) {
        this.sites = [...sites];
        this.plants = plants;
        this.units = units;
    }

    /**
     * This method is used to create new group data for adding.
     */
    createNewGroupData(): DataGroupListItem {
        return {
            name: '',
            description: '',
            sites: [],
            plants: new Map<SiteModel, Array<PlantModel>>(),
            units: new Map<PlantModel, Array<UnitModel>>(),
            id: null
        };
    }

    /**
     * This method is used to provide array of plants to be shown as option for the plant drop down.
     */
    getPlantsForSiteSelection() {
        const siteValue = this.dataFormGroup.controls.sites.value;
        const plants: Array<PlantModel> = [];
        plants.push(...this.plants.get(siteValue));
        this.allUnits.deselect();
        return plants;
    }

    /**
     * This method return all the units which are there for the selected sites and plants.
     */
    getAllUnitForSelection(): Array<UnitModel> {
        const siteValue = this.dataFormGroup.controls.sites.value;
        const units: Array<UnitModel> = [];
        if (siteValue) {
            this.plants.get(siteValue).forEach((plant) => {
                units.push(...this.units.get(plant));
            });
        }
        return units;
    }

    /**
     * ******************************************************************************************
     * Event Handlers
     * ******************************************************************************************
     */

    toggleUnitPerOne(all) {
        if (this.allUnits.selected) {
            this.allUnits.deselect();
            return false;
        }
        if (
            this.dataFormGroup.controls.units.value.length ===
            this.getAllUnitForSelection().length
        ) {
            this.allUnits.select();
        }
    }

    toggleAllUnitSelection() {
        if (this.allUnits.selected) {
            this.dataFormGroup.controls.units.patchValue([
                ...this.getAllUnitForSelection(),
                0
            ]);
        } else {
            this.dataFormGroup.controls.units.patchValue([]);
        }
    }

    /**
     * This method is used to save the data groups.
     */
    save() {
        if (this.dataFormGroup.valid) {
            const siteMapping = [];
            this.addedSites.sites.forEach((site) => {
                const plantUnit = [];
                if (this.addedSites.plants.get(site)) {
                    this.addedSites.plants.get(site).forEach((plant) => {
                        if (this.addedSites.units.get(plant)) {
                            plantUnit.push({
                                plantId: plant.plantId,
                                plantName: plant.plantName,
                                units: this.addedSites.units
                                    .get(plant)
                                    .map((item) => {
                                        return {
                                            unitId: item.unitId,
                                            unitName: item.unitName
                                        };
                                    })
                            });
                        }
                    });
                }
                siteMapping.push({
                    siteId: site.siteId,
                    siteName: site.siteName,
                    plants: plantUnit
                });
            });
            const dataGroup = {
                name: this.dataFormGroup.get('groupName').value,
                description: this.dataFormGroup.get('groupDesc').value,
                createdBy: this.applicationState.guid,
                siteMapping
            };
            if (this.data.group) {
                dataGroup['id'] = this.data.group.id;
                dataGroup['updatedBy'] = this.applicationState.guid;
                delete dataGroup.createdBy;
            }
            this.dialogRef.close(dataGroup);
        }
    }

    /**
     * This method is used to refresh data groups. It will reset pop up to its initial state.
     */
    refreshClicked() {
        this.init();
    }
}
