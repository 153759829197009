import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, OnChanges, SimpleChanges} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material';
import {Subject} from 'rxjs';
import {TreeFlatNode} from '../../model/tree-flat-node';
import {TreeNode} from '../../model/tree-node';
import { AdminConstants } from '../../constants/admin.constants';

@Component({
  selector: 'src-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TreeComponent implements OnInit, OnChanges {

  flatNodeMap = new Map<TreeFlatNode, TreeNode>();
  nestedNodeMap = new Map<TreeNode, TreeFlatNode>();
  selectedParent: TreeFlatNode | null = null;
  treeControl: FlatTreeControl<TreeFlatNode>;
  treeFlattener: MatTreeFlattener<TreeNode, TreeFlatNode>;
  dataSource: MatTreeFlatDataSource<TreeNode, TreeFlatNode>;
  checklistSelection = new SelectionModel<TreeFlatNode>(true /* multiple */);
  @Input() options: Array<TreeNode> = [];
  @Input() selectedOption: Array<TreeNode> = [];
  @Input() searchSubject: Subject<any> = new Subject<any>();
  @Output() optionUpdate: EventEmitter<any> = new EventEmitter<any>();
  public noRecordMessage = AdminConstants.NO_RECORDS_FOUND;
  private initialSelectionOption;
  constructor() {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
        this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<TreeFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  }

  getLevel = (node: TreeFlatNode) => node.level;

  isExpandable = (node: TreeFlatNode) => node.expandable;

  getChildren = (node: TreeNode): TreeNode[] => node.children;

  // tslint:disable-next-line:variable-name
  hasChild = (_: number, _nodeData: TreeFlatNode) => _nodeData.expandable;

  ngOnInit() {
    if (this.options) {
      this.initializeTree();
      this.searchSubject.subscribe(value => {
        if (value.filterFor === 'operations') {
          this.filterTree(value.filterValue);
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedOption.currentValue && !this.initialSelectionOption) {
      this.initialSelectionOption = this.selectedOption;
    }
  }

  /**
   * This method is used to initialize tree so that proper data can be visualize.
   */
  initializeTree(dataSource= null) {
    this.dataSource.data = dataSource || this.options;
    const selectedNodes: Array<TreeFlatNode> = [];
    this.dataSource.data.forEach(node => {
      const selectedNode = this.selectedOption.find(option => option.item === node.item && option.id === node.id);
      if (selectedNode) {
        selectedNodes.push(this.transformer(selectedNode, 0));
        if (selectedNode.children.length) {
          selectedNode.children.forEach(childNode => {
            const selectedChildNode = this.transformer(childNode, 1);
            selectedNodes.push(selectedChildNode);
          });
        }
      }
    });
    this._setNode(selectedNodes);
  }

  /**
   * _setNode: Function to filter selected node
   */
  _setNode(selectedNodes: TreeFlatNode[]) {
    selectedNodes.forEach(node => {
      for (const key of this.flatNodeMap.keys()) {
        if (key.id === node.id && key.item === node.item && key.operationId === node.operationId) {
          this.checklistSelection.select(key);
          break;
        }
      }
    });

  }

  deSelectNodes(selectedNodes: TreeFlatNode[]) {
    selectedNodes.forEach(node => {
      for (const key of this.flatNodeMap.keys()) {
        if (key.id === node.id && key.item === node.item && key.operationId === node.operationId) {
          this.checklistSelection.deselect(key);
          break;
        }
      }
    });
  }
  /**
   * This is used to filter the value of tree data source
   * @param filterValue: String value for search
   */
  filterTree(filterValue: string) {
    const dataSource = this.options.filter(item => {
      return item.item.toLowerCase().startsWith(filterValue.toLowerCase());
    });
    this.initializeTree(dataSource);
  }

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TreeNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = (existingNode && existingNode.item === node.item && existingNode.id === node.id)
        ? existingNode
        : new TreeFlatNode();
    flatNode.item = node.item;
    flatNode.id = node.id;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    flatNode.operationId = node.operationId || '';
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TreeFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    this.checkAllParentsSelection(node , false);
    // send event updated item to parent
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: TreeFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node , true);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TreeFlatNode , value): void {
    let parent: TreeFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
    if(value){
      this.checklistSelection.toggle(node);
      this.checklistSelection.toggle(node);
      }
    this.optionUpdate.emit(this.checklistSelection.selected.map(item => item));
  }
 /** Check root node checked state and change it accordingly */
 checkRootNodeSelection(node: TreeFlatNode): void {
  const nodeSelected = this.checklistSelection.isSelected(node);
  const descendants = this.treeControl.getDescendants(node);
  let check = false;
  const descAllSelected = descendants.some(child =>{
    if(this.checklistSelection.isSelected(child)){
      check = true;
      return false;
    }
  }
  );
  if (nodeSelected && !check) {
    this.checklistSelection.deselect(node);
  } else if (!nodeSelected && check) {
    this.checklistSelection.select(node);
  }
}
 /** Whether all the descendants of the node are selected. */
 descendantsAllSelected(node: TreeFlatNode): boolean {
  const descendants = this.treeControl.getDescendants(node);
  let check = false;
  const result = descendants.every(child =>{
    if(this.checklistSelection.isSelected(child)){
        check = true;
        return false;
    }
  });
  return check;
}
/** Whether part of the descendants are selected */
descendantsPartiallySelected(node: TreeFlatNode): boolean {
  const descendants = this.treeControl.getDescendants(node);
  const result = descendants.some(child => this.checklistSelection.isSelected(child));
  return result && !this.descendantsAllSelected(node);
}
  /* Get the parent node of a node */
  getParentNode(node: TreeFlatNode): TreeFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  resetSelection() {
    const nodesToReset: Array<TreeFlatNode> = [];
    if (this.initialSelectionOption && this.initialSelectionOption.length) {
      this.dataSource.data.forEach(node => {
        let flag = false;
        for (let index = 0; index <= this.initialSelectionOption.length - 1; index++) {
          const option = this.initialSelectionOption[index];
          if (option.item === node.item && option.id === node.id) {
            flag = true;
            break;
          }
        }
        if (!flag) {
            nodesToReset.push(this.transformer(node, 0));
            if (node.children.length) {
              node.children.forEach(childNode => {
                const selectedChildNode = this.transformer(childNode, 1);
                nodesToReset.push(selectedChildNode);
              });
            }
          }
      });
      this.deSelectNodes(nodesToReset);
    } else {
      this.initializeTree();
    }
  }
}
