import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'src-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchInputComponent implements OnInit, OnChanges {

  @Input() placeHolder = 'Search';
  @Input() resetSearch: false;
  @Output() searchInitiated: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('searchText', {static: false}) searchInput: any;
  constructor() { }

  ngOnInit() {
    if (this.resetSearch) {
      this.searchInput.value = '';
    }
  }

  ngOnChanges(change: SimpleChanges) {
    if (this.resetSearch) {
      this.searchInput.value = '';
    }
  }
}
