import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSelectChange } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataGroupService } from '../../services/data-group.service';
import { RoleOperationService } from '../../services/role-operation.service';
import { StateService } from '../../services/state.service';
import { UserOperationService } from '../../services/user-operation.service';
import { ConfirmationDialogComponent } from '../../shared/component/confirmation-dialog/confirmation-dialog.component';
import { DataGroupListItem } from '../../shared/model/interfaces/data-group-list-item';
import { UserListDataItem } from '../../shared/model/interfaces/user-list-data-item';
import { RegionModel } from '../../shared/model/region-model';
import { RoleModel } from '../../shared/model/role-model';
import UserModel from '../../shared/model/user-model';
import { ToasterService } from '../../services/toaster.service';
import { UserDataSerializer } from '../../services/serializers/user-data-serializer';
import { AdminConstants } from '../../shared/constants/admin.constants';
import { PCCRegionModel } from '../../shared/model/pcc-region-model';

@Component({
    selector: 'src-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UserDetailsComponent implements OnInit, OnDestroy {
    title = 'Default Region';
    user: UserModel;
    roles: Array<RoleModel> = [];
    dataGroups: Array<DataGroupListItem> = [];
    regions: Array<RegionModel> = [];
    selectedRegions: Array<RegionModel> = [];
    defaultRegion: RegionModel;
    userForm: FormGroup;
    invalidRegion = false;
    invalidPccRegion = false;
    invalidRoles = false;
    userAccess: boolean;
    pccRegions: any;
    isPccAdmin: any = false;
    roleRegionArray = [];
    // Subscriber
    private subscription: Subscription = new Subscription();
    constructor(
        private applicationState: StateService,
        private userService: UserOperationService,
        public matDialog: MatDialog,
        private dataGroupService: DataGroupService,
        private router: Router,
        private route: ActivatedRoute,
        private roleService: RoleOperationService,
        private readonly toasterService: ToasterService
    ) {
        if (this.applicationState.guid === 'system') {
            this.userService.fetchGuid();
        }
    }

    ngOnInit() {
        const userFunctionalities = localStorage.getItem('userFunctionalities');
        this.userAccess = userFunctionalities ? userFunctionalities.includes(AdminConstants.CREATE_USER) : false;
        this.userForm = new FormGroup({
            region: new FormControl('')
        });
        this.user = history.state ? history.state.userData : '';
        this.fetchData();
        this.init();
        this.addObservers();
    }

    /**
     * Service call to fetch data.
     */
    fetchData() {
        this.dataGroupService.fetchDataGroups();
        this.roleService.fetchRole();
        this.roleService.fetchRegions();
        this.roleService.fetchPCCRegions();
    }

    /**
     * This method is used to initialize view related property.
     */
    init() {
        if (this.user) {
            if (this.user.regions) {
                this.user.regions.forEach((value, key) => {
                    // key.default = true;
                    this.selectedRegions.push(key);
                    if (key.default) {
                        this.defaultRegion = key;
                    }
                });
            }
        }
        if (this.defaultRegion) {
            this.userForm.controls.region.setValue(this.defaultRegion);
        }
        // Enabling pcc admin dropdown based on role selection
        if (this.user && this.user.regions) {
            this.selectedRegions.forEach(reg => {
                let existingRoles: any = this.user.regions.get(reg) || '';
                if (existingRoles && existingRoles.length) {
                    existingRoles.forEach(element => {
                         if (this.roleRegionArray.includes(element.name)) {
                            this.isPccAdmin = true;
                        }
                    });
                }
            });
        }
    }

    /**
     * This method is used to add observer for the data required for this view.
     */
    addObservers() {
        this.subscription.add(
            this.applicationState.userDataObserver.subscribe(
                (result: UserModel) => {
                    if (Object.keys(result).length) {
                        const data = UserDataSerializer.serializeUserModel(
                            result
                        );
                        this.user = {
                            regions: data.regions,
                            email: data.email,
                            dataGroups: data.dataGroups,
                            userId: data.userId,
                            alias: data.alias,
                            fName: data.fName,
                            lName: data.lName,
                            userGid: data.userGid,
                            activeUser: data.activeUser,
                            windowsUserid: data.windowsUserid,
                            pccAdminRegion: data.pccAdminRegion,
                            orgCode: data.orgCode
                        };
                        this.init(); // Need to update UI
                    } else {
                        this.toasterService.danger(
                            AdminConstants.NO_USER_FOUND
                        );
                    }
                }
            )
        );

        this.subscription.add(
            this.applicationState.regionObserver.subscribe(
                (data: Array<RegionModel>) => {
                    this.regions = data;
                }
            )
        );
        this.subscription.add(
            this.applicationState.roleObserver.subscribe(
                (data: Array<RoleModel>) => {
                    data.forEach(i => {
                        i['userFunctionalityName'].includes(AdminConstants.MANAGE_PCC_ADMIN_REGION) && this.roleRegionArray.push(i.name);
                    });
                    // Filter the roles based on user functionality
                    const userFunctionalities = localStorage.getItem('userFunctionalities');
                    if (!userFunctionalities.includes(AdminConstants.MANAGE_PRIVILEGED_ROLE)) {
                        data.splice(
                            data.findIndex((item) => {
                                return this.roleRegionArray.includes(item.name);
                            }),
                            1
                        );
                    }
                    this.roles = data;
                }
            )
        );
        this.subscription.add(
            this.applicationState.dataGroupObserver.subscribe(
                (data: Array<DataGroupListItem>) => {
                    this.dataGroups = data;
                }
            )
        );
        this.subscription.add(
            this.applicationState.pccRegionObserver.subscribe(
                (data: Array<PCCRegionModel>) => {
                    this.pccRegions = data;
                }
            )
        )
    }
    /* Selected Tab Change Resetting the values */
    updateSelection() {
        this.selectedRegions.forEach((region) => {
            let regionRoles = this.user.regions.get(region);
            if (regionRoles) {
                regionRoles.map(x=>{
                    x.selected=false;
                });
            }
            this.user.regions.set(region, regionRoles);
        });
    }

    /**
     * This method is used to update the user region model.
     */

    updateUserRegions() {
        for (let region of this.user.regions.keys()) {
            if (!(this.selectedRegions.includes(region))) {
                this.user.regions.delete(region);
            }
        }

        this.selectedRegions.forEach((region) => {
            if (!region.hasOwnProperty('default')) {
                region['default'] = false;
            }
            let regionRoles = this.user.regions.get(region);
            if (!regionRoles) {
                regionRoles = [];
            }
            // this.user.regions.clear();
            this.user.regions.set(region, regionRoles);
        });
        if (this.selectedRegions.length) {
            this.selectedRegions[0].default = true;
            this.defaultRegion = this.selectedRegions[0];
        }
        else {
            this.defaultRegion = new RegionModel();
        }
        this.selectedRegions = [...this.selectedRegions];
    }

    /**
     * This method is used to show confirmation.
     * @param title: Title for the confirmation dialog
     * @param description: Description for the confirmation dialog
     * @param cancelLabel: label of the cancel button for the confirmation dialog
     * @param okLabel: label of the ok button for the confirmation dialog
     */
    showConfirmation(
        title: string,
        description: string,
        cancelLabel: string,
        okLabel: string
    ) {
        const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.matDialog.open(
            ConfirmationDialogComponent,
            {
                data: {
                    title,
                    description,
                    cancelLabel,
                    okLabel
                }
            }
        );
        return dialogRef;
    }
    /**
     * *********************************************************************************
     * Event Handlers
     * *********************************************************************************
     */

    /**
     * This method is used to handle when regions selected.
     * @param event: instance of region selected
     */
    regionSelected(event: { region: RegionModel; selected: boolean }) {
        if (event.selected) {
            this.selectedRegions.push(event.region);
        } else {
            this.selectedRegions.splice(
                this.selectedRegions.findIndex((item) => {
                    return item.name === event.region.name;
                }),
                1
            );
        }
        this.updateUserRegions();
    }

    /**
     * This method is used to handle the default region
     * @param event: instance of select event
     */
    defaultRegionSelected(event: any) {
        this.selectedRegions.map((item) => {
            item.default = item.name === event.target.value;
            if (item.default) {
                this.defaultRegion = item;
            }
            return item;
        });
    }

    onPccRegionSelected(event: any) {
        this.user.pccAdminRegion = event.value || null;
    }

    /**
     * This method is used to update the data group in user model.
     * @param event: contains the updated list of user model
     */
    dataGroupListUpdated(event: any) {
        const key = event.region;
        const value = event.group;
        if (key && value) {
            this.user.dataGroups.set(key, value);
        }
    }

    /**
     * This method is used to updated the roles for a region.
     * @param event contain mapping of the roles with the region.
     */
    regionRoleSelected(event: any) {
        this.isPccAdmin = false;
        const key = event.region;
        const value = event.role;
        if (key && value) {
            this.user.regions.set(key, value);
        }
        event.role.forEach(element => {
            if (element.userFunctionalityName && element.userFunctionalityName.includes(AdminConstants.MANAGE_PCC_ADMIN_REGION)) {
                this.isPccAdmin = true;
            }
        });
    }

    /**
     * This method is used to save user information.
     */
    save() {
        if (this.userAccess) {
            if (this.selectedRegions && this.selectedRegions.length > 0) {
                if (this.checkForTheRoles(this.user)) {
                    if(this.checkForPccAdminRegion(this.user)) {
                        if (this.user.userId) {
                            this.userService.editUser(this.user, this.defaultRegion);
                        } else {
                            this.userService.createUser(this.user, this.defaultRegion);
                        }
                        this.router.navigate(['../../users'], { relativeTo: this.route });
                    } else {
                        this.invalidPccRegion = true;
                    }
                } else {
                    this.invalidRoles = true;
                }
            } else {
                this.invalidRegion = true;
            }
        } else {
            this.toasterService.danger(AdminConstants.USER_AUTHORIZATION_ERROR);
        }
    }

    checkForPccAdminRegion(data: UserModel): boolean {
        let toReturn = true;
        data.regions.forEach((value, key) => {
            let roleIndex = value.findIndex(item => this.roleRegionArray.includes(item.name));
            if(roleIndex != -1 && !data.pccAdminRegion) {
                toReturn = false;
            }
        });
        return toReturn;
    }

    checkForTheRoles(data: UserModel): boolean {
        let toReturn = true;
        data.regions.forEach((value, key) => {
            if (value.length == 0) {
                this.user.pccAdminRegion = null;
                toReturn = false;
            } else {
                let roleIndex = value.findIndex(item => this.roleRegionArray.includes(item.name));
                if (roleIndex == -1) {
                    this.user.pccAdminRegion = null;
                }
            }

        });
        return toReturn;
    }

    /**
     * Method is used to handle press on cancel button this will show confirmation dialog and will operate on user operation
     * selected with confirmation dialog, pressing YEs will take user to User listing.
     */
    back() {
        const title = 'Confirm Revert';
        const desc = `This will revert all the unsaved changes. Are you sure want to leave this page?`;
        const cancel = 'NO';
        const ok = 'YES';
        this.subscription.add(
            this.showConfirmation(title, desc, cancel, ok)
                .afterClosed()
                .subscribe((data: UserListDataItem) => {
                    if (data) {
                        this.router.navigate(['../../users'], {
                            relativeTo: this.route
                        });
                    }
                })
        );
    }
    /**
     * This method is used to destroy the method.
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.router = null;
        this.roles = null;
        this.selectedRegions = null;
        this.user = null;
    }
}
