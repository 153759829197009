import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AuthService } from "./services/auth/auth.service";
import {
  MsalModule,
  MsalAngularConfiguration,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR,
  MsalService,
} from "@azure/msal-angular";
import { LoginComponent } from "./components/login/login.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { ImplicitCallbackComponent } from "./components/implicit-callback/implicit-callback.component";
import { Configuration } from "msal";

export function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId:
        window.location.host.includes("qa") ||
        window.location.host.includes("dev") ||
        window.location.host.includes("localhost")
          ? "d94a7072-83c4-4c1e-9a81-9d26556e9f0b"
          : "79d81c18-d477-4fca-bb51-b2f66cceb83e",
      authority:
        "https://login.microsoftonline.com/254ba93e-1f6f-48f3-90e6-e2766664b477",
      validateAuthority: true,
      redirectUri: window.location.origin + "/implicit/callback",
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  };
}

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: false,
    consentScopes: ["user.read", "openid", "email"],
    extraQueryParameters: {},
  };
}

export const protectedResourceMap: [string, string[]][] = [
  ["https://graph.microsoft.com/v1.0/me", ["user.read"]],
];
@NgModule({
  declarations: [LoginComponent, LoaderComponent, ImplicitCallbackComponent],
  imports: [CommonModule, MsalModule],
  providers: [
    AuthService,
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory,
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory,
    },
    MsalService,
  ],
  exports: [LoginComponent],
})
export class LoginAuthModule {}
