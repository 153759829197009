import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Login } from '../../model/enums/login.enum';
import { LoginConstant } from '../../model/login-constant';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'src-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @Output() loginResponse: EventEmitter<any> = new EventEmitter<any>();
  @Input() logoutRedirect = true;
  @Input() logoutUrl = LoginConstant.DEFAULT_LOGOUT_REDIRECT;
  @Input() autoLogin = true;
  showLoader = false;
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.authService.route = this.router;
    this.authService.doRedirect = this.logoutRedirect;
    this.authService.authStatus.subscribe(evt => {
      this.handleLoginResponse(evt);
    });

    if (this.router.url.indexOf('implicit/callback') !== -1) {
      this.showLoader = true;
      this.authService.handleAuthRedirect();
    }

    // auto login
    this.authService.isAuthenticated().then(authenticated => {
      if (authenticated && this.autoLogin) {
        this.router.navigate([LoginConstant.DEFAULT_LOGIN_REDIRECT]);
      }
    });
  }

  /**
   * This method is responsible for handling login response, it will emit login with correct status.
   * @param evt: Login status
   */
  handleLoginResponse(evt: Login) {
    this.showLoader = false;
    switch (evt) {
      case Login.LOGIN_SUCCESS:
        this.loginResponse.emit(Login.LOGIN_SUCCESS);
        break;
      case Login.LOGIN_FAILED:
        this.loginResponse.emit(Login.LOGIN_FAILED);
        break;
      case Login.LOG_OUT_FAILED:
        this.loginResponse.emit(Login.LOG_OUT_FAILED);
        break;
      case Login.LOG_OUT_SUCCESS:
        this.loginResponse.emit(Login.LOG_OUT_SUCCESS);
        break;
    }
  }

  /**
   * **********************************************************************
   * Event Handlers
   * **********************************************************************
   */

  /**
   * This method is used to handle click on login button.
   */
  loginClicked(event: MouseEvent) {
    this.showLoader = true;
    this.authService.login().then((response: any) => {
      if (response.status === 0) {
        this.showLoader = false;
      }
    });
  }
}
