import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { MatCheckboxChange, MatPaginator } from '@angular/material';
import { MdePopoverTrigger } from '@material-extended/mde';
import { StateService } from '../../../services/state.service';
import { ListItemOption } from '../../model/interfaces/list-item-option';

@Component({
    selector: 'src-multi-select-pagination-list',
    templateUrl: './multi-select-pagination-list.component.html',
    styleUrls: ['./multi-select-pagination-list.component.scss']
})
export class MultiSelectPaginationListComponent implements OnInit, OnChanges {
    @Input() options: Array<ListItemOption>;
    @Input() showInfo: boolean;
    @Input() paginationRequired: boolean = true;
    options2: Array<ListItemOption> = [];
    @Output() infoSelected: EventEmitter<any> = new EventEmitter<any>();
    @Output() selectedOptions: EventEmitter<ListItemOption> = new EventEmitter<
        ListItemOption
    >();
    @ViewChild(MdePopoverTrigger, { static: false }) trigger: MdePopoverTrigger;
    constructor(private applicationState: StateService) {}

    ngOnInit() {
        this.init();
        this.applicationState.notifierObserver.subscribe((result) => {
            if (this.showInfo) {
                this.trigger.togglePopover();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.init();
    }

    init() {
        this.options = this.options ? this.options : [];
        setTimeout((x) => {
            this.getData({ pageIndex: 0, pageSize: 10 }, this.options);
        });
    }

    /**
     * ********************************************************************************
     * Event Handlers
     * ********************************************************************************
     */

    /**
     * This method is handler for info icon click. this will notify paten component
     * @param event: Mouse event
     * @param option: option info instance which is clicked
     */
    infoClicked(event: MouseEvent, option: { name: string; id: string }) {
        event.stopImmediatePropagation();
        this.infoSelected.emit({ event, option });
    }

    /**
     * This method is used to filter out the table.
     * @param filterValue: string value on which filtering need to be done
     */
    applyFilter(filterValue: string) {
        const filterArray = this.options.filter((item) => {
            return item.name.search(filterValue) !== -1;
        });
        this.getData({ pageIndex: 0, pageSize: 10 }, filterArray);
    }

    /**
     * This method is used to initiate pagination
     * @param obj: instance of page.
     * @param optionArray: Array from which filtration need to be done
     */
    getData(obj, optionArray: Array<{ name: string; id: string }>) {
        optionArray = optionArray ? optionArray : this.options;
        let index = 0;
        const startingIndex = obj.pageIndex * obj.pageSize;
        const endingIndex = startingIndex + obj.pageSize;
        if (this.paginationRequired) {
            this.options2 = optionArray.filter(() => {
                index++;
                return index > startingIndex && index <= endingIndex;
            });
        } else {
            this.options2 = optionArray;
        }
        this.options2.map((item) => {
            item.selected = false;
            return item;
        });
    }

    /**
     * This method is used to handle selection of region
     * @param option: instance of option selected
     */
    optionSelected(option: ListItemOption) {
        this.options2.find(
            (item) => option.id === item.id
        ).selected = !option.selected;
        this.options2 = [...this.options2];
        this.selectedOptions.emit(option);
    }
}
