import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material';
import { RegionModel } from '../../../../shared/model/region-model';

@Component({
  selector: 'src-select-region',
  templateUrl: './select-region.component.html',
  styleUrls: ['./select-region.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectRegionComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() regions: Array<RegionModel>;
  @Input() selectedRegions: Array<RegionModel>;
  @Output() regionSelected: EventEmitter<{
    region: RegionModel;
    selected: boolean;
  }> = new EventEmitter<{ region: RegionModel; selected: boolean }>();
  constructor() {}

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  /**
   * This method is used to initialize view related data.
   */
  init() {
    this.regions = this.regions ? this.regions : [];
    this.selectedRegions = this.selectedRegions ? this.selectedRegions : [];
  }

  /**
   * This method is used to find that region is selected or not
   */
  isSelected(region: RegionModel): boolean {
    let selected: boolean;
    if (this.selectedRegions.length > 0) {
      selected = !(
        this.selectedRegions.findIndex(item => item.id === region.id) < 0
      );
    }
    return selected;
  }
  /**
   * ************************************************************
   * Event Handlers
   * ************************************************************
   */

  /**
   * This method is used to identify which checkbox is selected.
   * @param event: instance of select event
   * @param region: instance of selected region
   */
  regionChecked(event: MatCheckboxChange, region: RegionModel) {
    this.regionSelected.emit({ region, selected: event.checked });
  }
}
