import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { StateService } from '../../services/state.service';
import { UserOperationService } from '../../services/user-operation.service';
import UserModel from '../../shared/model/user-model';
import { RegionModel } from '../../shared/model/region-model';
import { RoleModel } from '../../shared/model/role-model';
import { Subscription } from 'rxjs';
import { ToasterService } from '../../services/toaster.service';
import { AdminConstants } from '../../shared/constants/admin.constants';

@Component({
  selector: 'src-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  title = 'Default Region';
  user: UserModel;
  regions: Array<RegionModel> = [];
  selectedRegions: Array<RegionModel> = [];
  defaultRegion: RegionModel;
  private subscription: Subscription = new Subscription();
  constructor(
    private applicationState: StateService,
    private userService: UserOperationService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly toasterService: ToasterService
  ) {
    if (this.applicationState.guid.toLowerCase() === 'system') {
      this.userService.fetchGuid().then(() => {
        this.fetchData();
      });
    }
  }

  ngOnInit() {
    this.addObservers();
  }

  /**
   * Service call to fetch data.
   */
  fetchData() {
    const userId = this.applicationState.id;
    this.userService.fetchUsersDetails({ userId });
  }

  /**
   * This method is used to add observer for the data required for this view.
   */
  addObservers() {
    this.subscription.add(
      this.applicationState.userDataObserver.subscribe((data: UserModel) => {
        if (Object.keys(data).length) {
          this.regions = this.convertMappedRegionToRegion(data.regions);
          this.user = {
            regions: data.regions,
            email: data.email,
            dataGroups: data.dataGroups,
            userId: data.userId,
            alias: data.alias,
            fName: data.fName,
            lName: data.lName,
            userGid: data.userGid,
            activeUser: data.activeUser,
            windowsUserid: data.windowsUserid,
            pccAdminRegion: data.pccAdminRegion,
            orgCode: data.orgCode
          };
        } else {
          this.toasterService.danger(AdminConstants.NO_RECORDS_FOUND);
        }
      })
    );
  }

  convertMappedRegionToRegion(regionData: Map<RegionModel, RoleModel[]>) {
    const regions: RegionModel[] =  [];
    let isDefault = false;
    regionData.forEach((value: Array<RoleModel>, key: RegionModel) => {
      regions.push(key);
      if (key.default) {
        isDefault = true;
        this.selectedRegions.push(key);
      }
    });
    if (!isDefault) {
      this.selectedRegions.push(regions[0]);
    }
    return regions;
  }

  /**
   * This method is used to handle when regions selected.
   * @param event: instance of region selected
   */
  regionSelected(event: { region: RegionModel; selected: boolean }) {
    if (event.selected) {
      this.selectedRegions.push(event.region);
    } else {
      this.selectedRegions.splice(
        this.selectedRegions.findIndex(item => {
          return item.name === event.region.name;
        }),
        1
      );
    }
  }

  back() {
    this.router.navigate(['../../users'], { relativeTo: this.route });
  }
  /**
   * This method is used to destroy the method.
   */
  ngOnDestroy(): void {
    this.user = null;
  }
}
