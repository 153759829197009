import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Subject } from 'rxjs';
import { UserListDataItem } from '../../../../shared/model/interfaces/user-list-data-item';
import { AdminConstants } from '../../../../shared/constants/admin.constants';

@Component({
  selector: 'src-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnChanges {
  @Input() data: Array<UserListDataItem> = [];
  @Input() actionControl = true;

  @Output() actionSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() userClicked: EventEmitter<UserListDataItem> = new EventEmitter<
    UserListDataItem
  >();

  dataSource: MatTableDataSource<UserListDataItem>;
  displayedColumns: Array<string> = [
    'userId',
    'gid',
    'userName',
    'email',
    'alias',
    'orgCode',
    'lineManager',
    'region',
    'activeUser',
    'action'
  ];
  @Input()
  searchSubject: Subject<any> = new Subject<any>();
  searchValue = '';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor() {}
  public selectedRow: any;
  userAccess: boolean;
  ngOnInit() {
    this.init();
    this.searchSubject.subscribe(value => {
      this.applyFilter(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  init() {
    const userFunctionalities = localStorage.getItem('userFunctionalities');
    this.userAccess = userFunctionalities ? userFunctionalities.includes(AdminConstants.CREATE_USER) : false;
    if (this.data) {
      this.data.sort((item1, item2) => item1.userName < item2.userName ? -1 : 1);
      this.dataSource = new MatTableDataSource<UserListDataItem>(this.data);
      if (this.searchValue !== '') {
        this.dataSource.filter = this.searchValue;
      }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  /**
   * this method return the default region for the user
   * @param element: current row
   */
  getDefaultRegion(element: any) {
    let defaultRegion;
    defaultRegion = element.defaultRegion ? element.defaultRegion.name : 'N/A';
    defaultRegion = defaultRegion ? defaultRegion : 'N/A';
    return defaultRegion;
  }
  /**
   * **************************************************
   * Event Handlers
   * **************************************************
   */

  /**
   * This method is used to filter out the table.
   * @param filterValue: string value on which filtering need to be done
   */
  applyFilter(filterValue: string) {
    this.searchValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * This method handles click on user name , from here we will open user details.
   * @param event: instance of mouse event.
   * @param column: instance of table row.
   */
  listItemClicked(event: MouseEvent, column: UserListDataItem) {
    this.userClicked.emit(column);
  }
  highlight(element: any) {
    this.selectedRow = element;

  }
  /**
   * This method handles click on action items.
   * @param row: Instance of the row clicked
   * @param type: type of the action button.
   */
  actionItemClicked(row: any, type: string) {
    this.actionSelect.emit({ type, user: row });
  }
}
