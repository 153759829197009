import { Component, Inject, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatCheckboxChange,
    MatDialog,
    MatDialogRef,
    MatTableDataSource
} from '@angular/material';
import { ApplicationStateService } from '../../services/application-state.service';
import { ProjectContactService } from '../../services/project-contact.service';
import { RoleOperationService } from '../../services/role-operation.service';
import { StateService } from '../../services/state.service';
import { UserOperationService } from '../../services/user-operation.service';
import { CriteriaItemInterface } from '../../shared/model/interfaces/criteria-item.iterface';
import { ProjectContactListItemInterface } from '../../shared/model/interfaces/project-contact-list-item.interface';
import { StatusItemInterface } from '../../shared/model/interfaces/status-item.interface';
import { UserListDataItem } from '../../shared/model/interfaces/user-list-data-item';
import { RegionModel } from '../../shared/model/region-model';
import { AddCriteriaDialogComponent } from '../add-criteria-dialog/add-criteria-dialog.component';
import { AdminConstants } from '../../shared/constants/admin.constants';
import { TagInputComponent } from '../../shared/component/tag-input/tag-input.component';
import { StatusListSelectionComponent } from '../list-selection/status-list-selection.component';
import { ToasterService } from '../../services/toaster.service';
import { InputTagComponent } from '../../shared/component/input-tag/input-tag.component';
import { PCCRegionModel } from '../../shared/model/pcc-region-model';

@Component({
    selector: 'src-add-edit-dialog',
    templateUrl: './add-edit-dialog.component.html',
    styleUrls: ['./add-edit-dialog.component.scss']
})
export class AddEditDialogComponent implements OnInit {
    contactFormGroup: FormGroup;
    isEdit: boolean;
    selectedRegion: any;
    selectedResponsibleTeam: any;
    id: string;
    title: string;
    regions: Array<RegionModel>;
    pccRegions: Array<PCCRegionModel>;
    orgCodes: any =[];
    queryPreview = '';
    selectedContacts: Array<string> = [];
    selectedOrgCodes: Array<string> = [];
    users: Array<UserListDataItem> = [];
    selectedAddress: Array<UserListDataItem> = [];
    previousSelectedOrgCodesList: any = [];
    selectedOrgCodesList: any = [];
    previousSelectedAddress: Array<UserListDataItem> = [];
    prevUsersList: any = [];
    prevOrgCodesList: any[];
    previousUserSelectedStatus: Array<StatusItemInterface> = [];
    status: Array<StatusItemInterface> = [];
    userSelectedStatus: Array<StatusItemInterface> = [];
    validQuery = true;
    private includeInDistributionList = false;
    private includeInCoverPage = false;
    criteriaTableData: Array<CriteriaItemInterface> = [];
    errorLabel: string;
    queryPreviewError = false;
    projectContactAccess: boolean;
    gid = localStorage.getItem('gid');
    public requiredFieldMessage = AdminConstants.REQUIRED_FIELD_MESSAGE;
    @ViewChild(TagInputComponent, { static: true }) tagInput: TagInputComponent;
    @ViewChild(InputTagComponent, { static: true }) inputTag: InputTagComponent;
    @ViewChild(StatusListSelectionComponent, { static: true }) statusListSelectionControl: StatusListSelectionComponent;
    constructor(
        public dialogRef: MatDialogRef<AddEditDialogComponent>,
        public matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: ProjectContactListItemInterface,
        private applicationState: ApplicationStateService,
        private otherState: StateService,
        private userService: UserOperationService,
        private roleService: RoleOperationService,
        private contactService: ProjectContactService,
        private readonly toasterService: ToasterService
    ) {
        this.userService.fetchListedUsers('projectContact');
        this.isEdit = !!this.data;
        this.addObserver();
        this.roleService.fetchRegions(this.gid);
        this.roleService.fetchPCCRegions();
        this.title = this.isEdit
            ? 'Edit Project Contacts'
            : 'Add Project Contacts';
        this.id = this.isEdit
            ? this.data.id
            : null;
        this.selectedContacts = this.isEdit ? this.data.emailAddresses : [];
        this.selectedOrgCodes = this.isEdit ? this.data.orgCodes : [];
    }

    ngOnInit() {
        const userFunctionalities = localStorage.getItem('userFunctionalities');
        this.projectContactAccess = userFunctionalities ? userFunctionalities.includes(AdminConstants.CREATE_PROJECT_CNTCT) : false;
        this.contactFormGroup = new FormGroup({
            title: new FormControl(
                { value: this.isEdit ? this.data.title : '', disabled: this.isEdit },
                Validators.required
            ),
            region: new FormControl(null, Validators.required),
            responsibleTeam: new FormControl(null, Validators.required),
            emailAddress: new FormControl(null),
            dl: new FormControl(null),
            cp: new FormControl(null),
        });
        this.queryPreview = this.isEdit ? this.data.displayQuery.replace(/"/g, '') : '';
        if (this.isEdit) {
            this.criteriaTableData = this.data.actualQuery;
        }
    }

    /**
     * Method is used to add observer for the data
     */
    addObserver() {
        this.applicationState.status.subscribe((value) => {
            this.status = value;
            if (this.isEdit) {
                this.userSelectedStatus = this.status.filter((statusItem) => {
                    const valueData = this.data.status.find(
                        (item) => statusItem.id === item.statusId.toString()
                    );
                    return !!valueData;
                });
                this.previousUserSelectedStatus = this.userSelectedStatus;
            }
        });
        this.otherState.userListObserver.subscribe((users) => {
            this.users = users;
            if (this.isEdit) {
                // this.selectedAddress  = JSON.parse(JSON.stringify(this.users));
                if(this.data.emailAddresses) {
                    this.data.emailAddresses.map(email => {
                        let result = false;
                        this.users.forEach(item => {
                            if (item.email === email) {
                                result = true;
                                this.selectedAddress.push(item);
                            }
                        });
                        if (!result) {
                            this.selectedAddress.push({ email: email });
                        }
                    });
                }
                this.previousSelectedAddress = JSON.parse(JSON.stringify(this.selectedAddress));
                this.prevUsersList = JSON.parse(JSON.stringify(this.previousUsersList(this.previousSelectedAddress)));
                this.prevOrgCodesList = (this.data && this.data.orgCodes && this.data.orgCodes.length) ? this.data.orgCodes : [];
            }
        });
        this.applicationState.orgCodesList.subscribe((orgCodes) => {
            this.orgCodes = orgCodes;
            if (this.isEdit) {
                this.selectedOrgCodesList = this.orgCodes.filter((org) => {
                    if(this.data.orgCodes) {
                        return this.data.orgCodes.includes(org.value);
                    }
                });
                this.previousSelectedOrgCodesList = this.selectedOrgCodesList;
            }
        })
        this.otherState.pccRegionObserver.subscribe((pccRegions: Array<PCCRegionModel>) => {
            this.pccRegions = pccRegions;
        });
        this.otherState.regionObserver.subscribe((regions) => {
            this.regions = regions;
            if (this.isEdit) {
                this.updateFormValue();
            }
        });
    }

    /**
     * Method is placed to update form value, in case of edit dialog.
     */
    updateFormValue() {
        let region;
        if(this.regions) {
            region = this.regions.find(
                (item) => item.id === this.data.regionId
            );
            this.selectedRegion = region;
        }
        
        if(this.data.responsibleTeamId && this.pccRegions){
            const responsibleTeam = this.pccRegions.find(
                (item) => item.value === this.data.responsibleTeamId
            );
            this.selectedResponsibleTeam = responsibleTeam;
        }
        this.contactFormGroup.setValue({
            title: this.data.title,
            region: region || null,
            responsibleTeam : this.selectedResponsibleTeam || null,
            emailAddress: null,
            dl: this.data.includeFromDL !== '0',
            cp: this.data.isNameShownInPdf !== '0'
        });
    }

    /**
     * This method is used to provide Array<string>.
     * @param selectedUsers: Array of user model
     */
    getOptionFrom(selectedUsers: Array<UserListDataItem>): Array<string> {
        return selectedUsers.map((user) => user.email);
    }

    getOrgCodesOptionFrom(selectedOrgCodes: any): Array<string> {
        return selectedOrgCodes.map((user) => user.value);
    }

    /** This method is used to provide previous selected users list */
    previousUsersList(previousSelectedAddress) {
        if (previousSelectedAddress && previousSelectedAddress.length) {
            return previousSelectedAddress.map(item => item.email);
        }
        return [];
    }


    /**
     * This method is used to provide array string for the email.
     * @param email: Array of string.
     */
    mapToContact(email: Array<string>) {
        return email;
    }

    /**
     * This method is used to generate query data, which we need to send to server.
     */
    private generateQueryData(): Array<any> {
        const queryData = [];
        const pointer = [];
        const openLocation = [];

        this.criteriaTableData.forEach((row, index) => {
            if (
                row.opening === '{{{' ||
                row.opening === '{{' ||
                row.opening === '{'
            ) {
                if (row.opening === '{{') {
                    const firstData = this.createQueryData(null, true);
                    const secondData = this.createQueryData(row, true);
                    firstData.condition.push(secondData);
                    this.pushDataInTable(
                        pointer,
                        queryData,
                        true,
                        index === 0,
                        firstData
                    );
                    pointer.push(...[firstData, secondData]);
                } else if (row.opening === '{') {
                    const firstData = this.createQueryData(row, true);
                    this.pushDataInTable(
                        pointer,
                        queryData,
                        true,
                        index === 0,
                        firstData
                    );
                    pointer.push(firstData);
                } else {
                    const firstData = this.createQueryData(null, true);
                    const secondData = this.createQueryData(null, true);
                    const thirdData = this.createQueryData(row, true);
                    secondData.condition.push(thirdData);
                    firstData.condition.push(secondData);
                    this.pushDataInTable(
                        pointer,
                        queryData,
                        true,
                        index === 0,
                        firstData
                    );
                    pointer.push(...[firstData, secondData, thirdData]);
                }
            } else if (
                row.closing === '}}}' ||
                row.closing === '}}' ||
                row.closing === '}'
            ) {
                if (row.closing === '}}') {
                    const data = this.createQueryData(row, false);
                    pointer[
                        pointer.length - 3
                    ].operator = row.andOr.toLowerCase();
                    pointer[pointer.length - 3].condition.push(data);
                    pointer.pop();
                    pointer.pop();
                } else if (row.closing === '}') {
                    const data = this.createQueryData(row, false);
                    pointer[
                        pointer.length - 2
                    ].operator = row.andOr.toLowerCase();
                    pointer[pointer.length - 2].condition.push(data);
                    pointer.pop();
                } else {
                    const data = this.createQueryData(row, false);
                    pointer[
                        pointer.length - 4
                    ].operator = row.andOr.toLowerCase();
                    pointer[pointer.length - 4].condition.push(data);
                    pointer.pop();
                    pointer.pop();
                    pointer.pop();
                }
            } else {
                const firstData = this.createQueryData(row, true);
                if (index === 0) {
                    queryData.push(firstData);
                } else {
                    const indexPoint =
                        index === this.criteriaTableData.length - 1
                            ? 0
                            : pointer.length - 1;
                    pointer[indexPoint].condition.push(firstData);
                }
                pointer[index] = firstData;
            }
        });
        return queryData;
    }

    pushDataInTable(
        pointer: any,
        queryData: Array<any>,
        operator: boolean,
        firstIndex: boolean,
        firstData: any
    ) {
        if (firstIndex) {
            const ff = this.createQueryData(null, true);
            ff.condition.push(firstData);
            queryData.push(ff);
            pointer.unshift(ff);
        } else {
            pointer[pointer.length - 1].condition.push(firstData);
        }
    }

    /**
     * This method is used to create query data
     * @param row: data
     * @param operator: value to add operator value
     */
    createQueryData(row, operator) {
        return {
            fieldName: row ? row.fieldName.fieldName.replace(/\s/g, '') : null,
            compare: row ? row.compare : null,
            type: row ? row.fieldName.type : null,
            fieldValue: row ? row.fieldValue : null,
            nestedOperator: row
                ? operator
                    ? row.andOr.toLowerCase()
                    : null
                : null,
            operator: null,
            condition: []
        };
    }

    /**
     * This method is used to add query data.
     * @param data: table data
     */
    private addQueryDialog(data: {
        tableData: Array<CriteriaItemInterface>;
        query: string;
        region: string;
    }) {
        const dialogRef: MatDialogRef<AddCriteriaDialogComponent> = this.matDialog.open(
            AddCriteriaDialogComponent,
            {
                data,
                disableClose: true
            }
        );
        dialogRef
            .afterClosed()
            .subscribe(
                (value: {
                    tableData: Array<CriteriaItemInterface>;
                    query: string;
                }) => {
                    if (value) {
                        this.errorLabel = null;
                        this.criteriaTableData = value.tableData;
                        this.queryPreview = value.query;
                    }
                    this.queryPreviewError = !!(this.queryPreview.trim() === '');
                }
            );
    }

    /**
     * **************************************************
     * Event Handlers
     * **************************************************
     */

    /**
     * This method handles addition of new query row
     */
    addQueryRow() {
        let region = this.contactFormGroup.controls.region.value ? this.contactFormGroup.controls.region.value.id : '';
        this.addQueryDialog({
            tableData: this.criteriaTableData,
            query: this.queryPreview,
            region: region,
        });
    }

    /**
     * Handler for DL check box.
     * @param event: Check box event
     */
    includeInDL(event: MatCheckboxChange) {
        this.includeInDistributionList = event.checked;
    }

    /**
     * Handler for the CP check box.
     * @param event : checkbox event
     */
    includeInCP(event: MatCheckboxChange) {
        this.includeInCoverPage = event.checked;
    }

    /**
     * This method is used to updated the contact.
     * @param emails: Array of email strings
     */
    contactUpdated(emails: Array<string>) {
        this.selectedContacts = this.mapToContact(emails);
        this.selectedAddress = this.users.filter((user) =>
            this.selectedContacts.includes(user.email)
        );
    }

    orgCodeUpdated(orgCodes: Array<string>) {
        this.selectedOrgCodes = JSON.parse(JSON.stringify(orgCodes));
        this.selectedOrgCodesList = this.orgCodes.filter((user) =>
            this.selectedOrgCodes.includes(user.value)
        );
    }

    /**
     * This method is used to handle the cancellation of dialog box.
     */
    cancelClicked() {
        // this.contactFormGroup.this.dialogRef.close(null);
        if (this.isEdit) {
            this.contactFormGroup.setValue({
                title: this.data.title,
                region: this.selectedRegion,
                responsibleTeam : this.selectedResponsibleTeam,
                emailAddress: null,
                dl: this.data.includeFromDL !== '0',
                cp: this.data.isNameShownInPdf !== '0'
            });
            this.queryPreview = this.data.displayQuery;
        } else {
            this.contactFormGroup.setValue({
                title: '',
                region: null,
                responsibleTeam: null,
                emailAddress: null,
                dl: null,
                cp: null
            });
            this.queryPreview = '';
        }
        this.tagInput.resetControls();
        this.inputTag.resetControls();
        this.statusListSelectionControl.resetControls();
    }

    /**
     * THis method is responsible for handling save event for the dialog.
     * @param event: Instance of mouse event
     */
    save(event: MouseEvent) {
        if (this.projectContactAccess) {
            if (this.contactFormGroup.valid && this.userSelectedStatus && this.userSelectedStatus.length
                && this.queryPreview.trim() !== '') {
                if (this.queryPreview !== '' && this.criteriaTableData.length > 0) {
                    if ((this.selectedContacts && this.selectedContacts.length) || (this.selectedOrgCodes && this.selectedOrgCodes.length)) {
                        const condition: Array<any> = this.generateQueryData();
                        const queryData = {
                            userGid: this.otherState.guid,
                            projectContactName: this.contactFormGroup.controls.title.value,
                            query: btoa(unescape(encodeURIComponent(this.queryPreview))),
                            actualQuery: this.criteriaTableData,
                            condition
                        };
                        this.contactService
                            .createQueryMethod(JSON.stringify(queryData))
                            .subscribe((value: any) => {
                                if (value.success) {
                                    const queryId = value.data.projectContact.id;
                                    const data = {
                                        id: this.id,
                                        title: this.contactFormGroup.controls.title.value,
                                        regionId: this.contactFormGroup.controls.region
                                            .value
                                            ? this.contactFormGroup.controls.region.value.id
                                            : '',
                                        responsibleTeamId : this.contactFormGroup.controls.responsibleTeam
                                            .value
                                            ? this.contactFormGroup.controls.responsibleTeam.value.value
                                            : '',
                                        queryBuilderId: queryId,
                                        emailAddresses: this.selectedContacts ? this.selectedContacts.join(',') : null,
                                        isNameShownInPdf: this.includeInCoverPage ? 1 : 0,
                                        includeDistributionList: this
                                            .includeInDistributionList
                                            ? 1
                                            : 0,
                                        userGid: this.otherState.guid,
                                        status: this.userSelectedStatus.map(
                                            (item) => item.id
                                        ),
                                        orgCodes: this.selectedOrgCodes ? this.selectedOrgCodes.join(',') : null
                                    };
                                    if (!this.isEdit) {
                                        this.contactService.addProjectContact(
                                            JSON.stringify(data)
                                        );
                                    } else {
                                        this.contactService.modifyProjectContact(
                                            JSON.stringify(data)
                                        );
                                    }
                                    this.dialogRef.close(null);
                                } else {
                                    this.validQuery = false;
                                }
                            });
                    }
                    else {
                        this.toasterService.danger('Fill in at least one project contact or organization code');
                    }
                } else {
                    this.errorLabel = 'Please generate criteria query first';
                }
            } else {
                this.queryPreviewError = !!(this.queryPreview.trim() === '');
                this.contactFormGroup.markAllAsTouched();
                this.tagInput.validateControl();
                this.tagInput.validateControl();
                this.statusListSelectionControl.validateControl();
            }
        } else {
            this.toasterService.danger(AdminConstants.PROJECT_CNTCT_AUTHORIZATION_ERROR);
        }
    }

    /**
     * This method is used to update the selected status for the project contacts.
     * @param data: Array of selected status
     */
    onStatusUpdated(data: any) {
        this.userSelectedStatus = data.status;
    }

    /**
     * This method is used to remove the query data, all together.
     */
    removeAllQuery() {
        this.criteriaTableData = [];
        this.queryPreview = '';
        this.queryPreviewError = true;
    }

    closeDialogue() {
        this.dialogRef.close(null);
    }
}
