import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'src-label-value',
  templateUrl: './label-value.component.html',
  styleUrls: ['./label-value.component.scss']
})
export class LabelValueComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
