import { Component, OnInit, Input } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  ActivatedRoute,
} from "@angular/router";

import { AuthService } from "../../services/auth/auth.service";
import { LoginConstant } from "../../model/login-constant";
import { Login } from "../../model/enums/login.enum";

@Component({
  selector: "src-implicit-callback",
  templateUrl: "./implicit-callback.component.html",
  styleUrls: ["./implicit-callback.component.scss"],
})
export class ImplicitCallbackComponent implements OnInit {
  @Input() redirectURL = "";
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    let hasRedirected = false;
    // workaround to handle msal bug when the page is stuck at implicit/callback
    setTimeout(() => {
      if (
        window.location.hash &&
        window.location.hash.includes("id_token") &&
        !hasRedirected
      ) {
        this.router.navigate([LoginConstant.DEFAULT_LOGIN_REDIRECT]);
        hasRedirected = true;
      }
    }, 500);
    if (!hasRedirected) {
      this.redirectURL = this.route.snapshot.data.redirectURL;
      this.authService.handleAuthRedirect().subscribe(
        (status) => {
          console.log("status = ", status);
          // if login is successful
          if (status) {
            // check user authentication
            this.authService
              .isAuthenticated()
              .then((authenticated) => {
                // if user is authenticated, redirect it
                if (authenticated) {
                  hasRedirected = true;
                  const redirectURL = this.redirectURL
                    ? this.redirectURL
                    : LoginConstant.DEFAULT_LOGIN_REDIRECT;
                  this.router.navigate([redirectURL]);
                } else {
                  this.handleError();
                }
              })
              .catch((error) => {
                this.handleError();
              });
          } else {
            this.handleError();
          }
        },
        (error) => {
          this.handleError();
        }
      );
    }
  }

  handleError() {
    this.router.navigate([LoginConstant.DEFAULT_LOGOUT_REDIRECT]);
  }
}
