import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { AuthParams } from "../../model/enums/auth-params.enum";
import { Login } from "../../model/enums/login.enum";
import { LoginConstant } from "../../model/login-constant";
import { OktaConstants } from "../../model/okta-constants";
import { OktaAuthenticator } from "../../services/auth/okta-authenticator";
import { BehaviorSubject, Observable } from "rxjs";
import { AuthBase } from "./auth-base";
import { AzureAuthenticator } from "./azure-authenticator";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public doRedirect = true;
  private authenticator: AuthBase;
  private readonly loginStatus: BehaviorSubject<Login>;
  private router: Router;
  redirectUrl = "";

  constructor(private authClient: MsalService) {
    // TODO: from here we can comment out and use init method if want to initialize other authenticator
    this.loginStatus = new BehaviorSubject<Login>(Login.LOGIN_FAILED);
    this.authenticator = new AzureAuthenticator(authClient);
    //this.authenticator = new OktaAuthenticator(OktaConstants);
  }

  set landingPage(routePath: string) {
    this.redirectUrl = routePath;
  }

  /**
   * This method is used to set router properties.
   * @param value: Router instance.
   */
  set route(value: Router) {
    this.router = value;
  }

  /**
   * This method returns a behaviour subject which will be executed on every login status.
   */
  get authStatus(): BehaviorSubject<Login> {
    return this.loginStatus;
  }

  /**
   * This method can be used to initiate different authenticator based on the param
   * passed.
   */
  init(param: AuthParams) {
    switch (param) {
      case AuthParams.OKTA_LOGIN:
        this.authenticator = new OktaAuthenticator(OktaConstants);
        break;
      case AuthParams.AZURE_LOGIN:
        this.authenticator = new AzureAuthenticator(this.authClient);
        break;
      case AuthParams.OTHER_LOGIN:
        this.authenticator = new OktaAuthenticator(OktaConstants);
        break;
      default:
        this.authenticator = new OktaAuthenticator(OktaConstants);
    }
  }

  /**
   * Returns access token.
   */
  async getAccessToken() {
    return this.authenticator.getAccessToken();
  }

  /**
   * Return the ID token
   */
  async getIdToken() {
    return this.authenticator.getIdToken();
  }

  /**
   * Check if user is authenticated or not
   * @returns True if user is authenticated otherwise false
   */
  async isAuthenticated() {
    return !!(await this.authenticator.isAuthenticated());
  }

  /**
   * Return the payload of access token
   */
  getTokenPayload() {
    return this.authenticator.getTokenPayload();
  }

  /**
   * This method is used to do login with okta services.
   */
  login() {
    return this.authenticator.login();
  }

  /**
   * This method is used to do logout with okta services.
   */
  logout(): Observable<any> {
    return new Observable<any>((observer) => {
      this.authenticator.logout().then(
        (status) => {
          this.loginStatus.next(Login.LOG_OUT_SUCCESS);
          if (this.doRedirect) {
            this.router.navigate([LoginConstant.DEFAULT_LOGOUT_REDIRECT]);
          } else {
            observer.next(status);
          }
        },
        (error) => {
          this.loginStatus.next(Login.LOG_OUT_FAILED);
          observer.next(error);
        }
      );
    });
  }

  /**
   * Parse the token from the URL and save it in the session. This method should be called by Main application
   * when redirect occurs
   */
  handleAuthRedirect(): Observable<any> {
    return new Observable<any>((observer) => {
      this.authenticator.handleAuthRedirect().then(
        (status) => {
          observer.next(status);
          // TODO: We need to check for user in DB from here.
          this.loginStatus.next(Login.LOGIN_SUCCESS);
        },
        (error) => {
          observer.next(error);
          this.loginStatus.next(Login.LOGIN_FAILED);
        }
      );
    });
  }

  /**
   * Redirect the page for login via entitlement.
   */
  private authenticate() {
    this.authenticator.authenticate();
  }
}
