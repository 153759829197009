import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataGroupListItem } from '../shared/model/interfaces/data-group-list-item';
import { PlantModel } from '../shared/model/plant-model';
import { SiteModel } from '../shared/model/site-model';
import { UnitModel } from '../shared/model/unit-model';
import { RestMethodService } from './rest-method.service';
import { UserDataSerializer } from './serializers/user-data-serializer';
import { StateService } from './state.service';
import { LOCAL_URL, SANDBOX_URL, DEV_URL, ENV } from '../shared/model/env';

@Injectable({
    providedIn: 'root'
})
export class DataGroupService extends RestMethodService {
    private API_DOMAIN =
        ENV === 'local' ? LOCAL_URL : ENV === 'dev' ? DEV_URL : SANDBOX_URL;

    constructor(
        httpClient: HttpClient,
        private applicationState: StateService
    ) {
        super(httpClient);
    }

    /**
     * This method is used to fetch data for the data authentication.
     */
    fetchData(): Promise<any> {
        const serverAddress = `${this.API_DOMAIN}/sites`;
        return new Promise<any>((resolve) => {
            this.get(serverAddress).then((result) => {
                resolve(
                    UserDataSerializer.serializeDataGroup(result.data.sites)
                );
            });
        });
    }

    createGroupListItem(): DataGroupListItem {
        return {
            name: '',
            id: '1',
            sites: [],
            description: '',
            plants: new Map<SiteModel, Array<PlantModel>>(),
            units: new Map<PlantModel, Array<UnitModel>>()
        };
    }

    serializeDataGroupList(groups: Array<any>): Array<DataGroupListItem> {
        return groups.map((item) => {
            const groupListItem: DataGroupListItem = this.createGroupListItem();
            const plantSiteMap: Map<SiteModel, Array<PlantModel>> = new Map<
                SiteModel,
                Array<PlantModel>
            >();
            const unitPlantMap: Map<PlantModel, Array<UnitModel>> = new Map<
                PlantModel,
                Array<UnitModel>
            >();
            groupListItem.description = item.description;
            groupListItem.id = item.id;
            groupListItem.name = item.name;
            if (item.sites) {
                groupListItem.sites = item.sites.map((site) => {
                    const siteModel = {
                        siteId: site.id,
                        siteName: site.name,
                        default: false
                    };
                    const plantArray: Array<PlantModel> = site.plants.map(
                        (plant) => {
                            const plantModel = {
                                plantName: plant.name,
                                plantId: plant.id
                            };
                            const unitArray: Array<UnitModel> = plant.units.map(
                                (unit) => {
                                    return {
                                        unitName: unit.name,
                                        unitId: unit.id
                                    };
                                }
                            );
                            unitPlantMap.set(plantModel, unitArray);
                            return plantModel;
                        }
                    );
                    plantSiteMap.set(siteModel, plantArray);
                    return siteModel;
                });
            }
            groupListItem.plants = plantSiteMap;
            groupListItem.units = unitPlantMap;
            return groupListItem;
        });
    }

    /**
     * This method is used to fetch data groups.
     */
    fetchDataGroups(): void {
        let query = `{"id": 1,"name":0,"description":0 }`;
        query = encodeURIComponent(query);
        const serverAddress = `${this.API_DOMAIN}/data-groups?sortingParams=${query}`;
        this.get(serverAddress).then((result) => {
            this.applicationState.dataGroupList = this.serializeDataGroupList(
                result.data.dataGroups
            );
        });
    }

    /**
     * This method is used to edit and update the data groups
     * @param data: instance of data group item which need to be updated.
     */
    editDataGroup(data: any) {
        const serverAddress = `${this.API_DOMAIN}/data-groups/${data.id}`;
        this.put(serverAddress, JSON.stringify(data)).then(() =>
            this.fetchDataGroups()
        );
    }

    /**
     * THis method is used to provide information for the data group.
     * @param id
     */
    fetchDataGroupInfo(id: string) {
        const serverAddress = `${this.API_DOMAIN}/data-groups/${id}`;
        return new Promise<any>((resolve) => {
            this.get(serverAddress).then((result) => {
                resolve(
                    UserDataSerializer.serializeDataGroup(
                        result.data.dataGroup.sites
                    )
                );
            });
        });
    }

    /**
     * This method is used to create the data groups
     * @param data: instance of data group item which need to be updated.
     */
    createDataGroup(data: any) {
        const serverAddress = `${this.API_DOMAIN}/data-groups`;
        this.post(serverAddress, JSON.stringify(data)).then((result) => {
            this.fetchDataGroups();
        });
    }

    /**
     * This method is used to delete the data group items.
     * @param item: instance of groups which need to be removed.
     */
    deleteDataGroup(item: DataGroupListItem) {
        const serverAddress = `${this.API_DOMAIN}/data-groups/${item.id}`;
        this.delete(serverAddress).then(() => this.fetchDataGroups());
    }
}
