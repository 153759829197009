import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestMethodService } from './rest-method.service';
import { RoleListDataItem } from '../shared/model/interfaces/role-list-data-item';
import { StateService } from './state.service';
import { LOCAL_URL, SANDBOX_URL, ENV, DEV_URL } from '../shared/model/env';
import { RoleModel } from '../shared/model/role-model';
import { ToasterService } from './toaster.service';
import { AdminConstants, ServiceConstant } from '../shared/constants/admin.constants';

@Injectable({
    providedIn: 'root'
})
export class RoleOperationService extends RestMethodService {
    private API_DOMAIN =
        ENV === 'local' ? LOCAL_URL : ENV === 'dev' ? DEV_URL : SANDBOX_URL;

    constructor(
        httpClient: HttpClient,
        private applicationState: StateService,
        private readonly toasterService: ToasterService
    ) {
        super(httpClient);
    }

    /**
     * This method is used to fetch all the roles in the system.
     */
    fetchRegions(gid?: string): void {
        const serverAddress = gid ? `${this.API_DOMAIN}/regions?gid=${gid}` : `${this.API_DOMAIN}/regions`;
        this.get(serverAddress).then((result) => {
            this.applicationState.regionItems = result.data.regions;
        });
    }

    fetchPCCRegions(): void {
        const body = {
            name: 'get_dynamic_dropdown_list',
            type: 'function',
            params: [
                {"language_pk": "2"},
                {"parent_building_block": "AdminPCCRegions"},
                {"parent_control_name": "PCCRegionsCombo"}
            ]
        }
        const serverAddress = `${this.API_DOMAIN}/${ServiceConstant.MASTER_DATA_URL}`;
        this.post(serverAddress, body).then((result) => {
            this.applicationState.pccRegions = result;
        });
    }


    /**
     * This method is used to fetch roles.
     */
    fetchRole(): void {
        let query = `{"id": 1,"name":0,"description":0 }`;
        query = encodeURIComponent(query);
        const serverAddress = `${this.API_DOMAIN}/roles?sortingParams=${query}`;
        this.get(serverAddress).then((result) => {
            this.applicationState.roleModel = result.data.roles;
        });
    }

    /**
     * This method is used to fetch role listing.
     */
    fetchRoleWithOperationAndFunctionalities(data: RoleModel): void {
        let query = `{"id": 1,"name":0,"description":0 }`;
        query = encodeURIComponent(query);
        const serverAddress = `${this.API_DOMAIN}/roles/${data.id}`;
        this.get(serverAddress).then((result) => {
            this.applicationState.rolesList = result.data.roles[0];
        });
    }

    /**
     * This method is used to fetch operations for the system.
     */
    fetchOperations(): void {
        let query = `{"id":1, "operation":0}`;
        query = encodeURIComponent(query);
        const serverAddress = `${this.API_DOMAIN}/operations?sortingParams=${query}`;
        this.get(serverAddress).then((result) => {
            if (result.success) {
                this.applicationState.operationList = result.data.operations;
            } else {
                this.applicationState.operationList = [];
            }
        });
    }

    /**
     * This method is used to fetch user functionalities for the system.
     */
    fetchUserFunctionalities(): void {
        let query = `{"id": 1, "functionality": 0, "description": 0}`;
        query = encodeURIComponent(query);
        const serverAddress = `${this.API_DOMAIN}/functionalities?sortingParams=${query}`;
        this.get(serverAddress).then((result) => {
            if (result.success) {
                this.applicationState.functionalitiesList = result.data.functionalities;
            } else {
                this.applicationState.functionalitiesList = [];
            }
        });
    }

    /**
     * This method is used to create role.
     * @param data: Instance of the role item.
     */
    createRole(data: RoleListDataItem) {
        const serverRole = {
            data: {
                role: {
                    name: data.name,
                    description: data.description,
                    operations: data.operations,
                    functionalities: data.functionalities,
                    isPrivilegedRole: data.isPrivilegedRole
                },
                user_gid: this.applicationState.guid
            }
        };
        const serverAddress = `${this.API_DOMAIN}/roles`;
        this.post(serverAddress, JSON.stringify(serverRole)).then((result) => {
            if (result.success) {
                this.fetchRole();
            } else {
                this.toasterService.danger(result.err || AdminConstants.ROLE_CREATE_ERROR);
            }
        });
    }

    /**
     * This method is used to edit role.
     * @param data instance of role item which need to create.
     */
    editRole(data: RoleListDataItem) {
        const serverRole = {
            data: {
                role: {
                    name: data.name,
                    id: data.id,
                    description: data.description,
                    operations: data.operations,
                    functionalities: data.functionalities,
                    isPrivilegedRole: data.isPrivilegedRole
                },
                user_gid: this.applicationState.guid
            }
        };
        const serverAddress = `${this.API_DOMAIN}/roles/${data.id}`;
        this.put(serverAddress, JSON.stringify(serverRole)).then((result) => {
            if (result.success) {
                this.toasterService.success(AdminConstants.UPDATE_SUCCESS_MSG);
                this.fetchRole();
            } else {
                this.toasterService.danger(result.err || AdminConstants.ROLE_UPDATE_ERROR);
            }
        });
    }

    /**
     * This item is used to delete the role.
     * @param data array instance of role item which need to be deleted
     */
    deleteRole(data: RoleModel) {
        const serverAddress = `${this.API_DOMAIN}/roles/${data.id}?userGid=${this.applicationState.guid}`;
        this.delete(serverAddress).then((result) => {
            if (result.success) {
                this.fetchRole();
            } else {
                this.toasterService.danger(result.err);
            }
        });
    }
}
