import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestMethodService } from './rest-method.service';
import { TOKEN_API, AZURE_GRAPH_API } from '../shared/model/env';
import { Observable, from as fromPromise } from 'rxjs';
import { ToasterService } from './toaster.service';
import { AdminConstants } from '../shared/constants/admin.constants';
import { mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AzureADIntegrationService extends RestMethodService {
    private httpOptions = {};
    constructor(
        httpClient: HttpClient,
        private readonly toasterService: ToasterService
    ) {
        super(httpClient);
    }

    /**
     * This method is used to fetch token.     
     */
    async fetchToken() {
        const inputData = {
            api_key: AdminConstants.API_KEY,
            payload: {}
        };
        const serverAddress = TOKEN_API;
        const result = await this.post(serverAddress, JSON.stringify(inputData));
        if (result && result.access_token) {
            return result.access_token;
        }
        this.toasterService.danger(result.error_description || AdminConstants.GET_TOKEN_ERROR);
        return null;
    }

    /**
     * This method is used to fetch user information from azure graph api.
     * @param email: user email which need to be fetch.     
     */
    fetchUserDetails(email: string): Observable<any> {
        return fromPromise(this.fetchToken()).pipe(mergeMap(token => {
            this.httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${token}`
                })
            };
            let query = `$select=extension_37708433798243bca89959b28c968b71_siemens_gid,onPremisesSamAccountName,givenname,surname,userprincipalname,mail,displayname,department,country,mobilePhone,city,officeLocation,preferredLanguage,jobTitle,employeeID,extension_37708433798243bca89959b28c968b71_siemensResponsible,manager,accountEnabled`;

            const serverAddress = `${AZURE_GRAPH_API}/${email}?${query}`;
            return this.getObserver(serverAddress, this.httpOptions);
        }));
    }

    /**
     * This method is used to fetch user information from azure graph api.
     * @param managerGID: manager gid which need to be fetch.     
     */
    fetchResponsibleDetails(managerGID: string): Promise<any> {        
        let filter = `$filter= extension_37708433798243bca89959b28c968b71_siemens_gid eq '${managerGID}' &$count=true&`;
        let query = `$select=onPremisesSamAccountName,givenname,surname,userprincipalname,mail,displayname,department,createdDateTime,accountEnabled,country,mobilePhone,onPremisesDistinguishedName,city,officeLocation, Onpremisesdomainname, Employeetype, Employeehiredate, postalcode, preferredLanguage, extension_37708433798243bca89959b28c968b71_siemensResponsible,accountEnabled`;

        const serverAddress = `${AZURE_GRAPH_API}?${filter}${query}`;
        return this.get(serverAddress, this.httpOptions);
    }
}
