import OktaAuth from '@okta/okta-auth-js';
import { AuthBase } from './auth-base';
import { Subject } from 'rxjs';

export class OktaAuthenticator extends AuthBase {
  private readonly authClient: OktaAuth;
  private tokenInfo = null;
  private config: any;

  constructor(config: any) {
    super();
    this.config = config;
    this.authClient = new OktaAuth({
      url: config.BASE_URL,
      clientId: config.CLIENT_ID,
      issuer: config.ISSUER,
      scopes: config.SCOPES,
      redirectUri: config.REDIRECT_URL
    });
  }

  /**
   * Returns access token.
   */
  async getAccessToken() {
    try {
      const accessToken = (await this.authClient.tokenManager.get('accessToken'));
      if (!this.tokenInfo) {
        this.tokenInfo = this.authClient.token.decode(accessToken.accessToken);
      }
      return accessToken.accessToken;
    } catch (err) {
      this.clearSession();
      return undefined;
    }
  }

  /**
   * Return the ID token
   */
  async getIdToken() {
    try {
      const idToken = await this.authClient.tokenManager.get('idToken');
      return idToken.idToken;
    } catch (err) {
      this.clearSession();
      return undefined;
    }
  }

  /**
   * Check if user is authenticated or not
   * @returns True if user is authenticated otherwise false
   */
  async isAuthenticated() {
    return !!(await this.getAccessToken()) || !!(await this.getIdToken());
  }

  /**
   * Return the payload of access token
   */
  getTokenPayload() {
    if (!this.tokenInfo) {
      this.clearSession();
      return {};
    }
    return this.tokenInfo.payload;
  }

  /**
   * This method is used to do login with okta services.
   */
  login() {
    return new Promise((resolve) => {
      this.authenticate();
    });
  }

  /**
   * This method is used to do logout with okta services.
   */
  logout() {
    return new Promise((resolve, reject) => {
      try {
        this.clearSession();
        setTimeout(() => resolve(true), 0);
      } catch (e) {
        reject(e);
      }
    });
  }

  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  };

  /**
   * Parse the token from the URL and save it in the session. This method should be called by Main application
   * when redirect occurs
   */
  handleAuthRedirect() {
    return new Promise((resolve, reject) => {
      this.authClient.token
        .parseFromUrl()
        .then(tokens => {
          const [idToken, accessToken] = tokens;
          let name = idToken.claims.name;
          name = name.split(' ')[0];
          this.username = name;
          // this.setUserGroups(idToken.claims.groups); //TODO : Need to update if needed
          this.userId = idToken.claims.email;
          const parseToken = accessToken && accessToken.accessToken ? this.parseJwt(accessToken.accessToken) : {};
          this.gid = parseToken.gid || '';
          // this.updateLogoutURL(); //TODO : Need to update if needed
          this.authClient.tokenManager.add('idToken', idToken);
          this.authClient.tokenManager.add('accessToken', accessToken);
          resolve(true);
        })
        .catch(err => {
          console.log('Login error :', err);
          reject(false);
        });
    });
  }

  /**
   * Redirect the page for login via entitlement.
   */
  authenticate() {
    this.authClient.token.getWithRedirect({
      responseType: ['id_token', 'token'],
      idp: this.config.IDP,
      scopes: this.config.SCOPES
    });
  }

  /**
   * Clear the current session
   */
  protected clearSession() {
    this.tokenInfo = null;
    if (this.authClient) {
      this.authClient.tokenManager.clear();
      this.authClient.signOut();
    }
  }
}
