export abstract class AuthBase {

  /**
   * Set user id
   */
  set userId(userId) {
    localStorage.setItem('userId', userId);
  }

  /**
   * Get user id
   */
  get userId() {
    return localStorage.getItem('userId');
  }

  /**
   * Set user gid
   */
  set gid(gid) {
    localStorage.setItem('gid', gid);
  }

  /**
   * Get user gid
   */
  get gid() {
    return localStorage.getItem('gid');
  }


  /**
   * Return the user information stored in the localStorage.
   * @returns User information.
   */
  get username() {
    return localStorage.getItem('username');
  }

  /**
   * Store the user information in localStorage.
   * @param name User name
   */
  set username(name) {
    if (name) {
      localStorage.setItem('username', name);
    }
  }

  set idToken(idToken) {
    if(idToken) {
      localStorage.setItem('ms.idToken', idToken);
    }
  }

  get idToken() {
    return localStorage.getItem('ms.idToken');
  }

  abstract getAccessToken(): any;

  abstract getIdToken(): any;

  abstract logout(): any;

  abstract isAuthenticated(): any;

  abstract getTokenPayload(): any;

  abstract login(): Promise<any>;

  abstract handleAuthRedirect(): Promise<any>;

  abstract authenticate(): void;

  protected abstract clearSession(): void;
}
