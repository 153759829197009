import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Subject} from 'rxjs';
import {DataGroupListItem} from '../../../../shared/model/interfaces/data-group-list-item';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {DataGroupTableItem} from '../../../../shared/model/interfaces/data-group-table-item';

@Component({
  selector: 'src-list-data-groups',
  templateUrl: './list-data-groups.component.html',
  styleUrls: ['./list-data-groups.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class ListDataGroupsComponent implements OnInit, OnChanges {
  @Input() data: Array<DataGroupListItem> = [];
  @Input() actionControl = true;
  @Output() actionSelect: EventEmitter<any> = new EventEmitter<any>();
  expandedElement: DataGroupTableItem | null;
  dataSource: MatTableDataSource<DataGroupTableItem>;
  tableItems: Array<DataGroupTableItem> = [];
  displayedColumns: Array<string> = ['name', 'description', 'action'];
  subDisplayedColumns: Array<string> = ['name', 'unitName'];
  spans = [];
  @Input()
  searchSubject: Subject<any> = new Subject<any>();
  searchValue = '';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor() {}

  ngOnInit() {
    this.init();
    this.searchSubject.subscribe((value) => {
      this.applyFilter(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.init();
  }

  /**
   * Initialize the view data.
   */
  init() {
    this.tableItems = [];
    this.parseData();
    this.spans = [];
    this.dataSource = new MatTableDataSource<DataGroupTableItem>(
      this.tableItems
    );
    if (this.searchValue !== '') {
      this.dataSource.filter = this.searchValue;
    }
    this.dataSource.paginator = this.paginator;
  }

  /**
   * This method is used to handle data.
   * @param element: data item
   */
  getDataSource(element: DataGroupTableItem) {
    return new MatTableDataSource(element.items);
  }

  /**
   * This method is used to handle the and parse data for showing.
   */
  parseData() {
    if (this.data) {
      this.tableItems = [];
      this.data.forEach((item) => {
        const ti: DataGroupTableItem = {
          name: item.name,
          description: item.description,
          id: item.id,
          items: []
        };
        if (item.sites) {
          item.sites.forEach((site) => {
            if (item.plants.get(site)) {
              item.plants.get(site).forEach((plant) => {
                const name = `${site.siteName} - ${plant.plantName}`;
                let unitString = '';
                if (item.units.get(plant)) {
                  unitString = item.units
                    .get(plant)
                    .map((unit) => unit.unitName)
                    .reduce((item1, item2) => `${item1}, ${item2}`);
                }
                ti.items.push({
                  name,
                  unitName: unitString
                });
              });
            }
          });
          if (ti.items.length > 0) {
            this.tableItems.push(ti);
          }
        }
      });
    }
  }

  /**
   * **************************************************
   * Event Handlers
   * **************************************************
   */

  /**
   * This method is used to filter out the table.
   * @param filterValue: string value on which filtering need to be done
   */
  applyFilter(filterValue: string) {
    this.searchValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * This method handles click on action items.
   * @param event: Instance of mouse event
   * @param row: Instance of the row clicked
   * @param type: type of the action button.
   */
  actionItemClicked(event: MouseEvent, row: any, type: string) {
   // event.stopImmediatePropagation();
    this.actionSelect.emit({ type, dgId: row.id });
  }
}
