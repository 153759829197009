import { FieldNameInterface } from './interfaces/field-name.interface';
import { ProjectContactListItemInterface } from './interfaces/project-contact-list-item.interface';
import { StatusItemInterface } from './interfaces/status-item.interface';
import { LOCAL_URL, SANDBOX_URL, ENV, DEV_URL } from './env';
import { OrgCodeModel } from './org-code.model';

class ProjectContactsModel {
    contactMethod = 'project-contacts';
    operatorMethod = 'query-builder-operators';
    statusMethod = 'status';
    fieldMethod = 'query-builder-fields';
    projectContactMethod = 'project-contact-emails';
    contactList: Array<ProjectContactListItemInterface> = [];
    operators: Array<string> = [];
    projectStatus: Array<StatusItemInterface> = [];
    queryBuilderFields: Array<FieldNameInterface> = [];
    queryBuilderId: string;
    orgCodes: Array<OrgCodeModel> = [];
}

class DataGroupModel {}

class RoleDataModel {}

class UsersDataModel {}

export default class ApplicationModel {
    serverUrl =
        ENV === 'local'
            ? LOCAL_URL + '/'
            : ENV === 'dev'
            ? DEV_URL + '/'
            : SANDBOX_URL + '/';
    projectContacts: ProjectContactsModel = new ProjectContactsModel();
    dataGroups: DataGroupModel = new DataGroupModel();
    roles: RoleDataModel = new RoleDataModel();
    users: UsersDataModel = new UsersDataModel();
}
