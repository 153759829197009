import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import ApplicationModel from '../shared/model/application-model';
import {FieldNameInterface} from '../shared/model/interfaces/field-name.interface';
import {ProjectContactListItemInterface} from '../shared/model/interfaces/project-contact-list-item.interface';
import {StatusItemInterface} from '../shared/model/interfaces/status-item.interface';

@Injectable({
  providedIn: 'root'
})
export class ApplicationStateService {
  private applicationModel: ApplicationModel;
  private readonly contactObserver: BehaviorSubject<Array<ProjectContactListItemInterface>>;
  private readonly orgCodeObserver: BehaviorSubject<Array<any>>;
  private readonly operatorObserver: BehaviorSubject<Array<any>>;
  private readonly statusObserver: BehaviorSubject<Array<StatusItemInterface>>;
  private readonly fieldsObserver: BehaviorSubject<Array<FieldNameInterface>>;

  constructor() {
    this.applicationModel = new ApplicationModel();
    this.contactObserver = new BehaviorSubject<Array<ProjectContactListItemInterface>>(this.applicationModel.projectContacts.contactList);
    this.orgCodeObserver = new BehaviorSubject<Array<any>>(this.applicationModel.projectContacts.orgCodes);
    this.operatorObserver = new BehaviorSubject<Array<any>>(this.applicationModel.projectContacts.operators);
    this.statusObserver = new BehaviorSubject<Array<StatusItemInterface>>(this.applicationModel.projectContacts.projectStatus);
    this.fieldsObserver = new BehaviorSubject<Array<FieldNameInterface>>(this.applicationModel.projectContacts.queryBuilderFields);
  }

  get contactApiServicePath() {
    return this.applicationModel.serverUrl + this.applicationModel.projectContacts.contactMethod;
  }

  get operatorApiServicePath() {
    return this.applicationModel.serverUrl + this.applicationModel.projectContacts.operatorMethod;
  }

  get statusApiServicePath() {
    return this.applicationModel.serverUrl + this.applicationModel.projectContacts.statusMethod;
  }

  get projectContactApiPath() {
    return this.applicationModel.serverUrl + this.applicationModel.projectContacts.projectContactMethod;
  }

  get queryBuilderApiPath() {
    return this.applicationModel.serverUrl + 'query-builders';
  }

  get fieldNameApiPath() {
    return this.applicationModel.serverUrl + this.applicationModel.projectContacts.fieldMethod;
  }

  get projectContacts(): BehaviorSubject<Array<ProjectContactListItemInterface>> {
    return this.contactObserver;
  }

  get orgCodesList(): any {
    return this.orgCodeObserver;
  }

  get operators(): BehaviorSubject<Array<any>> {
    return this.operatorObserver;
  }

  get status(): BehaviorSubject<Array<StatusItemInterface>> {
    return this.statusObserver;
  }

  get fields(): BehaviorSubject<Array<FieldNameInterface>> {
    return this.fieldsObserver;
  }

  get queryBuilderId(): string {
    return this.applicationModel.projectContacts.queryBuilderId;
  }

  set queryBuilderId(value: string) {
    if (value && value !== '') {
      this.applicationModel.projectContacts.queryBuilderId = value;
    }
  }

  set serverUrl(value: string) {
    if (value !== '') {
      this.applicationModel.serverUrl = value;
    }
  }

  set contactsPath(value: string) {
    if (value !== '') {
      this.applicationModel.projectContacts.contactMethod = value;
    }
  }

  set operatorPath(value: string) {
    if (value !== '') {
      this.applicationModel.projectContacts.operatorMethod = value;
    }
  }

  set statusPath(value: string) {
    if (value !== '') {
      this.applicationModel.projectContacts.statusMethod = value;
    }
  }

  set projectContactList(value: Array<ProjectContactListItemInterface>) {
    if (value && value.length > 0) {
      this.applicationModel.projectContacts.contactList = value;
      this.contactObserver.next(value);
    }
  }

  set orgCodesList(value: any) {
    if (value && value.length > 0) {
      this.applicationModel.projectContacts.contactList = value;
      this.orgCodeObserver.next(value);
    }
  }

  set operatorList(value: Array<string>) {
    if (value && value.length > 0) {
      this.applicationModel.projectContacts.operators = value;
      this.operatorObserver.next(value);
    }
  }

  set statusList(value: Array<StatusItemInterface>) {
    if (value && value.length > 0) {
      this.applicationModel.projectContacts.projectStatus = value;
      this.statusObserver.next(value);
    }
  }

  set fieldList(value: Array<FieldNameInterface>) {
    if (value && value.length > 0) {
      this.applicationModel.projectContacts.queryBuilderFields = value;
      this.fieldsObserver.next(value);
    }
  }
}
