import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { DataGroupListItem } from '../shared/model/interfaces/data-group-list-item';
import { RoleListDataItem } from '../shared/model/interfaces/role-list-data-item';
import { UserListDataItem } from '../shared/model/interfaces/user-list-data-item';
import { PCCRegionModel } from '../shared/model/pcc-region-model';
import { RegionModel } from '../shared/model/region-model';
import { RoleModel } from '../shared/model/role-model';
import UserModel from '../shared/model/user-model';

@Injectable({
    providedIn: 'root'
})
export class StateService {
    private userData: UserModel = {
        userId: null,
        alias: '',
        email: '',
        lName: '',
        fName: '',
        regions: new Map<RegionModel, Array<RoleModel>>(),
        dataGroups: new Map<RegionModel, Array<DataGroupListItem>>(),
        userGid: '',
        activeUser: '',
        windowsUserid: '',
        pccAdminRegion: '',
        orgCode: ''
    };
    private userGuid: Map<string, string> = new Map<string, string>();
    private userId: Map<string, string> = new Map<string, string>();
    private availableUsers: Array<UserModel> = [];
    private userList: Array<UserListDataItem> = [];
    private roles: Array<RoleModel> = [];
    private roleWithOperationAndFunctionalities: RoleListDataItem = {
        name: '',
        id: null,
        description: '',
        operations: [],
        functionalities: [],
        isPrivilegedRole: ''
    };
    private regions: Array<RegionModel> = [];
    private pccAdminRegions: Array<PCCRegionModel> = [];
    private operation: Array<{ id: string; operation: string }> = [];
    private functionalities: Array<{ id: string; functionality: string }> = [];
    private dataGroups: Array<DataGroupListItem> = [];
    // Observers
    userDataObserver: Subject<UserModel> = new Subject<UserModel>();
    userListObserver: Subject<Array<UserListDataItem>> = new Subject<
        Array<UserListDataItem>
    >();
    serverUserObserver: Subject<Array<UserModel>> = new Subject<
        Array<UserModel>
    >();
    roleWithOperationAndFunctionalitiesObserver: Subject<RoleListDataItem> = new Subject();
    roleObserver: Subject<Array<RoleModel>> = new Subject();
    regionObserver: Subject<Array<RegionModel>> = new Subject<
        Array<RegionModel>
    >();
    pccRegionObserver: Subject<Array<PCCRegionModel>> = new Subject<
        Array<PCCRegionModel>
    >();
    dataGroupObserver: Subject<Array<DataGroupListItem>> = new Subject<
        Array<DataGroupListItem>
    >();
    operationObserver: Subject<
        Array<{ id: string; operation: string }>
    > = new Subject<Array<{ id: string; operation: string }>>();
    functionalitiesObserver: Subject<
        Array<{ id: string; functionality: string }>
    > = new Subject<Array<{ id: string; functionality: string }>>();
    notifierObserver: Subject<any> = new Subject<any>();
    constructor() { }

    /**
     * This method us used to update the userData.
     * @param value: Fetched instance of user details
     */
    set userModel(value: UserModel) {
        this.userData = value;
        this.userDataObserver.next(this.userData);
    }

    set guid(value: string) {
        this.userGuid.set(localStorage.getItem('gid'), value);
    }

    get guid(): string {
        const guidUser = localStorage.getItem('gid');
        return guidUser ? guidUser : 'SYSTEM';
    }

    set id(value: string) {
        this.userId.set(localStorage.getItem('username'), value);
    }

    get id(): string {
        const userId = this.userId.get(localStorage.getItem('username'));
        return userId ? userId : '';
    }

    set userFunctionalities(value: string) {
        localStorage.setItem('userFunctionalities', value);
    }

    get userFunctionalities(): string {
        const userFunctionalities = localStorage.getItem('userFunctionalities');
        return userFunctionalities ? userFunctionalities : '';
    }

    set adminRoleRegion(value: string) {
        localStorage.setItem('adminRoleRegion', value);
    }

    get adminRoleRegion(): string {
        const adminRoleRegion = localStorage.getItem('adminRoleRegion');
        return adminRoleRegion ? adminRoleRegion : '';
    }
    /**
     * This method is used to set operation list.
     * @param value: List of operations
     */
    set operationList(value: Array<{ id: string; operation: string }>) {
        this.operation = value;
        this.operationObserver.next(this.operation);
    }

    /**
     * This method is used to set user functionalities list.
     * @param value: List of user functionalities
     */
    set functionalitiesList(value: Array<{ id: string; functionality: string }>) {
        this.functionalities = value;
        this.functionalitiesObserver.next(this.functionalities);
    }

    /**
     * This method is used to set the regions.
     * @param value: regions list
     */
    set regionItems(value: Array<RegionModel>) {
        this.regions = value;
        this.regionObserver.next(this.regions);
    }

    set pccRegions(value: Array<PCCRegionModel>) {
        this.pccAdminRegions = value;
        this.pccRegionObserver.next(this.pccAdminRegions);
    }

    /**
     * This method is used to set list of data groups.
     * @param value: list items for the data group.
     */
    set dataGroupList(value: Array<DataGroupListItem>) {
        this.dataGroups = value;
        this.dataGroupObserver.next(this.dataGroups);
    }

    /**
     * This setter is used to set value of roles.
     * @param value: array of roles
     */
    set roleModel(value: Array<RoleModel>) {
        this.roles = value;
        this.roleObserver.next(this.roles);
    }

    /**
     * This method is used to set the role list.
     * @param value: array of role list item
     */
    set rolesList(value: RoleListDataItem) {
        this.roleWithOperationAndFunctionalities = value;
        this.roleWithOperationAndFunctionalitiesObserver.next(this.roleWithOperationAndFunctionalities);
    }

    /**
     * This is setter function for all the users available on the server.
     * @param value: Array of the users
     */
    set serverUsers(value: Array<UserModel>) {
        this.availableUsers = value.map((item: any) => {
            const data: UserModel = {
                fName: item.fName,
                lName: item.lName,
                email: item.email,
                alias: item.alias,
                dataGroups: null,
                regions: null,
                userId: null,
                userGid: item.userGid,
                activeUser: item.activeUser,
                windowsUserid: item.windowsUserid,
                pccAdminRegion: null,
                orgCode: item.orgCode,
                userName: item.userName,
                jobTitle: item.jobTitle,
                cellPhone: item.cellPhone,
                countryCode: item.countryCode,
                division: item.division,
                locationCode: item.locationCode,
                mailCode:item.mailCode,
                site:item.site,
                displayName: item.displayName,
                searchKey: item.searchKey,
                managerGID: item.managerGID                
            };
            return data;
        });
        this.serverUserObserver.next(this.availableUsers);
    }

    /**
     * This method is used to return the list of the user.
     * @param value: list of user which are added to system
     */
    set listedUser(value: Array<UserListDataItem>) {
        this.userList = value;
        this.userListObserver.next(this.userList);
    }

    /**
     * This method is used to return the user list of user which are available for adding into system.
     */
    get users(): Array<UserModel> {
        return this.availableUsers.filter((user) => {
            return (
                this.userList.findIndex(
                    (item) =>
                        item.email.trim().toLowerCase() ===
                        user.email.trim().toLowerCase()
                ) < 0
            );
        });
    }

     /**
     * This method is used to return the user which is already available in system.
     */
    isUserAvailable(email:string):UserListDataItem  {
        const existingUserIndex= this.userList.find(
            (item) =>
                item.email.trim().toLowerCase() ===
                email.trim().toLowerCase()
        );        
        return existingUserIndex;          
    }
}
