import {Component, Input, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material';
import {AddEditDialogComponent} from '../../components/add-edit-dialog/add-edit-dialog.component';
import {ApplicationStateService} from '../../services/application-state.service';
import {ProjectContactService} from '../../services/project-contact.service';
import {ConfirmationDialogComponent} from '../../shared/component/confirmation-dialog/confirmation-dialog.component';
import {FieldNameInterface} from '../../shared/model/interfaces/field-name.interface';
import {ProjectContactListItemInterface} from '../../shared/model/interfaces/project-contact-list-item.interface';
import {StatusItemInterface} from '../../shared/model/interfaces/status-item.interface';
import { UserOperationService } from '../../services/user-operation.service';
import { StateService } from '../../services/state.service';

@Component({
  selector: 'src-project-contacts',
  templateUrl: './project-contacts.component.html',
  styleUrls: ['./project-contacts.component.scss']
})
export class ProjectContactsComponent implements OnInit {
  @Input() serverUrl: string;
  projectContacts$: Observable<Array<ProjectContactListItemInterface>>;
  orgCodesList$: Observable<Array<any>>;
  operators$: Observable<Array<string>>;
  status$: Observable<Array<StatusItemInterface>>;
  searchSubject: Subject<any> = new Subject<any>();
  resetSearch =  false;
  private subscription: Subscription;
  private fields$: Observable<Array<FieldNameInterface>>;
  projectContactAccess: boolean;
  constructor(
    public matDialog: MatDialog,
    private projectContactService: ProjectContactService,
    private applicationState: ApplicationStateService,
    private userService: UserOperationService,
    private stateService: StateService,
  ) {}

  async ngOnInit() {
    // let result;
    this.subscription = new Subscription();
    this.projectContactService.init();
    if (this.stateService.guid) {
      // result = 
      await this.userService.fetchGuid();
    }
    // if(result.success) {
    this.fetchData();
    const userFunctionalities = localStorage.getItem('userFunctionalities');
    this.projectContactAccess = userFunctionalities ? userFunctionalities.includes("Create Project Contact") : false;
    this.projectContacts$ = this.applicationState.projectContacts;
    this.orgCodesList$ = this.applicationState.orgCodesList;
    this.operators$ = this.applicationState.operators;
    this.status$ = this.applicationState.status;
    this.fields$ = this.applicationState.fields;
    // }
  }

  /**
   * This method is used to fetch project contact list.
   */
  private fetchData() {
    this.projectContactService.fetchProjectContacts();
    this.projectContactService.fetchOperators();
    this.projectContactService.fetchStatus();
    this.projectContactService.fetchOrgCodes();
  }

  /**
   * This method is used to show add/Edit data groups
   */
  showAddEditDialog(item: ProjectContactListItemInterface) {
    const dialogRef: MatDialogRef<AddEditDialogComponent> = this.matDialog.open(
      AddEditDialogComponent,
      {
        disableClose: true,
        autoFocus: false,
        data: item
      }
    );
    return dialogRef;
  }

  /**
   * This method is used handle click on edit group button.
   * @param item: instance of selected ProjectContactListItemInterface
   */
  editProjectContacts(item: ProjectContactListItemInterface) {
    this.subscription.add(
      this.showAddEditDialog(item)
        .afterClosed()
        .subscribe((data: ProjectContactListItemInterface) => {
          this.resetSearch = true;
          if (data) {
            this.projectContactService.modifyProjectContact(data);
          }
        })
    );
  }

  /**
   * This method is used to handle click on delete group button.
   * @param item: instance of selected ProjectContactListItemInterface
   */
  deleteProjectContacts(item: ProjectContactListItemInterface) {
    const desc = `Are you sure to remove the "${item.title}" permanently?`;
    this.subscription.add(
      this.showConfirmation('Remove Data Group', desc, 'No', 'Yes')
        .afterClosed()
        .subscribe((data: ProjectContactListItemInterface) => {
          if (data) {
            this.projectContactService.deleteProjectContact(item);
          }
        })
    );
  }

  /**
   * This method is used to show confirmation.
   * @param title: Title for the confirmation dialog
   * @param description: Description for the confirmation dialog
   * @param cancelLabel: label of the cancel button for the confirmation dialog
   * @param okLabel: label of the ok button for the confirmation dialog
   */
  showConfirmation(
    title: string,
    description: string,
    cancelLabel?: string,
    okLabel?: string
  ) {
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.matDialog.open(
      ConfirmationDialogComponent,
      {
        data: {
          title,
          description,
          cancelLabel: cancelLabel ? cancelLabel : 'Cancel',
          okLabel: okLabel ? okLabel : 'Ok'
        }
      }
    );
    return dialogRef;
  }

  /**
   * ************************************
   * Event Handlers
   * ************************************
   */

  /**
   * This method is used to filter out the table.
   * @param filterValue: string value on which filtering need to be done
   */
  applyFilter(filterValue: string) {
    this.resetSearch = false;
    this.searchSubject.next(filterValue);
  }

  /**
   * This method is used to open add data dialog, where user can provide details for the new data group.
   */
  actionClicked() {
    this.subscription.add(
      this.showAddEditDialog(null)
        .afterClosed()
        .subscribe((data: any) => {
          if (data) {
            this.projectContactService.addProjectContact(data);
          }
        })
    );
  }

  /**
   * This method handles the action taken on selected row.
   * @param action: instance of the project contacts
   */
  contactActionClicked(action: {
    item: ProjectContactListItemInterface;
    type: string;
  }) {
    if (action.type === 'Edit') {
      this.editProjectContacts(action.item);
    } else {
      this.deleteProjectContacts(action.item);
    }
  }
}
