import {DataGroupListItem} from './interfaces/data-group-list-item';
import {RegionModel} from './region-model';
import {RoleModel} from './role-model';

export default class UserModel {
    fName = '';
    lName = '';
    email = '';
    alias = '';
    userGid = '';
    dataGroups: Map<RegionModel, Array<DataGroupListItem>> = new Map<RegionModel, Array<DataGroupListItem>>();
    regions: Map<RegionModel, Array<RoleModel>> = new Map<RegionModel, Array<RoleModel>>();
    userId: string = null;
    activeUser = '';
    windowsUserid = '';
    pccAdminRegion = '';
    orgCode = '';
    userName?='';
    jobTitle?='';
    cellPhone?='';
    countryCode?='';
    division?='';
    locationCode?='';
    mailCode?='';
    site?='';
    displayName?='';
    searchKey?='';
    managerGID?='';
    managerName?='';
    managerEmail?='';
}
