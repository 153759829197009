import { MsalService } from "@azure/msal-angular";
import { AuthBase } from "./auth-base";
import { Subject } from "rxjs";

export class AzureAuthenticator extends AuthBase {
  private tokenInfo = null;
  constructor(private authClient: MsalService) {
    super();
  }

  /**
   * Returns access token.
   */
  async getAccessToken() {
    try {
      if (!!this.authClient.getAccount()) {
        return this.idToken;
      }
    } catch (err) {
      this.clearSession();
    }
    return undefined;
  }

  /**
   * Return the ID token
   */
  async getIdToken() {
    try {
      if (!!this.authClient.getAccount()) {
        return this.idToken;
      }
    } catch (err) {
      this.clearSession();
    }
    return undefined;
  }

  /**
   * Check if user is authenticated or not
   * @returns True if user is authenticated otherwise false
   */
  async isAuthenticated() {
    return !!this.authClient.getAccount();
  }

  /**
   * Return the payload of access token
   */
  getTokenPayload() {
    if (!this.tokenInfo) {
      this.clearSession();
      return {};
    }
    return this.tokenInfo.payload;
  }

  /**
   * This method is used to do login with okta services.
   */
  login() {
    return new Promise((resolve) => {
      this.authenticate();
    });
  }

  /**
   * This method is used to do logout with okta services.
   */
  logout() {
    return new Promise((resolve, reject) => {
      try {
        this.clearSession();
        setTimeout(() => resolve(true), 0);
      } catch (e) {
        reject(e);
      }
    });
  }

  parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  setTokenInfo(idToken) {
    this.userId = idToken.claims.EmailAddress;
    this.gid = idToken.claims.Gid;
    this.username = idToken.claims.Firstname;
    this.idToken = idToken.rawIdToken;
  }

  clearTokenInfo() {
    localStorage.clear();
  }

  /**
   * Parse the token from the URL and save it in the session. This method should be called by Main application
   * when redirect occurs
   */
  handleAuthRedirect() {
    return new Promise((resolve, reject) => {
      this.authClient.handleRedirectCallback((error, response) => {
        try {
          if (error) {
            throw error;
          }
          const idToken = response.idToken;
          this.setTokenInfo(idToken);
          resolve(true);
        } catch (err) {
          console.log("Login error :", err);
          reject(false);
        }
      });
    });
  }

  /**
   * Redirect the page for login via entitlement.
   */
  authenticate() {
    this.clearTokenInfo();
    setTimeout(() => {
      this.authClient.loginRedirect();
    }, 1000);
  }

  /**
   * Clear the current session
   */
  protected clearSession() {
    this.clearTokenInfo();
    if (this.authClient) {
      this.authClient.logout();
    }
  }
}
